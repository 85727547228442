import React, {useEffect, useState} from "react";
import House from "../../../images/svg/hmrc-page/black-house.svg";
// import {createOrRetrieveToken} from "../../../actions/BanksMandates";
import closedEye from '../../../images/svg/closed-eye.svg';
import openEye from '../../../images/svg/open-eye.svg';
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {Typography, Divider} from "@collegia-partners/ui-kit";
import {useGetMandateInfo} from "../../../hooks/automatic-enrolment";
import Skeleton from "../../../components/Skeleton";

function LeftSide({
	title = "",
	subtitle = "",
	showHide = false,
	setShowHide = () => {},
	renderExtra = false,
	showDiv = false,
	activeText = false,
}) {
	return (
		<div>
			<div
				className={"automatic-enrolment-ddi-left-container-row"}
			>
				<div className={activeText ? "automatic-enrolment-ddi-left-container-active" : ""}>
					<Typography
						variant={"subtitle"}
						weight={activeText ? "normal" : "bold"}
						color={activeText ? "white" : "black"}
					>
						{title}
					</Typography>
				</div>
				<div className={renderExtra ? "automatic-enrolment-ddi-left-container-icon-text" : ""}>
					{
						renderExtra && (
							<img
								className={"automatic-enrolment-ddi-left-container-icon-cursor"}
								alt={"eye"}
								src={showHide ? openEye : closedEye}
								onClick={() => setShowHide(!showHide)}
							/>
						)
					}
					<Typography
						variant={"subtitle2"}
						weight={"normal"}
						color={"gray"}
					>
						{subtitle}
					</Typography>
				</div>

			</div>
			{
				showDiv
					? <div className={"automatic-enrolment-ddi-div-margin"}><Divider color={"light-gray"}/></div>
					: ""
			}
		</div>
	);
}

function retrieveMandateStatus(status) {
	switch (status) {
		case "CANCELLED":
			return "Cancelled";
		case "READY_FOR_EXPORT":
		case "EXPORTED":
		case "ACTIVE":
			return "Active";
		default:
			return "N/A";
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AutomaticEnrolmentDdi = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();
	const [showAccountNumber, setShowAccountNumber] = useState(false);
	const [showSortCode, setShowSortCode] = useState(false);
	const { mandate_info, isPending } = useGetMandateInfo();

	useEffect(() => {
		setLayoutProps({
			activePage: 'Auto Enrolment',
			showBackButton: true,
			backLink: '/main/auto-enrolment-menu',
		});
	}, [setLayoutProps]);

	return (
		<div
			id={'automatic-enrolment-ddi'}
		>
			<div className={"automatic-enrolment-ddi-title-margin"}>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					Direct debit instruction
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					Create or view your direct debit instruction
				</Typography>
			</div>
			<Divider color={"light-gray"}/>
			<div className={"automatic-enrolment-ddi-container"}>
				{
					isPending && (
						<Skeleton width={'55%'} height={'20vw'} />
					)
				}
				{
					mandate_info?.id && (
						<div className={"automatic-enrolment-ddi-left"}>
							<LeftSide
								title={retrieveMandateStatus(mandate_info?.mandateInfo?.status)}
								subtitle={""}
								showDiv={true}
								activeText={true}
							/>
							<LeftSide
								title={"Mandate reference"}
								subtitle={mandate_info?.mandateInfo?.mandateId ?? "N/A"}
								showDiv={true}
							/>
							<LeftSide
								title={"Payment day"}
								subtitle={mandate_info?.payment_day ?? "N/A"}
								showDiv={true}
							/>
							<LeftSide
								title={"Account Number"}
								subtitle={
									showAccountNumber
										? mandate_info?.debtorAccount?.domesticAccountNumber
										: "XXXXXXX"
								}
								showHide={showAccountNumber}
								setShowHide={setShowAccountNumber}
								renderExtra={true}
								showDiv={true}
							/>
							<LeftSide
								title={"Sort Code"}
								subtitle={
									showSortCode
										? mandate_info?.debtorAccount?.domesticBranchCode
										: "XXXXXX"
								}
								showHide={showSortCode}
								setShowHide={setShowSortCode}
								renderExtra={true}
								showDiv={false}
							/>
						</div>
					)
				}
				<div className={"automatic-enrolment-ddi-right"}>
					<img
						className={"automatic-enrolment-ddi-right-icon"}
						alt={"logo"}
						src={House}
					/>
					<div>
						<div>
							<Typography
								variant={"subtitle"}
									weight={"bold"}
									color={"black"}
								>
									Mandate
								</Typography>
							</div>
							<div>
								<Typography
									variant={"subtitle2"}
									weight={"normal"}
									color={"gray"}
								>
									Collegia uses electronic mandates to collect your pension contributions.
								</Typography>
							</div>
						</div>
					</div>

			</div>
			{/*<CreateNewMandateModal*/}
			{/*	open={showCreateMandateModal}*/}
			{/*	setOpen={setShowCreateMandateModal}*/}
			{/*	createOrRetrieveToken={createOrRetrieveToken}*/}
			{/*/>*/}
		</div>
	);
}

export default AutomaticEnrolmentDdi;