import React, {useCallback, useState} from "react";
import { Modal, FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import FPTable from "../../../components/common/FPTable/FPTable";
import {useGetFpsReportTable, useReSubmitFPS} from "../../../hooks/reports/fps";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const DownloadReportDialog = ({
	title = "",
	columns = {},
	open = false,
	setOpen = () => {},
	payRunId = "",
	payScheduleRun = {},
}): JSX.Element => {
	const { mutate: resubmitFps } = useReSubmitFPS(),
		[resubmitButtonActive, setResubmitButtonActive] = useState(false);

	const resubmitFpsCallBack = useCallback(async () => {
		await resubmitFps({
			pay_schedule_runs: {
				id: payRunId,
			}
		});
		setResubmitButtonActive(true);
	},[payRunId, resubmitFps]);

	return (
		<Modal
			onClose={() => {
				setOpen(false);
			}}
			isOpen={open}
			size={"lg"}
		>
			<div id={"report-fps-modal"}>

				<div className={"report-fps-modal-title"}>
					<Typography variant={"subtitle"} color={"black"} weight={"bold"}>
						{title}
					</Typography>
					<FreepayrollButton
						variant={"red"}
						custom
						width={2.5}
						height={0.6}
						onClick={resubmitFpsCallBack}
						disabled={!payScheduleRun.resubmission_needed || resubmitButtonActive}
						size={"small"}
					>
						Resubmit FPS
					</FreepayrollButton>
				</div>
				<FPTable
					headers={columns}
					hookName={useGetFpsReportTable}
					hookKey={'report_fps_table'}
					hookParams={{payScheduleRunId: payRunId}}
				/>
			</div>
		</Modal>
	);
}

export default DownloadReportDialog