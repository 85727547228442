import { isValid, parseISO } from "date-fns";

/**
 *
 * @param password
 * @returns {{valid: boolean, number: boolean, character: boolean, length: boolean}}
 */
export const validatePassword = (password = "") => {
	// validate a password containing at least 8 characters
	// at least one letter and at least one number
	let valid = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
	let character = /[a-zA-Z]+/;
	let number = /\d+/;
	let length = /^.{8,}$/;
	return {
		valid: valid.test(password),
		character: character.test(password),
		number: number.test(password),
		length: length.test(password),
	};
};

/*
 * Usar nas datas q vem do backend yyyy-mm-dd
 */
export const formatDateFromBackend = (date) => {
	if (!date) return "";
	const parts = date.split("-");
	return `${parts[2]}/${parts[1]}/${parts[0]}`;
};

/*
 * Usar nas datas q vem do backend yyyy-mm-dd com horario
 */
export const formatDateFromBackendWithTime = (date) => {
	if (!date) return "";
	const parts = date.split("T");
	const dateParts = parts[0].split("-");
	return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};

/**
 * @param state
 * @param action
 * @returns {{valueOf(): boolean}|boolean}
 */
export function resolveReduxState(state = false, action = false) {
	if (action && state) {
		return {
			...state,
			...action,
		};
	}

	return state;
}

export function validateEmail(email) {
	/*eslint-disable */
	const re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
	/*eslint-enable */
}

/**
 * @returns {string|boolean}
 */
export const retrieveAccessToken = (): string | boolean =>
	localStorage.getItem("fpEmployerToken") ?? false;


export function renderPensionStatusColor(status) {
	switch (status) {
		case "Not enrolled":
			return "#000000";
		case "Enrolled":
			return "#0360FD";
		case "Opted-out":
		case "Left scheme":
			return "#C4C4C4";
		default:
			return "#000";
	}
}

export const company_types = {
	"": "Select your company legal structure",
	"private-unlimited": "Private unlimited company",
	ltd: "Private limited company",
	plc: "Public limited company",
	"old-public-company": "Old public company",
	"private-limited-guarant-nsc-limited-exemption":
		"Private Limited Company by guarantee without share capital, use of 'Limited' exemption",
	"limited-partnership": "Limited partnership",
	"private-limited-guarant-nsc":
		"Private limited by guarantee without share capital",
	"converted-or-closed": "Converted / closed",
	"private-unlimited-nsc": "Private unlimited company without share capital",
	"private-limited-shares-section-30-exemption":
		"Private Limited Company, use of 'Limited' exemption",
	"protected-cell-company": "Protected cell company",
	"assurance-company": "Assurance company",
	"oversea-company": "Overseas company",
	"eeig-establishment":
		"European Economic Interest Grouping Establishment (EEIG)",
	"icvc-securities": "Investment company with variable capital",
	"icvc-warrant": "Investment company with variable capital",
	"icvc-umbrella": "Investment company with variable capital",
	"registered-society-non-jurisdictional": "Registered society",
	"industrial-and-provident-society": "Industrial and Provident society",
	"northern-ireland": "Northern Ireland company",
	"northern-ireland-other": "Credit union (Northern Ireland)",
	llp: "Limited liability partnership",
	"royal-charter": "Royal charter company",
	"investment-company-with-variable-capital":
		"Investment company with variable capital",
	"unregistered-company": "Unregistered company",
	other: "Other company type",
	"european-public-limited-liability-company-se":
		"European public limited liability company (SE)",
	"united-kingdom-societas": "United Kingdom Societas",
	"uk-establishment": "UK establishment company",
	"scottish-partnership": "Scottish qualifying partnership",
	"charitable-incorporated-organisation":
		"Charitable incorporated organisation",
	"scottish-charitable-incorporated-organisation":
		"Scottish charitable incorporated organisation",
	"further-education-or-sixth-form-college-corporation":
		"Further education or sixth form college corporation",
	eeig: "European Economic Interest Grouping (EEIG)",
	ukeig: "United Kingdom Economic Interest Grouping",
	"registered-overseas-entity": "Overseas entity",
};

export const generateRandomString = (length) => {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters.charAt(randomIndex);
	}
	return result;
};

/*
 * Usar nos componentes de datepicker
 */
export const parseDate = (date) => {
	if (!date) return null;

	// Check if the date is already a Date object
	if (date instanceof Date) return date;

	// Try to parse the date string
	const parsedDate = parseISO(date);

	// If the parsed date is valid, return it
	if (isValid(parsedDate)) return parsedDate;

	// Otherwise, return null (or handle the invalid date case)
	return null;
};

export const validDataToSchema = (data, schema) => {
	const validation = schema.safeParse(data);
	const issues = validation.error?.issues;
	if (!issues || !issues.length) return { valid: true };

	const error = {};

	for (let j = 0; j < issues.length; j++) {
		const issue = issues[j];
		error[issue.path[0]] = { message: issue.message };
	}

	return { valid: false, error };
};

export const formatMoney = (amount) => {
	const formatter = new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
	});
	return formatter.format(amount);
};

export function getPayDayOption(payFrequency = "") {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			return [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
			];
		case "M1":
			return [
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23",
				"24",
				"25",
				"26",
				"27",
				"28",
				"29",
				"30",
				"Last Day of Month",
			];
		default:
			return [];
	}
}

export const resolveTaxPeriodAndPeriodDates = (
	frequencyCode,
	periodEndDate,
	lastDayOfMonth = false
) => {
	const moment = require("moment");
	let finalTaxYearSubmission = false;
	periodEndDate = moment(periodEndDate);
	let taxYearStartDateYear;
	if (moment(`${periodEndDate.year()}-04-06`).isSameOrBefore(periodEndDate)) {
		taxYearStartDateYear = periodEndDate.year();
	} else {
		taxYearStartDateYear = periodEndDate.year() - 1;
	}
	let taxPeriod, periodStartDate;
	const taxYearStartDate = moment(`${taxYearStartDateYear}-04-06`);

	if (frequencyCode === "M1") {
		taxPeriod = periodEndDate.diff(taxYearStartDate, "months", false) + 1;
		periodStartDate = periodEndDate.clone().subtract(1, "month").add(1, "day");
		if (lastDayOfMonth) {
			periodStartDate = periodEndDate.clone().startOf("month");
		}

		if (taxPeriod === 12) {
			finalTaxYearSubmission = true;
		}
	} else if (frequencyCode === "W1") {
		taxPeriod = periodEndDate.diff(taxYearStartDate, "weeks", false) + 1;
		if (taxPeriod === 53) {
			periodStartDate = taxYearStartDate
				.clone()
				.add(52, "weeks")
				.format("YYYY-MM-DD");
			finalTaxYearSubmission = true;
		} else {
			periodStartDate = periodEndDate
				.clone()
				.subtract(1, "week")
				.add(1, "day")
				.format("YYYY-MM-DD");
		}
	} else if (frequencyCode === "W2") {
		const weeks = periodEndDate.diff(taxYearStartDate, "weeks", false) + 1;
		taxPeriod = Math.ceil(weeks / 2) * 2;

		if (taxPeriod === 54) {
			finalTaxYearSubmission = true;
			periodStartDate = taxYearStartDate
				.clone()
				.add(52, "weeks")
				.format("YYYY-MM-DD");
		} else {
			periodStartDate = periodEndDate
				.clone()
				.subtract(2, "weeks")
				.add(1, "day")
				.format("YYYY-MM-DD");
		}
	} else {
		// W4
		const weeks = periodEndDate.diff(taxYearStartDate, "weeks", false) + 1;
		taxPeriod = Math.ceil(weeks / 4) * 4;

		if (taxPeriod === 56) {
			finalTaxYearSubmission = true;
			periodStartDate = taxYearStartDate
				.clone()
				.add(52, "weeks")
				.format("YYYY-MM-DD");
		} else {
			periodStartDate = periodEndDate
				.clone()
				.subtract(4, "weeks")
				.add(1, "day")
				.format("YYYY-MM-DD");
		}
	}

	return {
		tax_period: taxPeriod,
		period_start_date: moment(periodStartDate).format("DD/MM/YYYY"),
		period_end_date: periodEndDate.format("DD/MM/YYYY"),
		tax_year_start_date: taxYearStartDate.format("DD/MM/YYYY"),
		final_tax_year_submission: finalTaxYearSubmission,
	};
};

export function isTaxCodeValid(taxCode) {
	const regexTaxCode = /^(?:[CS])?(([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5}))$/;
	return regexTaxCode.test(taxCode);
}

export function getPayItemType (payItem) {
	if (payItem?.is_gross) {
		return `Gross ${payItem?.type}`;
	} else if (payItem?.is_net) {
		return `Net ${payItem?.type}`;
	} else {
		return "Internal error";
	}
}
