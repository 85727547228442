import {
	useMutation,
} from '@tanstack/react-query';
import Request from "../utils/Request";

export function useCreateLead() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/leads`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			return data;
		},
	});
}

export function useCreateUser() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/users`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			localStorage.clear();
			localStorage.setItem('fpEmployerToken', data.token);
			window.location.href = '/onboarding/dashboard';
		},
	});
}

export function useSignUpWithCollegia() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/users/signup-with-collegia`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			localStorage.clear();
			localStorage.setItem('fpEmployerToken', data.token);
			window.location.href = '/onboarding/dashboard';
		},
	});
}

export function useVerifyEmail() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/users/verify-email`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			return data;
		},
	});
}

export function useSearchCompanies() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/search-companies`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			return data;
		},
	});
}

export function useVerifyCompany(setCanProceed) {
	return useMutation({
		mutationFn: async (companyNumber) => {
			return await Request.get(`/api/employers/verify-company/${companyNumber}`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			setCanProceed(data.can_proceed);
			return data;
		},
	});
}

export function useGetDirectors(setDirectors) {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/get-directors`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			setDirectors(data);
			return data;
		},
	});
}

export function useCreateCompany() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			if (data.success) {
				localStorage.clear();
				localStorage.setItem('fpEmployerToken', data.token);

				window.location.href = '/onboarding/dashboard';
			}
			return data;
		},
	});
}


