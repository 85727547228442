import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import Grid from '@mui/material/Grid';
import minLogo from '../images/svg/Logos/new-fp-icon-blue.svg';
import { useNavigate } from 'react-router-dom';
import {useState} from 'react';
import StyleTypography from './StyledComponents/StyleTypography';
import myAccount from '../images/svg/Right Menu/my-account.svg';
import companyProfile from '../images/svg/Right Menu/company-profile.svg';
import legal from '../images/svg/Right Menu/legal.svg';
import logout from '../images/svg/Right Menu/logout.svg';
import RightMenuItem from './RightMenuItem';
import Request from '../utils/Request';
import FullBlackLogo from "../images/svg/Logos/new-fp-logo-blakc-blue-horizontal.svg";
import {Crisp} from "crisp-sdk-web";
import {useUser} from "../context/UserContext";
import Skeleton from "./Skeleton";

export const StyleAppBar = ({
	custom = false,
	customOnClick = () => { },
	backLink = '/',
	showBackButton = false,
}) => {

	const { profileData: user, isPending: fetchingUser  } = useUser();

	const navigate = useNavigate();
	const [rightMenuOpen, setRightMenuOpen] = useState(false);
	const rightMenuItems = [
		{
			name: 'My account',
			icon: myAccount,
			link: '/main/settings/edit-info',
		},
		{
			name: 'Users',
			icon: companyProfile,
			link: '/main/users',
		},
		{
			name: 'Legal',
			icon: legal,
			link: 'https://s3.eu-west-2.amazonaws.com/freepayroll.uk/FreePayroll%2BTerms%2Band%2BConditions%2Bexecutedv2.pdf',
			external: true,
		},
	];

	return (
		<AppBar
			position={'sticky'}
			className={'app-bar'}
		>
			<Toolbar>
				{showBackButton ? (
					<Grid
						aria-label={'menu'}
						sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
					>
						<IconButton
							size={'large'}
							edge={'start'}
							onClick={() => {
								if (custom) {
									customOnClick();
								} else {
									navigate(backLink);
								}
							}}
						>
							<KeyboardBackspaceOutlinedIcon sx={{ color: '#000000' }} />
						</IconButton>
					</Grid>
				) : (
					<Grid
						aria-label={'menu'}
						sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
					>
						<IconButton
							size={'large'}
							edge={'start'}
							onClick={() => {
								navigate('/main');
							}}
							disableFocusRipple
							disableRipple
							disableTouchRipple
						>
							<img
								alt={'logo'}
								src={FullBlackLogo}
								className={'app-bar-full-logo'}
							/>
						</IconButton>
					</Grid>
				)}
				{showBackButton && (
					<Grid
						aria-label={'menu'}
						sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
					>
						<IconButton
							size={'large'}
							edge={'start'}
							disableFocusRipple
							disableRipple
							disableTouchRipple
						>
							<img
								alt={'logo'}
								src={minLogo}
								className={'app-bar-logo'}
							/>
						</IconButton>
					</Grid>
				)}
				{
					user?.name && (
						<Divider
							orientation={'vertical'}
							flexItem
						/>
					)
				}
				{user?.name && (
					<Grid
						className={'app-bar-final-grid'}
						onClick={() => setRightMenuOpen(!rightMenuOpen)}
					>
						<PersonOutlineOutlinedIcon sx={{ color: '#000000', mr: 2 }} />
						{
							fetchingUser ? (
								<Skeleton width={'10vw'} height={'1vw'} background={'#BDBDBD'} />

							) : (
								<Typography
									className={'app-bar-title'}
									component={'div'}
									color={'#000000'}
								>
									{user?.company_name ? user?.company_name : user?.name}
								</Typography>
							)
						}
					</Grid>
				)}
			</Toolbar>

			<Drawer
				anchor={'right'}
				open={rightMenuOpen}
				onClose={() => setRightMenuOpen(false)}
				id={'right-menu-drawer'}
			>
				<Grid
					container
					justifyContent={'flex-start'}
					id={'right-menu-drawer-container'}
				>
					<Grid
						id={'right-menu-drawer-name'}
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={18}
							fontWeight={'bold'}
							color={'#fff'}
						>
							{user?.name}
						</StyleTypography>
					</Grid>

					{Object.values(rightMenuItems).map((item, key) => (
						<RightMenuItem
							icon={item.icon}
							name={item.name}
							link={item.link}
							external={item.external}
							key={key}
						/>
					))}

					<Grid
						container
						alignItems={'center'}
						className={'right-menu-item'}
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						onClick={() => {
							Request.post('api/employers/users/logout').then(() => {
								sessionStorage.clear();
								localStorage.clear();
								Crisp.session.reset();
								navigate('/login');
							});
						}}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={16}
							color={'#BBBBC3'}
						>
							<img
								className={'right-menu-icon'}
								src={logout}
								alt={'Logout'}
							/>
							Logout
						</StyleTypography>
					</Grid>
				</Grid>
			</Drawer>
		</AppBar>
	);
};
