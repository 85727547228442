import {useQuery} from "@tanstack/react-query";
import Request from "../../../utils/Request";

export function useFindPayItem(id) {

	const {
		data,
		isPending,
		isError,
		isLoading
	} = useQuery({
		queryKey: ['pay-item',id],
		queryFn: async () => {
			return await Request.get(`/api/employers/pay-items/find/${JSON.parse(atob(id))}`)
				.then(response => response.data)
		},
		keepPreviousData: false,
		enabled: !!id,
	});

	return {
		payItem: data?.data,
		isLoading,
		isPending,
		isError,
	};
}