import {useMutation} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useSaveHoursWorked() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/payrolls/save-hours`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success("Hours saved successfully");
			return data;
		},
	});
}