import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { validateEmail, validatePassword } from '../../../utils/Helpers';
import closedEye from '../../../images/svg/closed-eye.svg';
import openEye from '../../../images/svg/open-eye.svg';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import inactive from '../../../images/svg/inactive.svg';
import check from '../../../images/svg/check.svg';
import error from '../../../images/svg/error.svg';
import google from '../../../images/svg/google.svg';
import collegiaLogo from '../../../images/svg/Logos/Logo-collegia.svg';
import {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	InputAdornment,
	Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StyleTypography from '../../StyledComponents/StyleTypography';
import NewTextField from '../../common/NewTextField';
import {useCreateUser, useSignUpWithCollegia, useVerifyEmail} from "../../../hooks/signup";
import {FreepayrollButton} from "@collegia-partners/ui-kit";

export function verifyFullName(fullName = '') {
	const trimmedName = fullName.trim();
	const words = trimmedName.split(' ');
	return words.length <= 1;
}
const checkPasswordClass = (checking = null) => {
	if (checking === null) {
		return 'inactive';
	}

	if (checking === true) {
		return 'check';
	}

	return 'error';
};

const checkPasswordIcon = (checking = null) => {
	if (checking === null) {
		return inactive;
	}

	if (checking === true) {
		return check;
	}

	return error;
};

function _validateEmail(email) {
	// Regular expression for basic email validation
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// List of known disposable email domains
	const disposableDomains = [
		'mailinator.com',
		'guerrillamail.com',
		'10minutemail.com',
		'temp-mail.org',
		'throwawaymail.com',
		'tempmail.net',
		'getnada.com',
		'maildrop.cc',
		'sharklasers.com',
		'mailnesia.com',
		'anonbox.net',
		'discard.email',
		'dispostable.com',
		'mailtemp.uk',
		'mailinator2.com',
		'yopmail.com',
		'ethereal.email',
	];

	// Check if the email matches the basic format
	if (!emailRegex.test(email)) {
		return false; // Not a valid email format
	}

	// Extract domain from the email address
	const domain = email.split('@')[1];

	// Check if the domain is in the list of disposable domains
	return !disposableDomains.includes(domain);

	 // Valid email address
}

export const CreateAccountCard = ({
	leadId = null,
}) => {

	const { mutateAsync: signupWithCollegia, isPending } = useSignUpWithCollegia();
	const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();
	const { mutateAsync: verifyEmail, isPending: isVerifyingEmail } = useVerifyEmail();

	const [isValidEmail, setIsValidEmail] = useState(false);

	const [showPassword, setShowPassword] = useState(false);

	const [phoneInfo, setPhoneInfo] = useReducer(
		(state, updates) => ({ ...state, ...updates }),
		{
			phone: '',
			info: {},
		}
	);

	const [contactInfo, setContactInfo] = useReducer(
		(state, updates) => ({ ...state, ...updates }),
		{
			fullName: '',
			email: '',
			password: '',
			confirmPassword: '',
		}
	);

	const [passwordInfo, setPasswordInfo] = useReducer(
		(state, updates) => ({ ...state, ...updates }),
		{
			valid: null,
			character: null,
			number: null,
			length: null,
		}
	);

	const [open, setOpen] = useState(false);

	const [collegia, setCollegia] = useReducer(
		(state, updates) => ({ ...state, ...updates }),
		{
			email: '',
			password: '',
		}
	);

	let timeout = useRef(null);

	const verifyEmailFunction = useCallback(async (email) => {
		try {
			const { error } = await verifyEmail({
				users: {
					email: email,
				},
			});
			if (!error) {
				setIsValidEmail(true);
			} else {
				setIsValidEmail(false);
			}
		} catch (e) {
			console.error(e);
			setIsValidEmail(false);
		}
	},[verifyEmail]);

	useEffect(() => {
		if (contactInfo.email !== '' && _validateEmail(contactInfo.email)) {
			if (timeout) {
				clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				verifyEmailFunction(contactInfo.email);
			}, 1000);
		}
	}, [contactInfo.email, verifyEmailFunction]);

	return (
		<Slide
			direction={'left'}
			in={true}
			mountOnEnter
			unmountOnExit
			timeout={400}
		>
			<Grid id={'sign-up-details'}>
				<Dialog
					onClose={() => setOpen(false)}
					open={open}
					fullWidth={true}
					maxWidth={'sm'}
					id={'signup-collegia'}
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						className={'dialog-close-button-container'}
					>
						<CloseIcon
							sx={{
								fontSize: '1.6vw',
							}}
							className={'dialog-close-button'}
							onClick={() => {
								setCollegia({
									email: '',
									password: '',
								});
								setOpen(false);
							}}
						/>
					</Grid>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						textAlign={'center'}
					>
						<img
							src={collegiaLogo}
							alt={'sign up with collegia'}
							className={'collegia-logo'}
						/>
					</Grid>
					<DialogTitle>
						<StyleTypography
							fontSize={35}
							color={'#000000'}
							fontWeight={'bold'}
							textAlign={'center'}
						>
							Sign up using your Collegia credentials
						</StyleTypography>
					</DialogTitle>
					<DialogContent>
						<Grid
							item
							container
							xl={10}
							lg={10}
							md={10}
							sm={10}
							xs={10}
							justifyContent={'center'}
							rowSpacing={2}
						>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<NewTextField
									label={'Email*'}
									required
									value={collegia.email}
									onChange={(e) =>
										setCollegia({
											email: e.target.value,
										})
									}
								/>
							</Grid>

							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<NewTextField
									label={'Password*'}
									required
									type={'password'}
									value={collegia.password}
									onChange={(e) =>
										setCollegia({
											password: e.target.value,
										})
									}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Grid
							item
							container
							xl={10}
							lg={10}
							md={10}
							sm={10}
							xs={10}
							justifyContent={'center'}
							rowSpacing={2}
						>
							<Grid
								item
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
							>
								<FreepayrollButton
									variant={"primary"}
									onClick={async () => {
										try {
											await signupWithCollegia({
												users: {
													...collegia,
													lead_id: leadId,
												},
											})
										} catch (e) {
											console.error(e);
										}
									}}
									isLoading={isPending}
									className={'default-black-button'}
								>
									Login
								</FreepayrollButton>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>

				<div style={{ position: 'relative' }}>
					<Button
						id={'google-sign-up'}
						fullWidth
						disabled={true}
						style={{ position: 'relative', zIndex: 1 }}
					>
						<img
							src={google}
							alt={'sign up with google'}
						/>
						Sign up with Google
					</Button>
					<div
						style={{
							position: 'absolute',
							top: '0', // Adjust to align with the top of the button
							left: '0', // Position to give the effect of wrapping from behind
							backgroundColor: '#016efd', // Background color of the label
							color: '#fff', // Text color of the label
							padding: '5px 8px',
							fontSize: '0.75rem',
							fontWeight: 'bold',
							boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)', // Shadow for depth effect
							textAlign: 'center',
							zIndex: 999, // Lower z-index so it goes 'under' the button
						}}
					>
						Available Soon
					</div>
				</div>

				<Button
					id={'collegia-sign-up'}
					onClick={() => {
						setOpen(true);
					}}
					fullWidth
				>
					<img
						src={collegiaLogo}
						alt={'sign up with collegia'}
					/>
					Sign up with Collegia
				</Button>

				<Divider>
					<Typography className={'side-lines'}>or</Typography>
				</Divider>

				<NewTextField
					label={'Full Name'}
					className={'create-account-card-text-field'}
					required
					value={contactInfo.fullName}
					onChange={(e) =>
						setContactInfo({
							fullName: e.target.value,
						})
					}
					error={
						verifyFullName(contactInfo.fullName) && contactInfo.fullName !== ''
					}
					helperText={
						verifyFullName(contactInfo.fullName) && contactInfo.fullName !== ''
							? 'Please provide your full name'
							: ''
					}
					name={'name'}
				/>

				<NewTextField
					label={'Work email'}
					name={'email'}
					error={
						(contactInfo.email !== '' && !validateEmail(contactInfo.email)) ||
						!isValidEmail
					}
					helperText={
						contactInfo.email !== '' && !validateEmail(contactInfo.email)
							&& 'Invalid e-mail'

					}
					required
					value={contactInfo.email}
					onChange={(e) => {
						setIsValidEmail(false);
						setContactInfo({
							email: e.target.value.toLowerCase(),
						})
					}}
					InputProps={{
						endAdornment: isVerifyingEmail && contactInfo.email !== '' && (
							<InputAdornment position={'end'}>
								<CircularProgress size={'18px'} />
							</InputAdornment>
						),
					}}
				/>

				<InputLabel
					className={'default-input-label create-account-card-phone-label'}
				>
					Phone
				</InputLabel>

				<MuiTelInput
					className={'default-phone-input create-account-card-text-field'}
					defaultCountry={'GB'}
					flagSize={'medium'}
					forceCallingCode
					value={phoneInfo.phone}
					error={!matchIsValidTel(phoneInfo.phone) && phoneInfo.phone !== ''}
					onChange={(value, info) =>
						setPhoneInfo({
							phone: value,
							info: info,
						})
					}
				/>
				<NewTextField
					required
					label={'Set password'}
					autoComplete="new-password"
					value={contactInfo.password}
					onChange={(e) => {
						setContactInfo({
							password: e.target.value,
						});
						setPasswordInfo(validatePassword(e.target.value));
					}}
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<img
								src={showPassword ? closedEye : openEye}
								alt={'show password'}
								onClick={() => setShowPassword(!showPassword)}
							/>
						),
					}}
				/>

				<Grid
					className={'password-check'}
					container
					alignItems={'center'}
				>
					<img
						src={checkPasswordIcon(passwordInfo.length)}
						alt={'Use 8 or more characters'}
					/>

					<Typography className={checkPasswordClass(passwordInfo.length)}>
						Use 8 or more characters.
					</Typography>
				</Grid>

				<Grid
					className={'password-check'}
					container
					alignItems={'center'}
				>
					<img
						src={checkPasswordIcon(passwordInfo.character)}
						alt={'use a minimum of one letter'}
					/>

					<Typography className={checkPasswordClass(passwordInfo.character)}>
						Use a minimum of one letter.
					</Typography>
				</Grid>

				<Grid
					className={'password-check'}
					container
					alignItems={'center'}
				>
					<img
						src={checkPasswordIcon(passwordInfo.number)}
						alt={'use a minimum of one number'}
					/>

					<Typography className={checkPasswordClass(passwordInfo.number)}>
						Use a minimum of one number.
					</Typography>
				</Grid>
				<NewTextField
					label={'Confirm password'}
					error={
						!(contactInfo.password === contactInfo.confirmPassword) &&
						contactInfo.confirmPassword !== ''
					}
					className={'create-account-card-text-field'}
					required
					autoComplete="new-password"
					value={contactInfo.confirmPassword}
					onChange={(e) =>
						setContactInfo({
							confirmPassword: e.target.value,
						})
					}
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<img
								src={showPassword ? closedEye : openEye}
								alt={'show password'}
								onClick={() => setShowPassword(!showPassword)}
							/>
						),
					}}
				/>

				<FreepayrollButton
					variant={"primary"}
					className={'default-black-button'}
					isLoading={isCreatingUser}
					disabled={
						!contactInfo.fullName ||
						!contactInfo.email ||
						!contactInfo.password ||
						!contactInfo.confirmPassword ||
						!(contactInfo.password === contactInfo.confirmPassword) ||
						!phoneInfo.phone ||
						!matchIsValidTel(phoneInfo.phone) ||
						!passwordInfo.valid ||
						isVerifyingEmail ||
						verifyFullName(contactInfo.fullName) ||
						!_validateEmail(contactInfo.email) ||
						!isValidEmail
					}
					onClick={async () => {
						try {
							await createUser({
								users: {
									email: contactInfo.email,
									password: contactInfo.password,
									name: contactInfo.fullName,
									phone_number: phoneInfo.phone,
									lead_id: leadId,
								},
							})
						} catch (e) {
							console.error(e);
						}
					}}
				>
					Create Account
				</FreepayrollButton>
			</Grid>
		</Slide>
	);
};