import { connect } from "react-redux";
import FullLogoBlack from "../images/svg/Logos/new-pension-black-blue-full-logo.svg";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
	Checkbox,
	Divider,
	FreepayrollButton,
	FreepayrollSelect,
	FreepayrollTextField,
	Typography,
} from "@collegia-partners/ui-kit";
import Loading from '../components/Loading';
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import DirectDebitIcon from "../images/svg/direct-debit-1.svg";
import CheckIcon from "../images/svg/check.svg";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import Request from "../utils/Request";
import DDLogo from "../images/svg/direct-debit-1.svg";
import StyleButton from "../components/StyledComponents/StyleButton";
import {toast} from "sonner";

const MAX_STEPS = 3;
const ALLOWED_DAYS = Array.from({ length: 31 }, (_, i) => {
	const day = i + 1;

	if (day < 9 || day > 22) {
		return undefined;
	}

	return day;
}).filter((d) => typeof d !== "undefined");

const AutoEnrolment = () => {
	const token = useParams().token;
	const [data, setData] = useState({});
	const [step, setStep] = useState(0);
	const [isTokenLoading, setIsTokenLoading] = useState(false);
	const [retrieveDataLoading, setRetrieveDataLoading] = useState(false);
	const [bureauName, setBureauName] = useState(null);

	const onNext = useCallback((customData) => {
		if (customData) setData((prev) => ({ ...prev, ...customData }));

		setStep((prev) => {
			if (prev < MAX_STEPS) return prev + 1;
			return prev;
		});
	}, []);

	const onPrev = useCallback(() => {
		setStep((prev) => {
			if (prev > 0) return prev - 1;
			return prev;
		});
	}, []);

	const checkTokenStatus = useCallback(async (mode = null) => {
		try {
			if (mode) {
				setRetrieveDataLoading(true);
				const { data } = await Request.get(`/api/employers/pending-direct-debit/retrieve-token-status/${token}?mode=${mode}`);

				if (data.completed) {
					setBureauName(data.bureau_name);
					setStep(2);
				}

			} else {
				const { data } = await Request.get(`/api/employers/pending-direct-debit/retrieve-token-status/${token}`);

				if (data.data?.id) {
					setIsTokenLoading(false);
					setStep(2);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setRetrieveDataLoading(false);
		}
	},[token]);

	useEffect(() => {
		checkTokenStatus('token_active');
	}, [checkTokenStatus]);

	useEffect(() => {
		let timeout;
		if (isTokenLoading) {
			timeout = setInterval(() => {
				checkTokenStatus();
			}, 2000);
		}
		return () => clearTimeout(timeout);
	}, [checkTokenStatus, isTokenLoading]);

	const component = [
		//Remove the identity validation, for now
		<AutoEnrolmentSelectContributionDate onNext={onNext} setIsTokenLoading={setIsTokenLoading}/>,
		<AutomaticEnrolmentForm onNext={onNext} onPrev={onPrev} formData={data} />,
		<SuccessPage bureauName={bureauName} />,
	][step];

	if (isTokenLoading || retrieveDataLoading) {
		return (
			<div className="wrapper">
				<PublicHeader/>
				<Divider/>
				<Loading/>
			</div>
		)
	}

	return (
		<div className="wrapper">
			<PublicHeader/>
			<Divider/>
			{component}
		</div>
	);
};

function SuccessPage({bureauName}) {

	return (
		<div className="wrapper-step wrapper-step-center">
			<img src={CheckIcon} alt="Checked Icon" className="main-icon" />
			<Typography variant="title" size="xlarge">
				Thanks for setting up your
				<br />
				direct debit instruction.
			</Typography>
			<Typography variant="body">
				You can close this tab now, {bureauName} will be notified of your completion.
			</Typography>

			<div className="start-now-button">
				<FreepayrollButton
					variant="primary"
					size="xsmall"
					onClick={() => {
						window.close();
					}}
				>
					Finish
				</FreepayrollButton>
			</div>
		</div>
	);
}

const debitSchema = z.object({
	accountHoldName: z
		.string()
		.min(3, { message: "Account holder name is too short" })
		.max(50, { message: "Account holder name is too long" }),
	email: z.string().email({ message: "Email is not valid" }),
	postCode: z.string()
		.min(5, { message: "Post code is too short" })
		.max(10, { message: "Post code is too long" }),
	addressLine1: z.string().min(3, { message: "Address line 1 is too short" }),
	addressLine2: z.string().optional(),
	city: z.string().min(3, { message: "City is too short" }),
	country: z.string().min(3, { message: "Country is too short" }),
});

function AutomaticEnrolmentForm({ onNext, onPrev, formData,  }) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(debitSchema),
	});
	const [sortCode, setSortCode] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	const [accountNumberError, setAccountNumberError] = useState(null);
	const [sortCodeError, setSortCodeError] = useState(null);
	const [isLoadingAccount, setIsLoadingAccount] = useState(false);
	const [phone, setPhone] = useState("");
	const [phoneError, setPhoneError] = useState(null);
	const [isCheckedOwner, setIsCheckedOwner] = useState(false);
	const [iban, setIban] = useState(null);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const token = useParams().token;

	let timeout = useRef(null);

	useEffect(() => {
		if (
			accountNumber !== '' &&
			sortCode !== ''
		) {
			if (timeout) {
				clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				setIsLoadingAccount(true);
				setIban(null);
				Request.post('/api/validate-iban', {
					"nuapay": {
						account_number: accountNumber,
						sort_code: sortCode,
					}
				}).then((response) => {
					setIban(response.data.iban);
					setIsLoadingAccount(false);
					setAccountNumberError(false);
					setSortCodeError(false);
				}).catch(() => {
					setIban(null);
					setIsLoadingAccount(false);
					setAccountNumberError(true);
					setSortCodeError(true);
				});
			}, 1000);
		}
	}, [accountNumber, sortCode]);

	const onSubmit = useCallback(
		async (data) => {
			if (accountNumberError || sortCodeError || phoneError || !iban) {
				toast.error('Please complete all required fields');
				return;
			}

			if (!isCheckedOwner) {
				toast.error('Please confirm that you are the account owner and sole signatory');
				return;
			}

			if (!matchIsValidTel(phone)) {
				setPhoneError(true);
				toast.error("Phone number is invalid");
				return;
			}

			try {
				setIsButtonLoading(true);
				const response = await Request.post(`/api/employers/pending-direct-debit/manual-mandate-signing/${token}`,{
					'pending-direct-debit':{
						name: data.accountHoldName,
						line1: data.addressLine1,
						line2: data.addressLine2,
						town: data.city,
						postCode: data.postCode,
						mobileNumber: phone,
						email: data.email,
						iban: iban,
						collection_day: formData.collectionDay,
					},
				});
				if (response?.data?.success) {
					onNext({
						...data,
						sortCode,
						accountNumber,
						phone,
						isCheckedOwner,
						iban
					});
				}
			} catch (error) {
				toast.error('Please contact support and inform code: EX-32');
				console.error(error);
			} finally {
				setIsButtonLoading(false);
			}
		}, [accountNumber, accountNumberError, formData.collectionDay, iban, isCheckedOwner, onNext, phone, phoneError, sortCode, sortCodeError, token]);

	return (
		<div className="wrapper-step">
			<div className="text-wrapper">
				<Typography variant="title">Automatic Enrolment</Typography>
				<Typography variant="body">
					We need to set up a Direct Debit to collect pension contributions.
					Don't worry, the Auto Enrolment pension is free, only pension
					contributions will be collected.
				</Typography>
				<Divider color="light-gray" />
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="second-form">
				<div className="form-wrapper">
					<FreepayrollTextField
						label="Account holder name *"
						fieldType="text"
						error={!!errors.accountHoldName}
						helperText={errors.accountHoldName?.message}
						{...register("accountHoldName")}
					/>
					<FreepayrollTextField
						label="Email *"
						fieldType="text"
						error={!!errors.email}
						helperText={errors.email?.message}
						{...register("email")}
					/>
				</div>
				<div className="form-wrapper">
					<FreepayrollTextField
						label="Sort code *"
						fieldType="text"
						name="sortCode"
						value={sortCode}
						onChange={(e) => setSortCode(e.target.value)}
						error={sortCodeError}
						helperText={sortCodeError && ("Sort code is invalid")}
						isLoading={isLoadingAccount}
					/>
					<FreepayrollTextField
						label="Account number *"
						fieldType="text"
						name="accountNumber"
						value={accountNumber}
						onChange={(e) => setAccountNumber(e.target.value)}
						error={accountNumberError}
						helperText={accountNumberError && ("Account number is invalid")}
						isLoading={isLoadingAccount}
					/>
				</div>
				<div className="form-wrapper">
					<div className="phone-container">
						<Typography variant="body" color="black">
							Phone
						</Typography>
						<MuiTelInput
							className={"default-phone-input"}
							defaultCountry={"GB"}
							flagSize={"medium"}
							forceCallingCode
							value={phone}
							error={!!phoneError}
							onChange={(value, error) => {
								setPhoneError(false);
								setPhone(value);
							}}
						/>
					</div>
					<FreepayrollTextField
						label="Postcode *"
						fieldType="text"
						error={!!errors.postCode}
						helperText={errors.postCode?.message}
						{...register("postCode")}
					/>
				</div>
				<div className="form-wrapper">
					<FreepayrollTextField
						label="Address line 1 *"
						fieldType="text"
						error={!!errors.addressLine1}
						helperText={errors.addressLine1?.message}
						{...register("addressLine1")}
					/>
					<FreepayrollTextField
						label="Address line 2"
						fieldType="text"
						error={!!errors.addressLine2}
						helperText={errors.addressLine2?.message}
						{...register("addressLine2")}
					/>
				</div>
				<div className="form-wrapper">
					<FreepayrollTextField
						label="City *"
						fieldType="text"
						error={!!errors.city}
						helperText={errors.city?.message}
						{...register("city")}
					/>
					<FreepayrollTextField
						label="Country *"
						fieldType="text"
						error={!!errors.country}
						helperText={errors.country?.message}
						{...register("country")}
					/>
				</div>
				<div className="form-wrapper">
					<Checkbox
						isChecked={isCheckedOwner}
						onChange={() => setIsCheckedOwner((old) => !old)}
						label="Tick to confirm that you are the account owner and sole signatory"
					/>
				</div>
				<div className="footer-wrapper">
					<Typography variant="body">
						*required fields.
						<br />
						<br />
						You will be notified via e-mail once your Direct Debit Instructions
						has been activated. The notification will be sent within 3 working
						days or no later than 5 working days before the first collection.
						<br />
						<br />
						It will show in your statement as Nuapay Re Collegia
						<br />
						<br />
						Your payments are protected by the{" "}
						<Typography variant="link" href="#" color="black" weight="bold">
							Direct Debit Guarantee
						</Typography>
					</Typography>

					<img
						src={DirectDebitIcon}
						alt="Direct debit icon"
						className="direct-debit-icon"
					/>

					<div className="buttons-wrapper">
						<FreepayrollButton
							variant="primary"
							type="submit"
							isLoading={isButtonLoading || isLoadingAccount}
							size="xsmall"
						>
							SIGN DIRECT DEBIT INSTRUCTIONS
						</FreepayrollButton>
						<FreepayrollButton
							variant="white-button"
							type="button"
							size="xsmall"
							onClick={onPrev}
						>
							CANCEL MANDATE SIGNING
						</FreepayrollButton>
					</div>
				</div>
			</form>
		</div>
	);
}

function PublicHeader() {
	return (
		<div className="header">
			<img src={FullLogoBlack} alt="FreePayroll" className="logo" />
			<button className="button-circle">?</button>
		</div>
	);
}

// function AutoEnrolmentIdentityVerification({ onNext, setStep }) {
//
// 	const token = useParams().token;
// 	const [isLoading, setIsLoading] = useState(false);
// 	const retrieveData = useCallback(async () => {
// 		setIsLoading(true);
// 		try {
// 			const { data } = await Request.get(`/api/employers/pending-direct-debit/${token}`);
// 			if (!data.data.token_active) {
// 				setStep(3);
// 				toast.info('Mandate already signed');
// 			}
// 		} catch (error) {
// 			console.error(error);
// 		} finally {
// 			setIsLoading(false);
// 		}
// 	}, [setStep, token]);
//
// 	useEffect(() => {
// 		retrieveData();
// 	}, [retrieveData]);
//
// 	if (isLoading) {
// 		return (
// 			<Loading/>
// 		)
// 	}
//
// 	return (
// 		<div className="wrapper-step wrapper-step-center">
// 			<img
// 				src={WhiteAndBlueWarning}
// 				alt="white and blue warning"
// 				className="main-icon"
// 			/>
// 			<Typography variant="title" weight="normal">
// 				<b>Important:</b> Identity verification
// 			</Typography>
// 			<Typography variant="body">
// 				BureauName has requested that you sign the direct debit for your auto
// 				enrolment pension collection.
// 				<br />
// 				First, you will need to confirm your identity, by inputting the code
// 				that you will receive on your email.
// 			</Typography>
//
// 			<div className="start-now-button">
// 				<FreepayrollButton variant="primary" size="xsmall" onClick={onNext}>
// 					Start now!
// 				</FreepayrollButton>
// 			</div>
// 		</div>
// 	);
// }

function AutoEnrolmentSelectContributionDate({ onNext, setIsTokenLoading }) {
	const [collectionDay, setCollectionDay] = useState(null);
	const [isBankModalOpen, setIsBankModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const token = useParams().token;

	const signViaOpenBanking = async () => {
		setIsLoading(true);
		try {
			const { data } = await Request.post(`/api/employers/pending-direct-debit/open-banking-signing/${token}`,{
				'pending-direct-debit':{collection_day: collectionDay},
			});
			let link = '';
			if (process.env.REACT_APP_ENV === 'production') {
				link =
					'https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
					data.token;
			} else {
				link =
					'https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
					data.token;
			}
			window.open(link, '_blank');
			setIsTokenLoading(true);
			setIsBankModalOpen(false);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}

	};

	return (
		<div className="wrapper-step wrapper-step-center text-wrapper">
			<Typography variant="title">Automatic Enrolment</Typography>
			<Typography variant="body">
				Automatic Enrolment is a UK government initiative that requires
				employers to enrol eligible employees into a qualifying workplace
				pension scheme automatically. As an employer, you are legally obliged to
				provide a workplace pension scheme for your employees and make
				contributions to it.
			</Typography>
			<Divider color="light-gray" />
			<Typography variant="body">
				Eligible employees who meet certain criteria are automatically enrolled
				into the scheme, but they have the option to opt-out if they choose to
				do so. Minimum contribution rates for employers and employees are set by
				law, but you can increase them if wnat to. Employers must communicate
				with their employees and keep accurate records to ensure compliance with
				the legislation.
			</Typography>

			<div>
				<Typography variant="subtitle2" color="black">
					Select the date you want pension contributions to be collected
				</Typography>
				<Typography variant="body">
					All outstanding pension contributions are collected once a month
					through Direct Debit.
					<br />
					You can select which day of the month they'll be collected.
				</Typography>
			</div>

			<div className="select-wrapper">
				<FreepayrollSelect
					label="Select the date"
					options={ALLOWED_DAYS.map((day) => ({
						label: day.toString(),
						value: day.toString(),
					}))}
					onSelect={(e) => setCollectionDay(e?.value || "")}
					noGhostHelperText
				/>
			</div>

			<Typography variant="subtitle2" color="black">
				We need to setup a Direct Debit to collect pension contributions from
				your bank account.
				<br />
				How would you like to sign your Direct Debit?
			</Typography>

			<div className="buttons-wrapper">
				<FreepayrollButton
					variant="primary"
					size="xsmall"
					onClick={() => setIsBankModalOpen(true)}
				>
					Via Open Banking
				</FreepayrollButton>
				<FreepayrollButton
					variant="white-button"
					onClick={() => onNext({ collectionDay })}
					size="xsmall"
				>
					Sign Manually
				</FreepayrollButton>
			</div>

			<Dialog
				onClose={() => setIsBankModalOpen(false)}
				open={isBankModalOpen}
				fullWidth={true}
				maxWidth={'md'}
				id={'direct-debit-guarantee-dialog'}
			>
				<DialogTitle>
					<Grid
						container
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Grid>
							<Typography variant={"body"} className={'dialog-title'}>
								We need to set up a Direct Debit to <br />
								collect pension contributions.
							</Typography>
						</Grid>
						<Grid>
							<img
								src={DDLogo}
								alt={'sign up with collegia'}
								className={'dd-logo'}
							/>
						</Grid>
					</Grid>
					<Typography variant={"body"} className={'dialog-title-subtitle'}>
						Don’t worry, FreePayroll is free, we will only collect pension contributions.
					</Typography>
				</DialogTitle>
				<DialogContent>
					<ul>
						<li>
							We require your authorisation to collect the outstanding pension
							contributions for your workforce because of Automatic Enrolment.
							This is a UK legal requirement.
						</li>
						<li>
							When you sign the Direct Debit mandate, you understand you grant
							Collegia Partners Limited, which is regulated by the FCA FRN
							922214, the authority to collect those pension contributions from
							your corporate bank account.
						</li>
						<li>
							This Guarantee is offered by all banks and building societies that
							accept instructions to pay Direct Debits.
						</li>
						<li>
							If an error is made in the payment of your Direct Debit by Nuapay
							re Collegia Partners Trustees Limited T/A Collegia or your bank or
							building society, you are entitled to a full and immediate refund
							of the amount paid from your band or building society.
						</li>
						<li>
							If you receive a refund you are not entitled to, you must pay it
							back when Nuapay re Collegia Partners Trustees Limited T/A
							Collegia asks you to.
						</li>
						<li>
							You can cancel a Direct Debit at any time by simply contacting
							your bank or building society. Written confirmation may be
							required. Please also notify us.
						</li>
					</ul>
				</DialogContent>
				<DialogActions>
					<Grid>
						<StyleButton
							sx={{
								height: '3vw',
								minWidth: '18vw'
							}}
							isWhiteButton={true}
							fullWidth={false}
						>
							Cancel
						</StyleButton>
					</Grid>
					<Grid>
						<StyleButton
							sx={{
								height: '3vw',
								minWidth: '18vw'
							}}
							fullWidth={false}
							onClick={() => signViaOpenBanking()}
							disabled={isLoading}
						>
							Sign Mandate
						</StyleButton>
					</Grid>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default connect()(AutoEnrolment);
