import React from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {CompletedContent} from "../../../components/CompletedContent/CompletedContent";
import {useDownloadNetPayReport} from "../../../hooks/payrolls";

const CompletedPayroll = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { mutate: downloadNetPay } = useDownloadNetPayReport();

    return (
        <CompletedContent
            mode={"completed"}
            approvalAction={"Payroll completed"}
            scheduleName={searchParams.get("pay_schedule")}
            taxPeriod={searchParams.get("tax_period")}
            payDate={searchParams.get("pay_date")}
            onButtonClick={() => navigate("/main")}
            onDownloadClick={() => downloadNetPay(searchParams.get("id"))}
        />
    );
}

export default CompletedPayroll;