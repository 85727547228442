import React, {useState} from "react";
import {Drawer} from "@mui/material";
import {FreepayrollDropbox, Typography, Icon, Divider} from "@collegia-partners/ui-kit";
import {useNavigate} from "react-router-dom";
import {toast} from "sonner";
import Request from "../../utils/Request";
import {useViewPayslip} from "../../hooks/employees";

function renderPaymentBreakdownLine(title, value, isDeduction = false) {
	return (
		<div className={"PaymentBreakdownLine"}>
			<Typography
				variant={"body"}
				color={"black"}
				weight={"medium"}
			>
				{title}
			</Typography>
			{
				isDeduction ? (
					<Typography variant={"body"} weight={"bold"} color={ value > 0 ? "red" : 'black'}>
						{
							parseFloat(value) > 0 && "("
						}
						{
							(parseFloat(value) ?? 0.00).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).replace('-','')
						}
						{
							parseFloat(value) > 0 && ")"
						}
					</Typography>
				) : (
					<Typography variant={"body"} weight={"bold"} color={"black"}>
						{(parseFloat(value) ?? 0.00).toLocaleString('en-GB', {
							style: 'currency',
							currency: 'GBP',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</Typography>
				)
			}
		</div>
	);
}


/**
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeePayrollBreakdown = ({
	selectedEmployee,
	open,
	onClose,
	payrollMode,
}: {
	selectedEmployee: any,
	open: boolean,
	onClose: () => void,
	payrollMode: "RUN_PAYROLL" | "VIEW_PAYROLL" | "PAYROLL_APPROVAL",
}): JSX.Element => {

	const push = useNavigate();
	const [isSendingPayslip, setIsSendingPayslip] = useState(false);
	
	const { mutate: viewPayslip, isPending: isOpeningPayslip } = useViewPayslip();

	const onSendPayslip = async () => {
		setIsSendingPayslip(true);
		await toast.promise(
			Request.post('/api/employers/send-payslip',{
				"pay_schedule_runs": {
					id: selectedEmployee.id,
				}
			}),
			{
				pending: 'Sending payslip...',
				success: 'Payslip sent successfully',
				error: 'An error occurred while sending payslip, please contact support',
			}
		);
		setIsSendingPayslip(false);
	}

	return (
		<div>
			<Drawer
				open={open}
				onClose={onClose}
				anchor="right"
				sx={{
					width: '24.5313vw',
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: '24.5313vw',
					},
				}}
				autoFocus={false}
			>
				<div id={"EmployeeSummary"}>
					<div className={"Header"}>
						<div className={"Avatar"}>
							<Typography variant={"title"} size={"xmedium"} color={"black"} weight={"bold"}>
								{selectedEmployee.employee?.forename[0]}{selectedEmployee.employee?.surname[0]}
							</Typography>
						</div>
						<div>
							<div style={{cursor: "pointer"}} onClick={() => push(`/main/people/record/${selectedEmployee.employee?.safe_id}`)} >
								<Typography variant={"title"} size={"xmedium"} color={"black"} weight={"bold"}>
									{selectedEmployee.employee?.forename} {selectedEmployee.employee?.surname}
								</Typography>
							</div>
							<Typography variant={"label"} size={"small"} color={"black"} className={"TaxCodeLabel"}>
								Tax
								Code <strong>{selectedEmployee.employee?.tax_code} {!selectedEmployee.employee?.is_cumulative && '(W1/M1)'}</strong>
								| NI Cat <strong>{selectedEmployee.employee?.ni_category}</strong>
							</Typography>
						</div>
						<div className={"EmployeeSummaryActionsContainer"}>
							<FreepayrollDropbox
								target={
									<button
										style={{all: "unset", cursor: "pointer"}}
									>
										<Icon
											iconName="dots-horizontal"
											className={"EmployeeSummaryIcon"}
										/>
									</button>
								}
								className={"EmployeeSummaryActions"}
							>
								<button
									onClick={() => {
										viewPayslip({
											id: selectedEmployee.id,
											safeId: selectedEmployee.employee?.safe_id,
										})
									}}
									disabled={isOpeningPayslip}
									className={"EmployeeSummaryAction"}
								>
									{
										isOpeningPayslip ?
											"Opening payslip..."
											:
											payrollMode !== "VIEW_PAYROLL" ? "View payslip (Draft)" : "View payslip"
									}
								</button>
								{
									payrollMode !== "PAYROLL_APPROVAL" && (
										<button
											onClick={onSendPayslip}
											disabled={isSendingPayslip}
											className={"EmployeeSummaryAction"}
										>
											{payrollMode !== "VIEW_PAYROLL" ? "Send payslip (Draft)" : "Send payslip"}
										</button>
									)
								}
							</FreepayrollDropbox>
						</div>
					</div>
					{
						payrollMode === 'RUN_PAYROLL' && (
							<div>
								<Typography variant={"body"} color={"black"} className={"AddPayItem"}>
									+ Add pay item
								</Typography>
							</div>
						)
					}
					<div className={"EmployeePaymentBreakdown"}>
						<Typography variant={"body"} color={"black"} weight={"bold"}>Summary</Typography>
						{renderPaymentBreakdownLine("Base pay", selectedEmployee.base_pay_amount)}
						{selectedEmployee.smp > 0 && renderPaymentBreakdownLine("SMP", selectedEmployee.smp)}
						{selectedEmployee.spp > 0 && renderPaymentBreakdownLine("SPP", selectedEmployee.spp)}
						{selectedEmployee.ssp > 0 && renderPaymentBreakdownLine("SSP", selectedEmployee.ssp)}
						{selectedEmployee.pg_loan > 0 && renderPaymentBreakdownLine("PG Loan", selectedEmployee.pg_loan, true)}
						{selectedEmployee.student_loan > 0 && renderPaymentBreakdownLine("Student Loan", selectedEmployee.student_loan, true)}
						{
							selectedEmployee.pay_items && selectedEmployee.pay_items.filter(item => item.is_gross).map((payItem) => {
								return renderPaymentBreakdownLine(payItem.item_name, payItem.item_amount, payItem.item_type === 'Deduction')
							})
						}
						{renderPaymentBreakdownLine("Employee NI", selectedEmployee.employee_ni_deducted, true)}
						{renderPaymentBreakdownLine("Income Tax", selectedEmployee.income_tax_deducted, true)}
						{renderPaymentBreakdownLine("Employee Pension", selectedEmployee.employee_pension_deducted, true)}
						{
							selectedEmployee.pay_items && selectedEmployee.pay_items.filter(item => item.is_net).map((payItem) => {
								return renderPaymentBreakdownLine(payItem.item_name, payItem.item_amount, payItem.item_type === 'Deduction')
							})
						}
						{renderPaymentBreakdownLine("Net pay", selectedEmployee.net_pay)}
						<Divider color="light-gray"/>
						<Typography variant={"body"} color={"black"} weight={"bold"}>Employer</Typography>
						{renderPaymentBreakdownLine("Employer NI", selectedEmployee.employer_ni_deducted)}
						{renderPaymentBreakdownLine("Employer Pension", selectedEmployee.employer_pension_deducted)}
					</div>
				</div>
			</Drawer>
		</div>
	);
};

export default EmployeePayrollBreakdown;
