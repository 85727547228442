import ReportsList from "./ReportsList";
import ReportsP60 from "./YearEndReports/ReportsP60";
import ReportsP11 from "./YearEndReports/ReportsP11";
import ReportsEps from "./HmrcSubmissions/ReportsEps";
import ReportsFps from "./HmrcSubmissions/ReportsFps";
import ReportsTaxesFilings from "./ReportsTaxesFilings";
import PayrollJournal from "./PayrollJournal";

const Reports = {
	ReportsList,
	ReportsP60,
	ReportsP11,
	ReportsEps,
	ReportsFps,
	ReportsTaxesFilings,
	PayrollJournal,
}
export default Reports;