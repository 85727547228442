import React, {useCallback, useEffect, useReducer, useState} from "react";
import Loading from "../../../../components/Loading";
import {
	GeneralFields,
	RequireClientApproval,
	UponApprovalClosePayroll, WhatIsPaySchedule
} from "../../../../components/Pay Schedules/PayScheduleSettings";
import {Divider, FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import {
	PayScheduleConfirmDialog
} from "../../../../components/Pay Schedules/PayScheduleSettings/PayScheduleConfirmDialog";
import {resolveTaxPeriodAndPeriodDates} from "../../../../utils/Helpers";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import {useNavigate} from "react-router-dom";
import {useGetEmployer} from "../../../../hooks/employer";
import {useUser} from "../../../../context/UserContext";

function StepsPaySchedule ({
	step = 0,
	schedule = {},
	setSchedule = () => {},
	minDate,
	maxDate,
	isBureau = false,
}) {
	switch (step) {
		case 0:
			return (
				<WhatIsPaySchedule/>
			);
		case 1:
			return (
				<GeneralFields
					scheduleName={schedule.scheduleName}
					setScheduleName={(e) => {
						schedule.scheduleName = e.target.value;
						setSchedule(schedule);
					}}
					payFrequency={schedule.payFrequency}
					setPayFrequency={(option) => {
						if (!option) return;
						schedule.payFrequency = option.value;
						schedule.payDays = null;
						schedule.firstPayDate = null;
						setSchedule(schedule);
					}}
					payDay={schedule.payDays}
					setPayDay={(option) => {
						if (!option) return;
						schedule.payDays = option.value;
						schedule.firstPayDate = null;
						setSchedule(schedule);
					}}
					minDate={minDate}
					maxDate={maxDate}
					payDate={schedule.firstPayDate}
					setPayDate={(value) => {
						schedule.firstPayDate = value.format("YYYY-MM-DD");
						schedule.taxPeriod = resolveTaxPeriodAndPeriodDates(
							schedule?.payFrequency,
							value,
							schedule?.payDays === "Last Day of Month"
						)["tax_period"];
						setSchedule(schedule);
					}}
					dayRateMethod={schedule.dayRateMethod}
					setDayRateMethod={(option) => {
						if (!option) return;
						schedule.dayRateMethod = option.value;
						setSchedule(schedule);
					}}
					isAutomaticPaySchedule={schedule.automaticPaySchedule}
					setIsAutomaticPaySchedule={(value) => {
						schedule.automaticPaySchedule = value;
						if (!value) {
							schedule.submissionDateOffset = null;
							schedule.clientAuthorisation = false;
							schedule.closePayRun = false;
							schedule.authoriserName = "";
							schedule.authoriserEmail = "";
						}
						setSchedule(schedule);
					}}
					submissionDateOffset={schedule.submissionDateOffset}
					setSubmissionDateOffset={(option) => {
						schedule.submissionDateOffset = option.value;
						setSchedule(schedule);
					}}
					isBureau={isBureau}
				/>
			);
		case 2:
			return (
				<RequireClientApproval
					requireClientApproval={schedule.clientAuthorisation}
					authorizerName={schedule.authoriserName}
					authorizerEmail={schedule.authoriserEmail}
					setRequireClientApproval={(value) => {
						schedule.clientAuthorisation = value;
						if (!value) {
							schedule.authoriserName = "";
							schedule.authoriserEmail = "";
							schedule.closePayRun = false;
						}
						setSchedule(schedule);
					}}
					setAuthorizerName={(e) => {
						schedule.authoriserName = e.target.value;
						setSchedule(schedule);
					}}
					setAuthorizerEmail={(e) => {
						schedule.authoriserEmail = e.target.value;
						setSchedule(schedule);
					}}
				/>
			);
		case 3:
			return (
				<UponApprovalClosePayroll
					submitPayrollUponClientApproval={schedule.closePayRun}
					setSubmitPayrollUponClientApproval={(value) => {
						schedule.closePayRun = value;
						setSchedule(schedule);
					}}
				/>
			);
		default:
			return;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AddPaySchedule = ({
	isOnboarding = false,
}): JSX.Element => {

	const { employer, isPending: isLoadingEmployer } = useGetEmployer({ relations: ['tax_year'] });
	const { profileData: user } = useUser();

	const { setLayoutProps } = useLoggedLayout();
	const push = useNavigate();

	const [step, setStep] = useState(0),
		[schedule, setSchedule] = useReducer(
		(state, updates) => ({
			...state,
			...updates,
		}),
		{
			scheduleName: "",
			payFrequency: null,
			payDays: null,
			dayRateMethod: "yearly_working_days",
			firstPayDate: null,
			submissionDateOffset: null,
			taxPeriod: "",
			automaticPaySchedule: false,
			clientAuthorisation: false,
			closePayRun: false,
			authoriserName: "",
			authoriserEmail: "",
		});
	
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	
	const onNext = useCallback(() => {
		if (user?.is_bureau_user) {
			if (step === 3) {
				setOpenConfirmDialog(true);
			} else if (step === 2 && !schedule?.clientAuthorisation) {
				setOpenConfirmDialog(true);
			} else if (step === 1 && schedule.automaticPaySchedule) {
				setOpenConfirmDialog(true);
			} else {
				setStep(step + 1);
			}
		} else {
			if (step === 1) {
				setOpenConfirmDialog(true);
			} else {
				setStep(step + 1);
			}
		}
	},[schedule.automaticPaySchedule, schedule?.clientAuthorisation, step, user?.is_bureau_user]);

	const onBack = useCallback(() => {
		if (step === 0) {
			if (isOnboarding) {
				push("/onboarding/dashboard");
			} else {
				push("/main/company/pay-schedules");
			}
		}
		setStep(step - 1);
	}, [isOnboarding, push, step]);
	
	function disable(schedule) {
		if (step === 1) {
			return schedule?.scheduleName?.length === 0 ||
				schedule?.payFrequency?.length === 0 ||
				schedule?.payDays === null ||
				schedule?.firstPayDate === null ||
				(schedule?.automaticPaySchedule && schedule?.submissionDateOffset === null)
		} else if (step === 2) {
			return (
				schedule?.clientAuthorisation &&
				(
					schedule.authoriserName.length === 0 ||
					schedule.authoriserEmail.length === 0
				)
			)
		}
	}

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Pay Schedules',
			showBackButton: true,
			custom: true,
			customOnClick: () => onBack(),
		});
	}, [setLayoutProps, onBack]);

	if (isLoadingEmployer) {
		return <Loading/>;
	}

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", gap: "2vw", width: "100%" }}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					Create a new pay schedule
				</Typography>
				<Divider color={"gray"}/>
				<StepsPaySchedule
					step={step}
					schedule={schedule}
					setSchedule={setSchedule}
					minDate={employer?.tax_year?.from}
					maxDate={employer?.tax_year?.to}
					isBureau={user?.is_bureau_user}
				/>
				<div style={{ width: '15vw'}}>
					<FreepayrollButton
						variant={"primary"}
						size={"medium"}
						onClick={onNext}
						disabled={disable(schedule)}
						className={"next-button-pay-schedule"}
					>
						Next
					</FreepayrollButton>
				</div>
			</div>
			
			<PayScheduleConfirmDialog
				open={openConfirmDialog}
				setOpen={setOpenConfirmDialog}
				schedule={schedule}
				isOnboarding={isOnboarding}
				isBureau={user?.is_bureau_user}
			/>
		</>
	);
};

export default AddPaySchedule;