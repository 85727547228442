import {useMutation, useQueryClient} from "@tanstack/react-query";
import Request from "../../../utils/Request";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

export function useCreatePayItem() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/pay-items/create', {'pay_item': data})
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['list-pay-items'] });
			navigate('/main/company/pay-items');
			toast.success('Pay item created successfully');
		},
	});
}