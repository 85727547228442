import {
	useMutation,
} from '@tanstack/react-query';
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useAddParentalLeave() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employees/add-parental-statutory`,data)
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success('Leave added successfully');
		},
	});
}