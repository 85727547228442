import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import { useState } from "react";
import ArrowDownIcon from "../../../images/svg/arrow-down-light-gray.svg";
import VideoWhiteFullIcon from "../../../images/svg/video-white-full.svg";
import { Divider } from "@mui/material";

const Tutorials = ({
	tutorials = [],
}) => {
	
	const tutorialsCategories = [
		{
			title: "Tutorials",
			tutorials
		},
	];

	return (
		<div className={"tutorials-container"}>
			{tutorialsCategories.map((category, index) => (
				<div key={index} className={"tutorials-category-items"}>
					<StyleTypography
						fontWeight="bold"
						fontSize={25}
						fontSizeMedium={20}
						color={"black"}
					>
						{category.title}
					</StyleTypography>

					{category.tutorials.map((tutorial, i) => (
						<Tutorial key={i} tutorial={tutorial} index={i}/>
					))}
				</div>
			))}
		</div>
	);
};

function Tutorial({tutorial, index}) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={"tutorials-item"}>
			<StyleTypography
				fontWeight="bold"
				fontSize={22}
				fontSizeMedium={18}
				color={"black"}
			>
				{index + 1}
			</StyleTypography>

			<div className={"tutorials-item-content"}>
				<div
					className={"tutorials-item-heading"}
					onClick={() => setIsOpen((prev) => !prev)}
				>
					<div className={"tutorials-item-heading-start"}>
						<StyleTypography
							fontWeight="bold"
							fontSize={22}
							fontSizeMedium={18}
							color={"black"}
						>
							{tutorial.title}
						</StyleTypography>
					</div>

					<img
						src={ArrowDownIcon}
						alt={"arrow-down"}
						className={"tutorials-item-heading-icon"}
					/>
				</div>

				{isOpen && (
					<>
						<Divider orientation={"horizontal"} flexItem />

						<div className={"tutorials-item-body"}>
							<button
								className={"tutorials-item-body-button"}
								onClick={() => window.open(tutorial.link, "_blank")}
							>
								<img
									src={VideoWhiteFullIcon}
									alt={"video-white-full"}
									className={"tutorials-item-body-button-icon"}
								/>
							</button>

							<StyleTypography
								fontWeight="400"
								fontSize={16}
								fontSizeMedium={14}
								color={"gray"}
							>
								{tutorial.description}
							</StyleTypography>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default Tutorials;
