import {useMutation, useQuery} from "@tanstack/react-query";
import Request from "../../utils/Request";

export function useGetEmployee({ id, relations = [] }) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['employee', id, ...relations],
		queryFn: async () => {
			if (!id) {
				return null;
			}

			let queryString = '';

			if (relations.length) {
				// Create a string like 'relations[]=relation1&relations[]=relation2'
				queryString = relations.map(relation => `relations[]=${relation}`).join('&');
			}

			return await Request.get(`/api/employees/${id}` + (queryString ? `?${queryString}` : ''))
				.then(response => response.data)
		},
		staleTime: 1000 * 60 * 5,
	});

	return {
		employee: data?.data,
		isPending,
		isError,
	};
}

export function useVerifyDuplicateEmployee() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employees/verify-duplicate-employee`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			return data;
		},
	});
}

