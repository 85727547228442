import addEmployer from "../../../images/svg/add-employer.svg"
import multiple from "../../../images/svg/multiple.svg"
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {Typography, Divider, FreepayrollButton} from "@collegia-partners/ui-kit";

function AddEmployeeMethodBox({
	key,
	img,
	text,
	subtext,
	buttonAction,
}) {
	return (
		<div key={key} style={{
			display: "flex",
			flexDirection: "column",
			gap: "2.3vw",
			border: "1px solid #d1d2d6",
			padding: "2.57vw",
		}}>
			<img src={img} alt={text} style={{ width: "1.1112vw" }} />
			<Typography variant={"subtitle"} color={"black"} weight={"bold"}>
				{text}
			</Typography>
			<div style={{ width: '60%' }} >
				<Typography variant={"label"}>
					{subtext}
				</Typography>
			</div>
			<div>
				<FreepayrollButton
					variant={"blue-button"}
					size={"small"}
					onClick={buttonAction}
					fullWidth={false}
					custom={true}
					height={0.6}
				>
					Get started
				</FreepayrollButton>
			</div>
		</div>
	);
}

const AddEmployeeMethod = ({
	isOnboarding,
}): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();

	const navigate = useNavigate();

	const AddEmployeeMethods = [
		{
			img: addEmployer,
			text: "Add one employee at a time",
			subtext: "Use our form to create each employee’s profile one at a time.",
			onClick: () => {
				if (isOnboarding) {
					navigate("/onboarding/add-employee");
				} else {
					navigate("/main/people/add-employee");
				}
			}
		},
		{
			img: multiple,
			text: "Add multiple employees at the same time",
			subtext: "Use our CSV template to add all your employees in one go.",
			onClick: () => {
				if (isOnboarding) {
					navigate("/onboarding/add-bulk-employees");
				} else {
					navigate("/main/people/add-bulk-employees");
				}
			}
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'People',
			showBackButton: true,
			backLink: '/main/people/team-members',
		});
	}, [setLayoutProps]);

	return (
		<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
			<div style={{display: "flex", flexDirection: "column"}}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					How would you like to add your employees?
				</Typography>
				<Typography variant={"label"}>
					Add one employee at a time use our form to create each employee’s
					profile one at a time. <br/> Add multiple employees at the
					same time use our CSV template to add all your employees in one go.
				</Typography>
			</div>
			<Divider color={"light-gray"}/>
			<div style={{width: "100%", display: "flex", gap: "2.57vw"}}>
				{
					AddEmployeeMethods.map((method, index) => (
						<AddEmployeeMethodBox
							key={index}
							img={method.img}
							text={method.text}
							subtext={method.subtext}
							buttonAction={method.onClick}
						/>
					))
				}
			</div>
		</div>
	);
}

export default AddEmployeeMethod;