import EditPersonalInfo from "./EditPersonalInfo";
import EditEmail from "./EditEmailForm";
import EditPassword from "./EditPasswordForm";
import EditTwoFactorAuthentication from "./EditTwoFactorAuthentication";
import ConfirmTwoFactorAuthentication from "./ConfirmTwoFactorAuthentication";

const SettingsPages = {
	EditPersonalInfo,
	EditEmail,
	EditPassword,
	EditTwoFactorAuthentication,
	ConfirmTwoFactorAuthentication,
};

export default SettingsPages;