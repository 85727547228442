import React, {useState} from "react";
import Warning from "../../../../images/svg/ReportsIcon/p60-warning.svg";
import DownloadIcon from "../../../../images/svg/ReportsIcon/download-icon.svg";
import {Typography, Divider} from "@collegia-partners/ui-kit";

function RenderGrids({
	index = 0,
	year = "",
}) {
	switch (index) {
		case 0:
			return (
				<div
					className={"reports-p60-map-row"}
				>
					<Typography
						variant={"body"}
						color={"black"}
						weight={index === 0 ? "bold" : ""}
					>
						{year}
					</Typography>
					<div className={"reports-p60-last-year"}>
						<Typography
							color={"white"}
							weight={"normal"}
							variant={"body"}
						>
							Available in April, 2024
						</Typography>
					</div>
				</div>
			);
		default:
			return (
				<div className={"reports-p60-map-row"}>
					<Typography
						variant={"body"}
						color={"black"}
						weight={index === 0 ? "bold" : ""}
					>
						{year}
					</Typography>
					<div className={"reports-p60-map-download-row"}>
						<Typography
							variant={"body"}
							color={"black"}
							weight={"bold"}
						>
							Download all
						</Typography>
						<img
							className={"img"}
							src={DownloadIcon}
							alt={"Download"}
						/>
					</div>
				</div>
			);
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsP60 = (): JSX.Element => {

	const [reportsDownloads] = useState([
		"2023/2024",
		"2022/2023",
		"2021/2022"
	]);

	return (
		<div
			id={"reports-p60"}
		>
			<div
				className={"reports-p60-item"}
			>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					P60s
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					All employees must be given a P60 at the end of each tax year. It details the tax that <br/>
					has been paid by an employee on their earnings.
				</Typography>
				<div className={"reports-title-margin"}>
					<Divider color={"light-gray"}/>
				</div>
				<div
					className={"reports-p60-description"}
				>
					<img
						className={"reports-p60-description-warning-image"}
						src={Warning}
						alt={"Warning"}
					/>
					<Typography
						color={"black"}
						variant={"subtitle2"}
						weight={"bold"}
					>
						Your P6Os will be automatically sent to your employees on the 20th April, 2023 for the
						2022/23 tax year.
					</Typography>
				</div>
				<div
					className={"reports-p60-download-title"}
				>
					<Typography
						color={"black"}
						weight={"bold"}
						variant={"subtitle"}
					>
						Download
					</Typography>
				</div>
				<div
					className={"reports-p60-download-sub-title"}
				>
					<Typography
						color={"gray"}
						weight={"normal"}
						variant={"body"}
					>
						You can download individual P6O documents by visiting individual employee profiles.
					</Typography>
				</div>
				{
					reportsDownloads.length > 0 &&
					<div
						className={"reports-p60-item-map"}
					>
						{
							reportsDownloads.map((download, index) => {
								return (
									<div
										key={index}
									>
										<RenderGrids
											year={download}
											index={index}
										/>
										{
											reportsDownloads.length-1 !== index
												? <div
													className={"reports-p60-item-map-divider"}
												>
													<Divider color={"light-gray"}/>
												</div>
												: ""
										}
									</div>
								)
							})
						}
					</div>
				}
			</div>
		</div>
	);
}

export default ReportsP60;