import React, {useCallback} from "react";
import {FreepayrollButton, Typography, Modal} from "@collegia-partners/ui-kit";
import styles from "./styles.module.scss";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {useAddPaySchedule, useUpdatePaySchedule} from "../../../hooks/pay-schedules";
import {resolveTaxPeriodAndPeriodDates} from "../../../utils/Helpers";

const ListItem = ({textInfo, schedulePropriety}) => {
	return (
		<div className={styles.ListItem}>
			<Typography variant={"body"} size={"medium"} color={"black"}>
				• <strong>{textInfo}</strong> {schedulePropriety}
			</Typography>
		</div>
	)
}

export const PayScheduleConfirmDialog = ({
	open,
	setOpen = () => {},
	schedule = {},
	isOnboarding,
	isBureau = false,
	isUpdate = false,
}): JSX.Element => {

	const { mutate: addPaySchedule, isPending: isAdding } = useAddPaySchedule(isOnboarding);
	const { mutate: updatePaySchedule, isPending: isUpdating } = useUpdatePaySchedule();

	const push = useNavigate();

	const onSave = useCallback(() => {
		if (isUpdate) {
			updatePaySchedule({
				id: schedule.id,
				data: {
					'pay_schedules': {
						name: schedule?.scheduleName,
						frequency_code: schedule?.payFrequency,
						week_payday: (schedule?.payFrequency === "W1" || schedule?.payFrequency === "W2" || schedule?.payFrequency === "W4") ? schedule?.payDays : null,
						month_payday: ((schedule?.payFrequency === "M1") && (schedule?.payDays !== 'Last Day of Month')) ? schedule?.payDays : null,
						custom_payday: (schedule?.payFrequency === "M1") && (schedule?.payDays === 'Last Day of Month') ? schedule?.payDays : null,
						starting_period_end_date: schedule?.firstPayDate,
						day_rate_method: schedule?.dayRateMethod,
						automatic_pay_schedule: schedule?.automaticPaySchedule,
						submission_date_offset: schedule?.submissionDateOffset,
						require_client_authorisation: schedule?.clientAuthorisation,
						close_upon_approval: schedule?.closePayRun,
						authoriser_name: schedule?.authoriserName,
						authoriser_email: schedule?.authoriserEmail,
					}
				}
			});
		} else {
			addPaySchedule({
				"pay_schedules": {
					name: schedule?.scheduleName,
					frequency_code: schedule?.payFrequency,
					week_payday: (schedule?.payFrequency === "W1" || schedule?.payFrequency === "W2" || schedule?.payFrequency === "W4") ? schedule?.payDays : null,
					month_payday: ((schedule?.payFrequency === "M1") && (schedule?.payDays !== 'Last Day of Month')) ? schedule?.payDays : null,
					custom_payday: (schedule?.payFrequency === "M1") && (schedule?.payDays === 'Last Day of Month') ? schedule?.payDays : null,
					starting_period_end_date: schedule?.firstPayDate,
					day_rate_method: schedule?.dayRateMethod,
					automatic_pay_schedule: schedule?.automaticPaySchedule,
					submission_date_offset: schedule?.submissionDateOffset,
					require_client_authorisation: schedule?.clientAuthorisation,
					close_upon_approval: schedule?.closePayRun,
					authoriser_name: schedule?.authoriserName,
					authoriser_email: schedule?.authoriserEmail,
				},
			}, isOnboarding, push);
		}
	},[addPaySchedule, isOnboarding, isUpdate, push, schedule?.authoriserEmail, schedule?.authoriserName, schedule?.automaticPaySchedule, schedule?.clientAuthorisation, schedule?.closePayRun, schedule?.dayRateMethod, schedule?.firstPayDate, schedule.id, schedule?.payDays, schedule?.payFrequency, schedule?.scheduleName, schedule?.submissionDateOffset, updatePaySchedule]);

	return (
		<Modal
			onClose={() => setOpen(false)}
			isOpen={open}
			size={"md"}
		>
			<div className={styles.PayScheduleConfirmDialog}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					Confirm Your Payment Schedule
				</Typography>
				<Typography variant={"body"} size={"xmedium"} color={"black"} weight={"bold"}>
					Your first pay run will be on:
				</Typography>
				<div className={styles.List}>
					<ListItem textInfo={"Pay frequency: "} schedulePropriety={schedule?.scheduleName}/>
					<ListItem textInfo={"First Payroll Date: "} schedulePropriety={moment(schedule?.firstPayDate || undefined).format("DD/MM/YYYY")}/>
					<ListItem
						textInfo={"Tax Period: "}
						schedulePropriety={resolveTaxPeriodAndPeriodDates(
							schedule?.payFrequency,
							schedule?.firstPayDate,
							schedule?.payDays === "Last Day of Month"
							)["tax_period"]
						}
					/>
					<ListItem textInfo={"Day rate method: "} schedulePropriety={schedule?.dayRateMethod === "yearly_working_days" ? "Yearly Working Days" : "Monthly Working Days"}/>
					{
						isBureau &&
						<>
							<ListItem textInfo={"Automatic pay schedule: "} schedulePropriety={schedule?.automaticPaySchedule ? "Yes" : "No"}/>
							{
								!schedule.automaticPaySchedule && (
									<ListItem textInfo={"Require client authorisation: "} schedulePropriety={schedule?.clientAuthorisation ? "Yes" : "No"}/>
								)
							}
							{
								(!schedule.automaticPaySchedule && schedule?.clientAuthorisation) && (
									<>
										<ListItem textInfo={"Authoriser name: "} schedulePropriety={schedule?.authoriserName}/>
										<ListItem textInfo={"Authoriser email: "} schedulePropriety={schedule?.authoriserEmail}/>
										<ListItem textInfo={"Close pay run upon client"} schedulePropriety={schedule?.closePayRun ? "Yes" : "No"}/>
									</>
								)
							}
						</>
					}
				</div>
				<Typography variant={"body"} size={"small"} color={"black"}>
					<strong>Note:</strong> If you want your first payroll to start earlier, please click on cancel and change your first payment date.
				</Typography>
				<div
					className={styles.ButtonsDiv}
				>
					<FreepayrollButton
						onClick={() => setOpen(false)}
						variant={"outline"}
						size={"medium"}
						isLoading={isAdding || isUpdating}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						size={"medium"}
						onClick={onSave}
						isLoading={isAdding || isUpdating}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	)
}