import {useMutation} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useEmailAllPayslips() {

	return useMutation({
		mutationFn: async (id) => {
			return await Request.get(`/api/employers/payrolls/${id}/email-payslips`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success("Payslips sent successfully");
			return data;
		},
	});
}