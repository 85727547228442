import {useMutation, useQueryClient} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useCreateSickLeave(safeId) {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employees/${safeId}/sick-leaves/create`,data)
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['sick-leaves'] });
			toast.success('Sick leave created successfully');
		},
	});
}