import React, {useCallback, useEffect, useState} from "react";
import {Typography, Modal, FreepayrollTextField, FreepayrollButton, Checkbox, FreepayrollSelect} from "@collegia-partners/ui-kit";
import {useUpdateDefaultPensionSettings} from "../../../hooks/employer";

export const EditModal = ({
	open,
	setOpen,
	employer,
}): JSX.Element => {

	const [applyToAll, setApplyToAll] = useState(false);

	const {
		mutateAsync: updateDefaultPensionSettings,
		isPending: isLoading
	} = useUpdateDefaultPensionSettings();
	
	const [pensionSettings, setPensionSettings] = useState({
		employee_rate: 0.00,
		employer_rate: 0.00,
		salary_sacrifice: '',
		pensionable_earnings: '',
		relief_at_source: '',
		qualifying_earnings: '',
	});

	const submitUpdateDefaultPensionSettings = useCallback(async () => {
		await updateDefaultPensionSettings({
			"pension_schemes": {
				"update_data": {
					employee_rate: pensionSettings?.employee_rate,
					employer_rate: pensionSettings?.employer_rate,
					salary_sacrifice: pensionSettings?.salary_sacrifice,
					pensionable_earnings: pensionSettings?.pensionable_earnings,
					relief_at_source: pensionSettings?.relief_at_source,
					qualifying_earnings: pensionSettings?.qualifying_earnings,
				},
				apply_to_all: applyToAll,
			}
		}).catch()
		setOpen(false);
	},[
		applyToAll,
		pensionSettings?.employee_rate,
		pensionSettings?.employer_rate,
		pensionSettings?.pensionable_earnings,
		pensionSettings?.qualifying_earnings,
		pensionSettings?.relief_at_source,
		pensionSettings?.salary_sacrifice,
		setOpen,
		updateDefaultPensionSettings
	]);

	useEffect(() => {
		setPensionSettings({
			employee_rate_percentage: employer?.pension_scheme?.employee_rate_percentage,
			employer_rate_percentage: employer?.pension_scheme?.employer_rate_percentage,
			salary_sacrifice: employer?.pension_scheme?.salary_sacrifice,
			pensionable_earnings: employer?.pension_scheme?.pensionable_earnings,
			relief_at_source: employer?.pension_scheme?.relief_at_source,
			qualifying_earnings: employer?.pension_scheme?.qualifying_earnings,
		})
	}, [employer, open]);
	
	return (
		<Modal
			onClose={() => setOpen(false)}
			isOpen={open}
			maxWidth={'sm'}
		>
			<div className={"auto-enrolment-settings-modal"}>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					Edit pension settings
				</Typography>
				<div className={"auto-enrolment-settings-modal-title"}>
					{/*Pension Type*/}
					<FreepayrollSelect
						label={"Pension type"}
						value={pensionSettings?.relief_at_source?.toString()}
						onSelect={(option) => {
							setPensionSettings({
								...pensionSettings,
								relief_at_source: option.value
							});
						}}
						options={[
							{
								label: "Relief at source*",
								value: "true",
							},
							{
								label: "Salary sacrifice*",
								value: "false",
							}
						]}
					/>

					{/*Pension Calculation*/}
					<FreepayrollSelect
						label={"Pension calculation"}
						onSelect={(option) => {
							setPensionSettings({
								...pensionSettings,
								pensionable_earnings: option.value
							});
						}}
						value={pensionSettings?.pensionable_earnings?.toString()}
						options={
							[
								{
									value: "true",
									label: "Pensionable Earnings*",
								},
								{
									value: "false",
									label: "Qualifying Earnings*"
								}
							]
						}
					/>

					<div className={"auto-enrolment-settings-modal-contributions"}>
						{/*Employee contribution*/}
						<div>
							<FreepayrollTextField
								name={"employee_rate_percentage"}
								label={"Employee contribution*"}
								value={pensionSettings?.employee_rate_percentage ?? "N/A"}
								onChange={(e) => {
									setPensionSettings({
										...pensionSettings,
										employee_rate_percentage: e.target.value,
										employee_rate: Number(e.target.value) / 100
									});
								}}
								noGhostHelperText={true}
								fieldType={"number"}
								maxDecimalPlaces={2}
								customEndAdornment={
									<Typography
										variant={"body"}
										weight={"bold"}
										color={"black"}
									>
										%
									</Typography>
								}
							/>
						</div>

						{/*Employer contribution*/}
						<div>
							<FreepayrollTextField
								name={"employer_rate_percentage"}
								label={"Employer contribution*"}
								value={pensionSettings?.employer_rate_percentage}
								onChange={(e) => {
									setPensionSettings({
										...pensionSettings,
										employer_rate_percentage: e.target.value,
										employer_rate: Number(e.target.value) / 100
									});
								}}
								noGhostHelperText={true}
								fieldType={"number"}
								maxDecimalPlaces={2}
								customEndAdornment={
									<Typography
										variant={"body"}
										weight={"bold"}
										color={"black"}
									>
										%
									</Typography>
								}
							/>
						</div>
					</div>

					{
						Number(pensionSettings?.employer_rate_percentage) + Number(pensionSettings?.employee_rate_percentage) < 8 && (
							<span className={"auto-enrolment-settings-modal-error"}>
								*Minimum combined contribution amount is 8% (employee + employer).
							</span>
						)
					}

					{
						Number(pensionSettings?.employer_rate_percentage) + Number(pensionSettings?.employee_rate_percentage) > 100 && (
							<span className={"auto-enrolment-settings-modal-error"}>
								*Maximum combined contribution amount is 100% (employee + employer).
							</span>
						)
					}

					{/*Apply to all employees*/}
					<div className={"auto-enrolment-settings-modal-checkbox"}>
						<Checkbox
							defaultValue={false}
							onChange={(e) => {
								setApplyToAll(e)
							}}
							label={
								<Typography
									color={"black"}
									weight={"normal"}
									variant={"subtitle2"}
								>
									Apply this changes to all existing employees
								</Typography>
							}
						/>
					</div>

				</div>
				<div className={"auto-enrolment-settings-modal-buttons"}>
					<div>
						<FreepayrollButton
							fullWidth
							onClick={submitUpdateDefaultPensionSettings}
							disabled={
								(pensionSettings?.employee_rate_percentage === employer?.pension_scheme?.employee_rate_percentage &&
									pensionSettings?.employer_rate_percentage === employer?.pension_scheme?.employer_rate_percentage &&
									pensionSettings?.pensionable_earnings === employer?.pension_scheme?.pensionable_earnings &&
									pensionSettings?.relief_at_source === employer?.pension_scheme?.relief_at_source) ||
								Number(pensionSettings?.employer_rate_percentage) < 3 ||
								(
									Number(pensionSettings?.employer_rate_percentage) + Number(pensionSettings?.employee_rate_percentage) > 100 ||
									Number(pensionSettings?.employer_rate_percentage) + Number(pensionSettings?.employee_rate_percentage) < 8
								)
							}
							isLoading={isLoading}
							variant={"primary"}
							size={"medium"}
							custom
							height={0.85}
							width={8}
						>
							Save
						</FreepayrollButton>
					</div>

					<div>
						<FreepayrollButton
							onClick={() => {
								setOpen(false);
							}}
							isLoading={isLoading}
							variant={"white-button"}
							size={"medium"}
							custom
							height={0.85}
							width={7.5}
						>
							Cancel
						</FreepayrollButton>
					</div>
				</div>
			</div>
		</Modal>
	)
}