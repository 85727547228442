import React, {useCallback, useEffect, useState} from "react";
import {Typography, Checkbox, Divider, FreepayrollButton, FreepayrollTextField} from "@collegia-partners/ui-kit";
import {useCreatePayrollTemplate, useGetPayrollTemplate} from "../../../hooks/reports/payroll-journal";
import {toast} from "sonner";

function Fields({item}) {
	return (
		<FreepayrollTextField
			name={item?.title}
			noGhostHelperText={true}
			value={item?.accountCode}
			label={item?.title}
			onChange={item?.onChange}
			fieldType={"number"}
			maxDecimalPlaces={0}
		/>
	)
}

function getMap(e, setEmployerJournal, regularTemplate, aggregateItem, item) {
	const newValue = Boolean(e) || false;
	switch (item?.id) {
		case "Total Income Tax Payable":
		case "Total Pension Contributions Payable":
		case "Total NIC Payable":
			setEmployerJournal((prev) =>
				prev.map((entry) =>
					entry.id === regularTemplate.id && entry.id === item.id
						? {
							...entry,
							show: !e,
							aggregate: entry.aggregate.map((agg) => {
								return { ...agg, show: e };
							}),
						}
						: entry
				)
			);
			break;
		default:
			setEmployerJournal((prev) =>
				prev.map((entry) =>
					entry.id === regularTemplate.id && entry.id === item.id
						? {
							...entry,
							aggregate: entry.aggregate.map((agg) =>
								agg.id === aggregateItem.id
									? {...agg, show: newValue}
									: agg
							),
						}
						: entry
				)
			);
			break;
	}

}

function RenderSteps({
	step = 0,
	setStep = () => {},
	submitCreatePayrollTemplate = () => {},
	employerJournal = [],
	exist_template = false,
 }) {
	switch (step) {
		case 0:
			return (
				<div>
					<Typography
						variant={"title"}
						weight={"bold"}
						color={"#0160FD"}
					>
						Payroll journals
					</Typography>
					<Typography
						variant={"subtitle2"}
						weight={"normal"}
						color={"gray"}
					>
						Synchronise FreePayroll with your accounting software using payroll journal reports.
					</Typography>
					<div className={"reports-payroll-journal-divider"}>
						<Divider color={"light-gray"} />
					</div>
					<div
						className={"edit-journal-box"}
					>
						<div className={"reports-payroll-button-margin"}>
							<Typography
								variant={"subtitle"}
								color={"black"}
								weight={"bold"}
							>
								Want to customise your journal?
							</Typography>
							<Typography
								weight={"normal"}
								color={"gray"}
								variant={"body"}
							>
								Customise your journal by selecting the payroll items you want to include,
								how you want to split across your organisation, and by defining your account codes.
							</Typography>
						</div>

						<FreepayrollButton
							onClick={() => setStep(1)}
							variant={"primary"}
							size={'small'}
							custom={true}
							fullWidth={false}
							height={0.7}
							width={1}
						>
							{exist_template ? "Edit template" : "Create template"}
						</FreepayrollButton>

					</div>
				</div>
			);
		case 1:
			return (
				<div>
					<Typography
						variant={"title"}
						weight={"bold"}
						color={"#0160FD"}
					>
						Select any items you want to <br/>
						show separately in your journal
					</Typography>
					<div className={"reports-payroll-journal-divider"}>
						<Divider color={"light-gray"}/>
					</div>
					<div className={"reports-payroll-journal-check-box-div"}>
						{
							employerJournal?.map((item) =>
								item?.aggregate
									.map((aggItem) => (
										aggItem?.extra && (
										<Checkbox
											key={aggItem.id}
											defaultValue={false}
											onChange={(e) => aggItem.onChangeCheckBox(e, item)}
											isChecked={aggItem.show}
											label={
												<Typography
													variant="subtitle2"
													color="black"
													weight="bold"
												>
													{aggItem.title}
												</Typography>
											}
										/>
									)
								))
							)
						}
						<div className={"reports-payroll-journal-button"}>
							<FreepayrollButton
								onClick={() => setStep(2)}
								variant={"primary"}
								custom={true}
								fullWidth={false}
								height={0.7}
								width={2.2}
							>
								Next
							</FreepayrollButton>
							<FreepayrollButton
								onClick={() => setStep(0)}
								variant={"white-button"}
								custom={true}
								fullWidth={false}
								height={0.7}
								width={1.5}
							>
								Cancel
							</FreepayrollButton>
						</div>
					</div>
				</div>
			);
		default:
			return (
				<div>
					<Typography
						variant={"title"}
						weight={"bold"}
						color={"#0160FD"}
					>
						Account codes
					</Typography>
					<div className={"reports-payroll-journal-divider"}>
						<Divider color={"light-gray"}/>
					</div>
					<div
						className={"reports-payroll-journal-items-per-row"}
					>
						{
							employerJournal?.map(item => {
								return (
									item?.show && <Fields item={item}/>
								)
							})
						}
						{
							employerJournal?.map((item) =>
								item?.aggregate
									.map((aggItem) => (
										aggItem?.show && (
											<Fields
												key={aggItem.id}
												item={aggItem}
											/>
										)
									))
							)
						}
					</div>
					<div className={"reports-payroll-journal-button"}>
						<FreepayrollButton
							onClick={() => submitCreatePayrollTemplate()}
							variant={"primary"}
							custom={true}
							fullWidth={false}
							height={0.7}
							width={2.2}
						>
							Save template
						</FreepayrollButton>
						<FreepayrollButton
							onClick={() => setStep(1)}
							variant={"white-button"}
							custom={true}
							fullWidth={false}
							height={0.7}
							width={4}
						>
							Cancel
						</FreepayrollButton>
					</div>
				</div>
			);
	}
}



/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollJournal = (): JSX.Element => {
	const { regular_template, exist_template } = useGetPayrollTemplate();
	const { mutate: addCreatePayrollTemplate } = useCreatePayrollTemplate();
	const [step, setStep] = useState(0);

	const [employerJournal, setEmployerJournal] = useState([]);

	const submitCreatePayrollTemplate = useCallback(async () => {
		try {
			await addCreatePayrollTemplate({
				journal_template: {
					template: employerJournal,
				},
			});
			setStep(0);
			toast.success(exist_template ? "Updated Payroll Template" : "Created Payroll Template");
		} catch (error) {
			toast.error("Something went wrong");
		}
	},[addCreatePayrollTemplate, employerJournal, exist_template]);

	useEffect(() => {
		if (regular_template?.length > 0) {
			regular_template?.forEach((regularTemplate) => {
				setEmployerJournal((prevJournal) => [
					...prevJournal,
					{
						id: regularTemplate.id,
						title: regularTemplate.title,
						accountCode: regularTemplate.accountCode ? regularTemplate.accountCode : 0.00,
						show: regularTemplate.show,
						extra: regularTemplate.extra,
						onChange: (e) => {
							const newValue = e.target.value || 0;
							setEmployerJournal((prev) =>
								prev.map((entry) =>
									entry.id === regularTemplate.id
										? { ...entry, accountCode: newValue }
										: entry
								)
							);
						},
						aggregate: regularTemplate?.aggregate?.map((aggregateItem) => (
							{
								id: aggregateItem.id,
								title: aggregateItem.title,
								accountCode: aggregateItem?.accountCode ? parseFloat(aggregateItem.accountCode) : 0.00,
								show: aggregateItem.show,
								extra: aggregateItem.extra,
								parentId: aggregateItem.parentId,
								onChange: (e) => {
									const newValue = e.target.value || 0;
									setEmployerJournal((prev) =>
										prev.map((entry) =>
											entry.id === regularTemplate.id
												? {
													...entry,
													aggregate: entry.aggregate.map((agg) =>
														agg.id === aggregateItem.id
															? { ...agg, accountCode: newValue }
															: agg
													),
												}
												: entry
										)
									);
								},
								onChangeCheckBox: (e, item) => {
									getMap(e, setEmployerJournal, regularTemplate, aggregateItem, item);
								},
							}
						)),
					},
				]);
			});
		}
	}, [regular_template]);

	return (
		<div
			id={"reports-payroll-journal"}
		>
			{
				<RenderSteps
					step={step}
					setStep={setStep}
					submitCreatePayrollTemplate={submitCreatePayrollTemplate}
					employerJournal={employerJournal}
					exist_template={exist_template}
				/>
			}
		</div>
	);
}

export default PayrollJournal;