import Dialog from "@mui/material/Dialog";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {AgGridReact} from "ag-grid-react";
import React, {useCallback, useEffect, useState} from "react";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {Tab, Tabs, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import StyleButton from "../../StyledComponents/StyleButton";
import ErrorIcon from '@mui/icons-material/Error';

export const ReviewDataModal = ({
    open = false,
    setOpen = () => {},
	uploadedData = [],
	onGridRowsUpdated = () => {},
	bulkEmployeeSave = () => {},
	submissionErrors = [],
	uploadMethod,
	setUploadedMethod,
	setUploadedData,
	setOpenFps = () => {}
}) => {

	const allowedTitles = ['Mr', 'Ms', 'Mrs', 'Miss', 'Lord', 'Sir', 'Dr', 'Lady'];

	const CustomHeaderComponent = (props) => {

		const hasError = submissionErrors.some(error => error.field === props.column.colId);

		return (
			<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
				{props.displayName}
				{hasError && (
					<Tooltip title="Error in this column. Must be unique per employee.">
						<ErrorIcon style={{ color: 'red', marginLeft: '5px' }} fontSize={'small'} />
					</Tooltip>
				)}
			</div>
		);
	};

	const columnsPersonalDetails = [
		{
			field: 'payrollId',
			editable: true,
			pinned: 'left',
			cellStyle: params => {

				const hasError = submissionErrors.find(error => error.rowIndex === params.node.id && error.field === params.colDef.field);
				if (hasError || (params.value === '' || params.value === undefined || params.value === null)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
			headerComponent: 'customHeaderComponent',
		},
		{
			field: 'firstName',
			headerName: 'Forename',
			editable: true,
			pinned: 'left',
			cellStyle: params => {
				if ((params.value === '' || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'lastName',
			headerName: 'Surname',
			editable: true,
			pinned: 'left',
			cellStyle: params => {
				if ((params.value === '' || params.value === undefined || params.value === null)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'email',
			cellStyle: params => {
				const hasError = submissionErrors.find(error => error.rowIndex === params.node.id && error.field === params.colDef.field);
				if (hasError || (params.value === '' || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
			editable: true,
			headerComponent: 'customHeaderComponent',
		},
		{
			field: 'nino',
			editable: true,
		},
		{
			field: 'title',
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: {
				values: allowedTitles,
			},
			cellStyle: params => {
				const hasError = submissionErrors.find(error => error.rowIndex === params.node.id && error.field === params.colDef.field);
				if (hasError || (params.value === '' || params.value === null || params.value === undefined || !allowedTitles.includes(params.value))) {
					return {
						backgroundColor: 'red',
					};
				}
			},
			editable: true
		},
		{
			field: 'gender',
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: {
				values: ['M', 'F'],
			},
			editable: true,
			cellStyle: params => {
				if ((params.value === '' || params.value === null || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'birthDate',
			editable: true,
			cellEditor: 'agDateCellEditor',
			cellEditorParams: {
				min: '1930-01-01',
			},
			cellStyle: params => {
				if ((params.value === '' || params.value === null || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'niCategory',
			editable: true,
			cellStyle: params => {
				if ((params.value === '' || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'taxCode',
			editable: true,
		},
		{
			field: 'isCumulative',
			headerName: 'Tax Code Cumulative?',
			editable: true,
			cellEditor: 'agSelectCellEditor',
			cellEditorParams: {
				values: ['Yes', 'No'],
			},
		},
		{
			field: 'startDate',
			headerName: 'Employment Start Date',
			editable: true,
			cellEditor: 'agDateCellEditor',
			cellEditorParams: {
				min: '1930-01-01',
				max: '2020-01-01',
			}
		},
		{
			field: 'line1',
			headerName: 'Address Line 1',
			editable: true,
			cellStyle: params => {
				if ((params.value === '' || params.value === null || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'line2',
			headerName: 'Address Line 2',
			editable: true,
		},
		{
			field: 'line3',
			headerName: 'Address Line 3',
			editable: true,
		},
		{
			field: 'line4',
			headerName: 'City',
			editable: true,
			cellStyle: params => {
				if ((params.value === '' || params.value === null || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'postcode',
			headerName: 'Postcode',
			editable: true,
			cellStyle: params => {
				if ((params.value === '' || params.value === null || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
		{
			field: 'country',
			headerName: 'Country',
			editable: true,
			cellStyle: params => {
				if ((params.value === '' || params.value === null || params.value === undefined)) {
					return {
						backgroundColor: 'red',
					};
				}
			},
		},
	];

	const columnsYtdFigures = [
		{
			field: 'payrollId',
			pinned: 'left',
		},
		{
			field: 'name',
			pinned: 'left',
			valueGetter: params => `${params.data.firstName} ${params.data.lastName}`,
		},
		{
			field: 'totalTaxablePayYtd',
			headerName: 'Gross for Tax YTD',
		},
		{
			field: 'totalTaxYtd',
			headerName: 'Total Tax YTD',
		},
		{
			field: 'studentLoanYtd',
			headerName: 'Student Loan YTD',
		},
		{
			field: 'pgLoanYtd',
			headerName: 'PG Loan YTD',
		},
		{
			field: 'eePensionYtd',
			headerName: 'Employee Pension YTD',
		},
		{
			field: 'smpYtd',
			headerName: 'SMP YTD',
		},
		{
			field: 'sppYtd',
			headerName: 'SPP YTD',
		},
		{
			field: 'sapYtd',
			headerName: 'SAP YTD',
		},
		{
			field: 'shppYtd',
			headerName: 'ShPP YTD',
		},
		{
			field: 'spbpYtd',
			headerName: 'SPBP YTD',
		},
		{
			field: 'benefitsInKindYtd',
			headerName: 'BiK YTD',
		},
	];

	const niYtdFigures = [
		{
			field: 'payrollId',
			pinned: 'left',
		},
		{
			field: 'name',
			pinned: 'left',
			valueGetter: params => `${params.data.firstName} ${params.data.lastName}`,
		},
		{
			field: 'niLetter',
			headerName: 'NI Category',
		},
		{
			field: 'niEarningsYtd',
			headerName: 'Gross for NI YTD',
		},
		{
			field: 'employeeNic',
			headerName: 'Employee NIC YTD',
		},
		{
			field: 'employerNic',
			headerName: 'Employer NIC YTD',
		},
		{
			field: 'niLel',
			headerName: 'Earnings at LEL YTD',
		},
		{
			field: 'niPt',
			headerName: 'Earnings to PT YTD',
		},
		{
			field: 'niUel',
			headerName: 'Earnings to UEL YTD',
		},
	];

	const [error, setError] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [activeTab, setActiveTab] = useState(0);

	//Row validation
	const validateRowData = useCallback((data) => {
		for (const row of data) {
			if (!row.firstName || !row.lastName || !row.email || !row.gender || !row.birthDate || !row.title || !row.line1 || !row.line4 || !row.postcode || !row.country || !row.niCategory) {
				return true;
			}
		}
		return false;
	}, []);

	useEffect(() => {
		setError(validateRowData(uploadedData));
	}, [uploadedData, validateRowData]);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	// Add a unique key for the grid to force re-render
	const gridKey = JSON.stringify(submissionErrors);

	// Flatten niYtdData for the grid
	const flattenedNiYtdData = uploadedData?.flatMap(row => row?.niYtdData?.map(niRow => ({
		...niRow,
		payrollId: row.payrollId,
		firstName: row.firstName,
		lastName: row.lastName,
		// Add other row-level data if needed
	})));

	function renderColumnDefs() {
		return {
			0: columnsPersonalDetails,
			1: columnsYtdFigures,
			2: niYtdFigures,
		}[activeTab];
	}

	function renderRowData() {
		return {
			0: uploadedData,
			1: uploadedData,
			2: flattenedNiYtdData,
		}[activeTab];
	}

	return (
		<Dialog
			open={open}
			maxWidth={'lg'}
			fullWidth={true}
			onClose={() => {
				setUploadedData([]);
				setUploadedMethod(" ");
				setOpen(false);
				setOpenFps(false);
			}}
			sx={{
				'.MuiDialog-paper': {
					padding: '20px',
				},
			}}
		>
			<StyleTypography
			>
				Review Data
			</StyleTypography>
			<Grid marginTop={3}>
				{
					(uploadMethod !== 'fps') && (
						<Tabs value={activeTab} onChange={handleTabChange} sx={{
							'.Mui-selected': {
								zIndex: '999 !important',
								backgroundColor: '#fff !important',
							},
							'.MuiTab-root': {
								zIndex: 1,
								borderTopLeftRadius: '20px',
								borderTopRightRadius: '20px',
								boxShadow: '0px 0px 2px rgba(0,0,0,0.2), 0px 0px 4px rgba(0,0,0,0.14), 0px 0px 6px rgba(0,0,0,0.12)',
								backgroundColor: '#dcdcdc',

							},
						}}>
							<Tab label="Personal Details" value={0} />
							<Tab label="Taxable YTD Figures" value={1} />
							<Tab label="NIC YTD Figures" value={2} />
						</Tabs>
					)
				}
				<div
					className={'ag-theme-alpine'}
					style={{
						width: '100%',
						height: '60vh',
					}}
					key={gridKey}
				>
					<AgGridReact
						columnDefs={renderColumnDefs()}
						rowData={renderRowData()}
						singleClickEdit={true}
						onCellValueChanged={onGridRowsUpdated}
						onCellEditingStarted={() => {
							setIsEditing(true);
						}}
						onCellEditingStopped={() => {
							setIsEditing(false);
						}}
						stopEditingWhenCellsLoseFocus={true}
						components={{
							customHeaderComponent: CustomHeaderComponent,
						}}
					/>
				</div>
				<StyleButton
					disabled={error || isEditing}
					onClick={() => {
						bulkEmployeeSave({
							employees: {
								uploadedData,
								upload_method: uploadMethod
							},
						});
					}}
				>
					Submit
				</StyleButton>
			</Grid>
		
		</Dialog>
	);
}