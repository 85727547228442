import {useMutation} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useDownloadP45(safeId) {

	return useMutation({
		mutationFn: async (id) => {
			return await Request.get(`/api/employees/${safeId}/p45/download`,{ responseType: 'blob' })
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'p45_file');
			document.body.appendChild(link);
			link.click();
			toast.success("Downloaded P45 successfully.");
		},
	});
}