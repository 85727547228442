import {FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import {useToggle2FA} from "../../../hooks/user";
import {useUser} from "../../../context/UserContext";
import {StickerAlert} from "../../../components/StickerAlert";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useEffect} from "react";


const EditTwoFactorAuthentication = () => {
	const { profileData: user } = useUser();

	const { setLayoutProps } = useLoggedLayout();

	const {
		mutate: toggle2FA,
		isPending: twoFALoading,
	} = useToggle2FA();

	useEffect(() => {
		setLayoutProps({
			backLink: '/main/settings/edit-info',
			showBackButton: true,
		});
	}, [setLayoutProps]);

	return (
		<div
			style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw' }}
		>
			<div>
				<Typography
					variant={"title"}
					weight={"bold"}
				>
					Do you want to {user?.two_fa_enabled ? 'disable' : 'enable'} <br/>
					two-step authentication?
				</Typography>
				<Typography
					color={"gray"}
					weight={'normal'}
					variant={'subtitle'}
				>
					Two-step authentication helps keep your data safer by requiring a second<br/>
					identity verification in addition to your password.
				</Typography>
			</div>
			{
				user?.two_fa_enabled && (
					<div style={{maxWidth: '50%'}}>
						<StickerAlert
							stickerColor={"#000000"}
							iconColor={'white'}
							text={'Risks of Disabling Two-Step Verification'}
							mode={'info'}
							subtitle={"Disabling two-step verification reduces your account’s security, " +
								"leaving it reliant only on your password and more vulnerable to unauthorised access " +
								"if your password is compromised."
							}
						/>
					</div>
				)
			}
			<div>
				<FreepayrollButton
					variant="primary"
					type="button"
					custom={true}
					width={3.5}
					height={1}
					isLoading={twoFALoading}
					onClick={() =>
						toggle2FA()
					}
					size="xsmall"
				>
					Continue
				</FreepayrollButton>
			</div>
		</div>
	);
};

export default EditTwoFactorAuthentication;