import {useQuery} from "@tanstack/react-query";
import Request from "../../utils/Request";

export function useRetrieveMandateToken(enabled) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['retrieve-mandate-token'],
		queryFn: async () => {
			return await Request.get(`/api/employers/retrieve-mandate-info`)
				.then(response => response?.data?.data ?? {});
		},
		keepPreviousData: true,
		enabled: enabled,
		refetchInterval: 2000,
	});

	return {
		mandate: data,
		isPending,
		isError
	};
}