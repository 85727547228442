import Grid from "@mui/material/Grid";
import { InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {parseISO} from "date-fns";
import FormControl from "@mui/material/FormControl";
import { CurrencyInputCustom } from "../../../StyledComponents/CurrencyInputCustom";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {parseDate} from "../../../../utils/Helpers";
import {useCallback, useEffect, useRef} from "react";
import {useVerifyDuplicateEmployee} from "../../../../hooks/employee/useGetEmployee";
import {FreepayrollTextField} from "@collegia-partners/ui-kit";

function returnErrorMessage(payroll_id = "", duplicateEmployee = false) {
	if (payroll_id !== '' && duplicateEmployee) {
		return "Already exist an employee with this payroll id";
	} else {
		return "";
	}
}

export const EmploymentDetails = ({
	employeeInformation,
	setEmployeeInformation,
	employer,
}) => {

	let timeout = useRef(null);
	const {
		mutateAsync: verifyDuplicateEmployeeMutate,
		isPending: isLoading
	} = useVerifyDuplicateEmployee();

	const verifyDuplicateEmployee = useCallback(async (payroll_id) => {
		try {
			const { exist_employee_payroll_id } = await verifyDuplicateEmployeeMutate({
				employees: {
					payroll_id: payroll_id,
					email: null,
				},
			});
			setEmployeeInformation((prevInfo) => ({
				...prevInfo,
				duplicatedEmployee: exist_employee_payroll_id,
			}));
		} catch (e) {
			console.error(e);
		}
	}, [setEmployeeInformation, verifyDuplicateEmployeeMutate]);

	useEffect(() => {
		if (employeeInformation.payroll_id !== '') {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				verifyDuplicateEmployee(employeeInformation.payroll_id);
			}, 1000);
		}

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [employeeInformation.payroll_id, verifyDuplicateEmployee]);

	return (
		<Grid
			item
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			spacing={4}
			paddingTop={2}
		>

			{/*Payroll ID and Employment Start Date*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				spacing={2}
				container
			>
				{/*Payroll ID*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Payroll ID*
					</InputLabel>
					<FreepayrollTextField
						fullWidth
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								payroll_id: event.target.value,
								duplicatedEmployee: false,
							});
						}}
						error={employeeInformation?.payroll_id !== '' && employeeInformation?.duplicatedEmployee}
						helperText={returnErrorMessage(employeeInformation?.payroll_id, employeeInformation?.duplicatedEmployee)}
						isLoading={isLoading}
						value={employeeInformation.payroll_id}
						noGhostLabel={true}
						noGhostHelperText={!employeeInformation?.duplicatedEmployee}
					/>
					<small className={"info-text"}>
						This will be the unique identifier sent to HMRC.
					</small>
				</Grid>

				{/*Employment Start Date*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Employment Start Date*
					</InputLabel>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={enGb}
					>
						<DesktopDatePicker
							className={"date-picker"}
							onChange={(newValue) => {
								setEmployeeInformation({
									...employeeInformation,
									join_date: newValue, // Ensuring the date is stored in ISO format
								});
							}}
							slotProps={{ field: { readOnly: true } }}
							enableAccessibleFieldDOMStructure
							value={parseDate(employeeInformation.join_date) ?? null}
						/>
					</LocalizationProvider>
				</Grid>
			</Grid>


			{/*Payment schedule*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={2}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Payment Schedule*
					</InputLabel>
					<FormControl
						fullWidth
						sx={{
							marginTop: '10px',
						}}
					>
						<Select
							className={"default-select"}
							id={"select-gender"}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
									},
								},
							}}
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									pay_schedule: {
										...employeeInformation.pay_schedule,
										id: event.target.value,
									}
								});
							}}
							value={employeeInformation.pay_schedule?.id ?? " "}
						>
							<MenuItem
								key={'select-title'}
								value={" "}
								disabled
							>
								Select
							</MenuItem>
							{
								employer?.pay_schedules.map((paySchedule) => (
									<MenuItem
										key={paySchedule.id}
										value={paySchedule.id}
									>
										{paySchedule.name}
									</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>

			</Grid>

			{/*Salary Type*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={2}
				marginBottom={1}
			>
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Salary Type*:
					</InputLabel>
					<FormControl
						fullWidth
						sx={{
							marginTop: '10px',
						}}
					>
						<Select
							className={"default-select"}
							id={"select-gender"}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200, // Adjust the maximum height of the list
									},
								},
							}}
							onChange={(event) => {
								setEmployeeInformation({
									...employeeInformation,
									salary: {
										employment_type: event.target.value,
										annual_salary: '',
										monthly_salary: '',
										weekly_salary: '',
										hourly_salary: '',
										expected_work_hours_per_week: '',
									}
								});
							}}
							value={employeeInformation.salary?.employment_type ?? " "}
						>
							<MenuItem
								key={'select-title'}
								value={" "}
								disabled
							>
								Select
							</MenuItem>
							<MenuItem
								key={'salaried'}
								value={"salaried"}
							>
								Salaried
							</MenuItem>
							<MenuItem
								key={'hourly'}
								value={"hourly"}
							>
								Hourly
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>

			{/*Salary Details*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={2}
			>

				{/*Annual Salary*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
					display={employeeInformation.salary?.employment_type === "salaried" ? "block" : "none"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Annual Salary*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(e) => {
							setEmployeeInformation({
								...employeeInformation,
								salary: {
									...employeeInformation.salary,
									annual_salary: e.target.value,
									monthly_salary: Math.ceil(e.target.value / 12 * 100) / 100,
									weekly_salary: Math.ceil(e.target.value / 52 * 100) / 100,
									hourly_salary: Math.floor((Math.ceil(e.target.value / 52 * 100) / 100) / (employeeInformation.salary?.expected_work_hours_per_week ?? 0) * 100) / 100,
								}
							});
						}}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						value={employeeInformation.salary?.annual_salary}
					/>
				</Grid>

				{/*Monthly Salary*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
					display={employeeInformation.salary?.employment_type === "salaried" ? "block" : "none"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Monthly Salary*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(e) => {
							setEmployeeInformation({
								...employeeInformation,
								salary: {
									...employeeInformation.salary,
									annual_salary: Math.floor(e.target.value * 12 * 10) / 10,
									monthly_salary: e.target.value,
									weekly_salary: Math.ceil((Math.floor(e.target.value * 12 * 10) / 10 / 52) * 100) / 100,
									hourly_salary: Math.floor((Math.ceil((Math.floor(e.target.value * 12 * 10) / 10 / 52) * 100) / 100) / (employeeInformation.salary?.expected_work_hours_per_week ?? 0) * 100) / 100,
								}
							});
						}}
						InputProps={{
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
							endAdornment: "£"
						}}
						value={employeeInformation.salary?.monthly_salary}
					/>
				</Grid>

				{/*Weekly Salary*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
					display={employeeInformation.salary?.employment_type === "salaried" ? "block" : "none"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Weekly Salary*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(e) => {
							setEmployeeInformation({
								...employeeInformation,
								salary: {
									...employeeInformation.salary,
									annual_salary: Math.floor(e.target.value * 52),
									monthly_salary: Math.ceil((Math.floor(e.target.value * 52 * 10) / 10) / 12 * 100) / 100,
									weekly_salary: e.target.value,
									hourly_salary: Math.floor((e.target.value / (employeeInformation.salary?.expected_work_hours_per_week ?? 0)) * 100) / 100,
								}
							});
						}}
						InputProps={{
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
							endAdornment: "£"
						}}
						value={employeeInformation.salary?.weekly_salary}
					/>
				</Grid>

				{/*Hours per week*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
					display={employeeInformation.salary?.employment_type !== undefined ? "block" : "none"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Expected work hours per week*
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						onChange={(e) => {
							if (employeeInformation.salary?.employment_type === "salaried") {
								setEmployeeInformation({
									...employeeInformation,
									salary: {
										...employeeInformation.salary,
										expected_work_hours_per_week: e.target.value,
										hourly_salary: employeeInformation.salary?.annual_salary / (52 * e.target.value),
									}
								});
							} else {
								setEmployeeInformation({
									...employeeInformation,
									salary: {
										...employeeInformation.salary,
										expected_work_hours_per_week: e.target.value,
									}
								});
							}
						}}
						value={employeeInformation.salary?.expected_work_hours_per_week}
						InputProps={{
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
							endAdornment: "Hours"
						}}
					/>
				</Grid>

				{/*Hourly Rate*/}
				<Grid
					xl={4}
					lg={4}
					md={4}
					sm={12}
					xs={12}
					item
					display={employeeInformation.salary?.employment_type !== undefined ? "block" : "none"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						{employeeInformation.salary?.employment_type === "salaried" ? "Hourly Equivalent" : "Hourly Salary"}
					</InputLabel>
					<TextField
						fullWidth
						className={"default-text-field"}
						InputProps={{
							endAdornment: "£",
							inputComponent: CurrencyInputCustom,
							inputProps: { prefix: "" },
						}}
						disabled={employeeInformation.salary?.employment_type === "salaried"}
						value={employeeInformation.salary?.hourly_salary}
						onChange={(e) => {
							setEmployeeInformation({
								...employeeInformation,
								salary: {
									...employeeInformation.salary,
									hourly_salary: e.target.value,
								}
							});
						}}
					/>
				</Grid>
			</Grid>

			{/*Director Details*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				spacing={2}
			>

				{/*Was/Is Director*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					className={'is-director-container'}
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={15}
						fontWeight={'bold'}
						color={'#000000'}
					>
						During the current tax year, is/was this employee a director ?
					</StyleTypography>
					<RadioGroup
						row
						onChange={(event) => {
							setEmployeeInformation({
								...employeeInformation,
								is_director: event.target.value === "true",
								active_director_detail: {
									...employeeInformation.active_director_detail,
									start_date: undefined,
									cumulative_calculation: undefined,
								},
							});
						}}
						defaultValue={employeeInformation.is_director}
						value={employeeInformation.is_director}
					>
						<FormControlLabel
							value={true}
							control={<Radio />}
							label={
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={'#000000'}
								>
									Yes
								</StyleTypography>
							}
						/>
						<FormControlLabel
							value={false}
							control={<Radio />}
							label={
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={'#000000'}
								>
									No
								</StyleTypography>
							}
						/>
					</RadioGroup>
				</Grid>

				{employeeInformation.is_director === true && (
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
						container
						spacing={2}
					>
						{/*Date appointed director*/}
						<Grid
							xl={4}
							lg={4}
							md={4}
							sm={12}
							xs={12}
							item
							className={'date-appointed-director-container'}
						>
							<InputLabel className={'default-input-label'}>
								Date appointed director*
							</InputLabel>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={enGb}
							>
								<DesktopDatePicker
									className={'date-picker'}
									onChange={(newValue) => {
										if (newValue === null) {
											setEmployeeInformation({
												...employeeInformation,
												active_director_detail: {
													...employeeInformation.active_director_detail,
													start_date: null,
												},
											});
											return;
										}

										if (newValue instanceof Date && isNaN(newValue.getTime())) {
											return; // Exit if the date is invalid
										}

										setEmployeeInformation({
											...employeeInformation,
											active_director_detail: {
												...employeeInformation.active_director_detail,
												start_date: newValue.toISOString().split('T')[0], // Ensuring the date is stored in ISO format,
											},
										});
									}}
									slotProps={{ field: { readOnly: true } }}
									enableAccessibleFieldDOMStructure
									onError={(error) => {
										if (error !== null) {
											setEmployeeInformation({
												...employeeInformation,
												active_director_detail: {
													...employeeInformation.active_director_detail,
													start_date_error: true,
												},
											});
										} else {
											setEmployeeInformation({
												...employeeInformation,
												active_director_detail: {
													...employeeInformation.active_director_detail,
													start_date_error: false,
												},
											});
										}
									}}
									value={employeeInformation.active_director_detail?.start_date ? parseISO(employeeInformation.active_director_detail.start_date) : null}
								/>
							</LocalizationProvider>
						</Grid>

						{/*Calculation method*/}
						<Grid
							item
							xl={4}
							lg={4}
							md={4}
							sm={12}
							xs={12}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Calculation method
							</InputLabel>
							<FormControl
								fullWidth
							>
								<Select
									className={"default-select"}
									id={"select-gender"}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200, // Adjust the maximum height of the list
											},
										},
									}}
									value={employeeInformation.active_director_detail?.cumulative_calculation ?? " "}
									onChange={(event) => {
										setEmployeeInformation({
											...employeeInformation,
											active_director_detail: {
												...employeeInformation.active_director_detail,
												cumulative_calculation: event.target.value === true,
											},
										});
									}}
								>
									<MenuItem
										key={'select-title'}
										value={" "}
										disabled
									>
										Select
									</MenuItem>
									<MenuItem
										key={'true'}
										value={true}
									>
										Standard annual method
									</MenuItem>
									<MenuItem
										key={'false'}
										value={false}
									>
										Alternative method
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>

						{/*Date directorship ended*/}
						<Grid
							xl={4}
							lg={4}
							md={4}
							sm={12}
							xs={12}
							item
							className={'date-appointed-director-container'}
						>
							<InputLabel className={'default-input-label'}>
								Date directorship ended (Leave blank if still active)
							</InputLabel>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={enGb}
							>
								<DesktopDatePicker
									className={'date-picker'}
									onChange={(newValue) => {
										if (newValue === null) {
											setEmployeeInformation({
												...employeeInformation,
												active_director_detail: {
													...employeeInformation.active_director_detail,
													end_date: null,
												},
											});
											return;
										}

										if (newValue instanceof Date && isNaN(newValue.getTime())) {
											return; // Exit if the date is invalid
										}

										setEmployeeInformation({
											...employeeInformation,
											active_director_detail: {
												...employeeInformation.active_director_detail,
												end_date: newValue.toISOString().split('T')[0], // Ensuring the date is stored in ISO format,
											},
										});
									}}
									slotProps={{ field: { readOnly: true } }}
									enableAccessibleFieldDOMStructure
									onError={(error) => {
										if (error !== null) {
											setEmployeeInformation({
												...employeeInformation,
												active_director_detail: {
													...employeeInformation.active_director_detail,
													end_date_error: true,
												},
											});
										} else {
											setEmployeeInformation({
												...employeeInformation,
												active_director_detail: {
													...employeeInformation.active_director_detail,
													end_date_error: false,
												},
											});
										}
									}}
									value={employeeInformation.active_director_detail?.end_date ? parseISO(employeeInformation.active_director_detail.end_date) : null}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				)}

			</Grid>
		</Grid>
	);
};