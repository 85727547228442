import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import copy from '../../../images/svg/copy.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import StyleTypography from '../../StyledComponents/StyleTypography';
import {useSearchCompanies, useVerifyCompany} from "../../../hooks/signup";

/**
 * @param step
 * @param setStep
 * @param setSignupType
 * @param companies
 * @param setCompany
 * @param company
 * @returns {JSX.Element}
 * @constructor
 */
const FindYourCompany = ({
	step = 0,
	setStep = () => {},
	setSignupType = () => {},
	setCompany = () => {},
	company = {},
}): JSX.Element => {

	const { mutateAsync: searchCompanies, isPending: isSearching } = useSearchCompanies();

	const [searchTimeout, setSearchTimeout] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [canProceed, setCanProceed] = useState(false);

	const { mutateAsync: verifyCompany, isPending: isVerifying } = useVerifyCompany(setCanProceed);


	return (
		<Grid
			id={'company-details-content'}
			container
			justifyContent={'flex-start'}
			alignItems={'flex-start'}
			direction={'column'}
		>
			<Typography className={'typography-black-header'}>
				Now, find your company
			</Typography>

			<Typography className={'typography-black-subheader'}>
				TYPE YOUR COMPANY NAME OR ENTER MANUALLY
			</Typography>

			<FormControl fullWidth>
				<Autocomplete
					className={'default-select'}
					options={companies ?? []}
					getOptionLabel={(option) =>
						option &&
						(typeof option === 'object'
							? `${option.company_number} - ${option.company_name}`
							: option)
					}
					onChange={(e, newValue = { company_number: '' }) => {
						setCompany(newValue);
						if (newValue) {
							verifyCompany(newValue.company_number);
						}
					}}
					loading={isSearching || isVerifying}
					loadingText={'Searching...'}
					noOptionsText={'Company not found.'}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={'Search Company'}
							onChange={(e) => {
								if (e.target.value === '') {
									return;
								}
								if (searchTimeout) {
									clearTimeout(searchTimeout);
								}
								setSearchTimeout(
									setTimeout(async () => {
										try {
											const companies = await searchCompanies({
												employers: {
													q: e.target.value,
													items_per_page: 0,
												},
											});
											setCompanies(companies);
										} catch (e) {
											console.error(e);
										}
									}, 1000)
								);
							}}
						/>
					)}
				></Autocomplete>
			</FormControl>

			{!canProceed && company !== null && !(isSearching) && (
				<StyleTypography
					fontSize={16}
					fontSizeMedium={10}
					color={'red'}
				>
					There is already an account registered for this company.
				</StyleTypography>
			)}

			<Typography
				className={'enter-details-manually'}
				onClick={() => {
					setStep(step + 2);
					setSignupType('manual');
				}}
			>
				<img
					src={copy}
					alt={'enter details manually'}
				/>
				Enter details manually
			</Typography>

			<Button
				className={'default-black-button'}
				id={'company-details-continue-button'}
				onClick={() => {
					setStep(step + 1);
				}}
				disabled={!company || !canProceed || isVerifying || isSearching}
			>
				Continue
			</Button>
		</Grid>
	);
};

export default FindYourCompany;
