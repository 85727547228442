import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// importing the provider.
import {Crisp} from "crisp-sdk-web";
import ScreenWidthChecker from "./components/ScreenWidthChecker";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById('root'));
Crisp.configure("e4e6507c-12e8-47da-b150-3e9f2dbb1535",{
    autoload: false
});
// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: 'always',
            retry: false,
        },
    },
});

root.render(
    <QueryClientProvider client={queryClient}>
        <ScreenWidthChecker> {/* Wrap your app with the ScreenWidthChecker */}
            <App />
        </ScreenWidthChecker>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
