import Grid from "@mui/material/Grid";
import {StyleAppBar} from "../../components/StyleAppBar";
import error from "../../images/svg/Errors/NotFound.svg";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import React, {useEffect} from "react";
import StyleButton from "../../components/StyledComponents/StyleButton";
import {useNavigate} from "react-router-dom";
import {Crisp} from "crisp-sdk-web";
import {useMe} from "../../hooks/user";

const ErrorPage = (): JSX.Element => {

	const { profileData:user } = useMe();

	const navigate = useNavigate();

	useEffect(() => {
		Crisp.load();
		Crisp.session.setData({
			auth_token : localStorage.getItem('fpEmployerToken'),
		});
		Crisp.user.setEmail(user?.email);
		Crisp.user.setNickname(user?.name);
		Crisp.user.setPhone(user?.phone_number)
	}, [user?.email, user?.name, user?.phone_number])

	return (
		<Grid
			container
			justifyContent={"center"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={10}
				lg={10}
				md={10}
				sm={10}
				xs={10}
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
				position={"absolute"}
				top={"25%"}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					lg={6}
					xl={6}
				>
					<StyleTypography
						fontSize={60}
						fontSizeMedium={55}
						fontWeight={"bold"}
						color={"#275EF3"}
						component={"h1"}
					>
						Internal Server Error
					</StyleTypography>

					<StyleTypography
						fontSize={18}
						fontSizeMedium={16}
						color={"#ABAEB7"}
					>
						An Internal Server Error occurred. <br/>
						Please contact support.
					</StyleTypography>

					<Grid marginTop={4}>
						<StyleButton
							fullWidth={false}
							onClick={() => navigate("/main")}
						>
							Back to Home
						</StyleButton>
					</Grid>

				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					lg={6}
					xl={6}
				>
					<img
						src={error}
						alt={"Not Found"}
						style={{
							width: "100%",
							height: "auto"
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
};

export default ErrorPage;