import {useMutation} from "@tanstack/react-query";
import {toast} from "sonner";
import Axios from "axios";

export function useBulkImportPayItems() {

	const options = {
		baseURL: process.env.REACT_APP_FP_GOLANG_API,
		withCredentials: false,
		timeout: 960000,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
		}
	};

	// creating the axios instance.
	let managePayItemRequest = Axios.create(options);

	return useMutation({
		mutationFn: async (data) => {
			return await managePayItemRequest.post(`/manage-bulk-pay-items`, data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success("Bulk imported pay items successfully");
			return data;
		},
	});
}