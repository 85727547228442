import React, {useEffect, useReducer, useState} from "react";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {validateEmail} from "../../../utils/Helpers";
import {verifyFullName} from "../../../components/Pages/SignUp/CreateAccountCard";
import {useUser} from "../../../context/UserContext";
import FPTable from "../../../components/common/FPTable/FPTable";
import {useAddUser, useGetUsers} from "../../../hooks/user";
import {
	FreepayrollButton,
	FreepayrollTextField,
	Modal,
	Typography,
	Chip,
} from "@collegia-partners/ui-kit";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";

function AddUserModal({ open, onClose }) {

	const [newAdmin, setNewAdmin] = useReducer(
		(state, updates) => ({...state, ...updates}),
		{
			name: "",
			email: "",
			phone: "",
		}
	);

	const { mutateAsync: createAdmin, isPending: isSaving } = useAddUser();

	return (
		<Modal
			isOpen={open}
			onClose={() => {
				setNewAdmin({
					name: "",
					email: "",
					phone: "",
				});
				onClose();
			}}
			size={'sm'}
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw' }} >
				<Typography
					variant={"title"}
					size={"large"}
				>
					Add User
				</Typography>
				<FreepayrollTextField
					fieldType={"text"}
					name={"name"}
					value={newAdmin.name}
					onChange={(e) => setNewAdmin({
						name: e.target.value
					})}
					label={"Full Name*"}
					error={verifyFullName(newAdmin.name) && newAdmin.name !== ""}
				/>
				<FreepayrollTextField
					fieldType={"email"}
					name={"email"}
					value={newAdmin.email}
					onChange={(e) => setNewAdmin({
						email: e.target.value.toLowerCase()
					})}
					label={"Email*"}
					error={
						(newAdmin.email !== '' && !validateEmail(newAdmin.email))
					}
					helperText={
						newAdmin.email !== '' && !validateEmail(newAdmin.email)
						&& 'Invalid e-mail'

					}
				/>
				<MuiTelInput
					className={'default-phone-input create-account-card-text-field'}
					defaultCountry={'GB'}
					flagSize={'medium'}
					forceCallingCode
					value={newAdmin.phone}
					error={!matchIsValidTel(newAdmin.phone) && newAdmin.phone !== ''}
					onChange={(value) =>
						setNewAdmin({
							phone: value,
						})
					}
				/>
				<div style={{ display: 'flex', gap: '5vw' }} >
					<FreepayrollButton
						variant={"white-button"}
						onClick={() => {
							setNewAdmin({
								name: "",
								email: "",
								phone: "",
							});
							onClose();
						}}
						isLoading={isSaving}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={async() => {
							try {
								await createAdmin({
									'employers': {
										name: newAdmin.name,
										email: newAdmin.email,
										phone: newAdmin.phone,
									}
								});
								setNewAdmin({
									name: "",
									email: "",
									phone: "",
								});
								onClose();
							} catch (e) {
								console.error(e);
							}
						}}
						isLoading={isSaving}
						disabled={
							verifyFullName(newAdmin.name) ||
							(newAdmin.email !== '' && !validateEmail(newAdmin.email)) ||
							!matchIsValidTel(newAdmin.phone)
						}
					>
						Add
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

const CompanyProfile = (): JSX.Element => {
	const [openAddUser, setOpenAddUser] = useState(false);

	const { profileData: user } = useUser();

	const { setLayoutProps } = useLoggedLayout();

	const columns = [
		{
			text: '',
			customRender: (row) => (
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
					<div style={{ display: 'flex', flexDirection:'column',gap: '5px' }} >
						<Typography variant={"subtitle"} color={"black"}>
							{row?.user?.name}
						</Typography>
						<Typography variant={"body"} color={"gray"}>
							{row?.user?.email}
						</Typography>
					</div>
					{
						row.user?.id === user?.id && (
							<Chip color='#0360FD'>You</Chip>
						)
					}
				</div>
			),
			width: '8fr',
		},
		{
			text: '',
			customRender: (row) => (
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
					{
						row.is_account_owner && (
							<Chip color='#000'>Account Owner</Chip>
						)
					}
				</div>
			)
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Home',
		});
	}, [setLayoutProps]);

	return (
		<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "4.57vw"}}>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
				<div>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Users
					</Typography>
					<Typography variant={"title"} size={"medium"} color={"gray"} weight={"normal"}>
						Having multiple users allows you to share access to your account with your team
					</Typography>
				</div>
				<div style={{width: "fit-content"}}>
					<FreepayrollButton
						size={"small"}
						variant={"primary"}
						height={0.7}
						custom
						width={2}
						onClick={() => setOpenAddUser(true)}
					>
						+ Add User
					</FreepayrollButton>
				</div>
			</div>
			<div>
				<FPTable
					headers={columns}
					hookName={useGetUsers}
					hookKey={'users'}
					hideHeader
					paginate={false}
				/>
			</div>
			<AddUserModal
				open={openAddUser}
				onClose={() => setOpenAddUser(false)}
			/>
		</div>
	);
};

export default CompanyProfile;