import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {FreepayrollButton, FreepayrollTextField, Typography} from "@collegia-partners/ui-kit";
import {useUpdateEmail} from "../../../hooks/user";
import {validateEmail} from "../../../utils/Helpers";

const EditEmailForm = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();
	const { mutate: updateEmail, isPending: isUpdating } = useUpdateEmail();

	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [newEmail, setNewEmail] = useState("");
	const [confirmEmail, setConfirmEmail] = useState("");

	useEffect(() => {
		setLayoutProps({
			activePage: 'Home',
			showBackButton: true,
			backLink: '/main/settings/edit-info',
		});
	}, [setLayoutProps]);
	
	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '1.53vw'}}>
			<Typography variant={"title"}>Change email</Typography>
			<Typography variant={"subtitle"}>
				To update your email address, please type the new address
				below and your current password.
			</Typography>
			<div style={{display: 'flex', flexDirection: 'column', maxWidth: '40%', gap: '0.5vw'}}>
				<FreepayrollTextField
					fieldType={"email"}
					name={"email"}
					label={"New Email*"}
					value={newEmail}
					onChange={(e) => setNewEmail(e.target.value?.toLowerCase())}
					error={!validateEmail(newEmail)}
					helperText={
						validateEmail(newEmail) ? "" : "Please enter a valid email address"
					}
				/>
				<FreepayrollTextField
					fieldType={"email"}
					name={"confirm_email"}
					label={"Confirm Email*"}
					value={confirmEmail}
					onChange={(e) => setConfirmEmail(e.target.value?.toLowerCase())}
					error={newEmail !== confirmEmail}
					helperText={
						newEmail !== confirmEmail ? "Emails do not match" : ""
					}
				/>
				<FreepayrollTextField
					fieldType={"password"}
					name={"password"}
					label={"Current Password*"}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>
			<div style={{display: 'flex', maxWidth: '40%', gap: '0.83vw'}}>
				<FreepayrollButton
					variant={"white-button"}
					onClick={() => navigate("/main/settings/edit-info")}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					onClick={() => {
						updateEmail({
							"users": {
								email: newEmail,
								password
							}
						})
					}}
					isLoading={isUpdating}
					disabled={
						!validateEmail(newEmail) ||
						newEmail !== confirmEmail ||
						!newEmail ||
						!confirmEmail ||
						!password
					}
				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
};

export default EditEmailForm;