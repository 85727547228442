// LoggedLayoutContext.js
import React, { createContext, useContext, useState } from 'react';

const LoggedLayoutContext = createContext();

export const LoggedLayoutProvider = ({ children }) => {
	const [layoutProps, setLayoutProps] = useState({
		backLink: null,
		showBackButton: false,
		activePage: 'Home',
		activeSubpage: '',
		leftMenuFixed: false,
		expanded: true,
		isOnboarding: false,
		lockMenuItems: [],
		custom: false,
		customOnClick: () => {},
	});

	return (
		<LoggedLayoutContext.Provider value={{ ...layoutProps, setLayoutProps }}>
			{children}
		</LoggedLayoutContext.Provider>
	);
};

export const useLoggedLayout = () => useContext(LoggedLayoutContext);
