import React from "react";
import Routes from "./utils/Routes";
import { Toaster } from 'sonner';

const App = () => (
	<div>
		<Toaster
			position="top-right"
			richColors={true}
		/>
		<Routes/>
	</div>
)

export default App;