import React, {useEffect, useState} from "react";
import {
	FreepayrollButton,
	Typography,
	Switch,
} from "@collegia-partners/ui-kit";
import {
	useUpdateEmployee
} from "../../../../../hooks/employees";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import {formatMoney} from "../../../../../utils/Helpers";
import {useParams} from "react-router-dom";

function StudentLoanCard(
	{
		title = "",
		subtitle = "",
		annual_threshold = 0,
		repay_percentage = 0,
		active = false,
		setEmployeeStudentLoans,
		index,
	}
) {

	return (
		<div
			style={{
				display: "flex",
				padding: '1.23vw',
				border: '1px solid #c9ccd1',
				maxWidth: '63%',
				justifyContent: 'space-around',
				alignItems: 'center',
			}}
		>
			<div style={{ display: "flex", flexDirection: "column", flexBasis: '73%', gap: '0.83vw' }} >
				<Typography variant={"subtitle"} color={"black"}>
					{title}
				</Typography>
				<Typography variant={"label"} color={"gray"} weight={"normal"}>
					{subtitle}
				</Typography>
				<Typography variant={"label"} color={"black"}>
					Annual threshold: <b>{formatMoney(annual_threshold)}</b>
				</Typography>
				<Typography variant={"label"} color={"black"}>
					Repay percentage: <b>{repay_percentage * 100}%</b>
				</Typography>
			</div>
			<div>
				<Switch
					isChecked={active}
					onCheckChange={(checked) => {
						setEmployeeStudentLoans((prev) => {
							const newPlans = [...prev];
							newPlans[index].active = checked;
							return newPlans;
						});
					}}
				/>
			</div>
		</div>
	);
}

export const StudentLoansTab = () => {

	const employeeId = useParams().employeeId;
	
	const { employee } = useGetEmployee({ id: employeeId, relations: ['student_loans'] });
	const { mutateAsync: updateEmployee, isPending } = useUpdateEmployee();
	
	const [employeeStudentLoans, setEmployeeStudentLoans] = useState([]);
	
	useEffect(() => {
		if (employee?.student_loans) {
			setEmployeeStudentLoans(employee.student_loans);
		}
	}, [employee]);

	return (
		<div style={{display: "flex", flexDirection: "column", gap: "3vw"}}>
			<div>
				<Typography
					variant={"title"}
				>
					Student Loans
				</Typography>
				<Typography
					variant={"body"}
				>
					Set up Student or Postgraduate Loan Repayment plan
				</Typography>
			</div>
			{
				employeeStudentLoans && employeeStudentLoans?.map((plan, index) => (
					<StudentLoanCard
						key={plan.plan_name.name}
						title={plan.plan_name.name}
						subtitle={plan.plan_name.description}
						active={plan.active}
						annual_threshold={plan?.threshold?.trigger_amount ?? 0}
						repay_percentage={plan?.threshold?.repayment_rate ?? 0}
						index={index}
						setEmployeeStudentLoans={setEmployeeStudentLoans}
					/>
				))
			}
			<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
				<FreepayrollButton
					variant={"white-button"}
					isLoading={isPending}
					fullWidth={false}
					width={5}
					custom={true}
					disabled={
						employeeStudentLoans?.length === 0 ||
						employeeStudentLoans === employee?.student_loans
					}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					isLoading={isPending}
					fullWidth={false}
					width={5}
					height={1}
					custom={true}
					disabled={
						employeeStudentLoans?.length === 0 ||
						employeeStudentLoans === employee?.student_loans
					}
					onClick={async () => {
						try {
							await updateEmployee({
								safeId: employee.safe_id,
								data: {
									employees: {
										action: 'student_loans',
										student_loans: employeeStudentLoans
									}
								}
							});
						} catch (e) {
							console.error(e);
						}
					}}
				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
};
