import {useMutation, useQueryClient} from "@tanstack/react-query";
import Request from "../../utils/Request";

export function useImportCollegiaData(setMandateFlowInitiated,setToken) {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/import-collegia`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			if (data?.has_mandate) {
				queryClient.invalidateQueries({queryKey: ['employer']});
			} else {
				setMandateFlowInitiated(true);
				setToken(data?.token);
			}
			return data;
		},
	});
}