import React, {useEffect} from "react";
import Loading from "../../components/Loading";
import {useNavigate} from "react-router-dom";
import {formatDateFromBackendWithTime} from "../../utils/Helpers";
import {useUser} from "../../context/UserContext";
import {useLoggedLayout} from "../../context/LoggedLayoutContext";
import {useListPayrolls} from "../../hooks/payrolls";
import FPTable from "../../components/common/FPTable/FPTable";
import {FreepayrollButton, Typography, Chip} from "@collegia-partners/ui-kit";
import {renderPayRunStatusColor} from "./Payroll/PayrollActive";
import {useGetEmployer} from "../../hooks/employer";
import {TaxYearBanner} from "../../components/Pages/MainPage/TaxYearBanner";
import {StickerAlert} from "../../components/StickerAlert";
import {useGetFailedFpsSubmissions} from "../../hooks/employer/useGetFailedFpsSubmissions";

function Greeting() {
	const currentHour = new Date().getHours();
	let greeting;

	if (currentHour < 12) {
		greeting = 'Good morning';
	} else if (currentHour < 18) {
		greeting = 'Good afternoon';
	} else {
		greeting = 'Good evening';
	}

	return greeting;
}

export function disableRunPayrollButton(employer) {
	 return !!(
		 employer?.account_locked ||
		 (employer?.basic_setup_step === 2 && employer?.basic_setup_complete)
	 );

}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const MainPage = (): JSX.Element => {

	const {profileData: user } = useUser();
	const { setLayoutProps } = useLoggedLayout();

	const { employer, isPending: isLoadingEmployer } = useGetEmployer({ relations: ['active_pay_schedule','pendingDirectDebit'] });

	const { failedSubmissions } = useGetFailedFpsSubmissions();

	const push = useNavigate();

	const columns = [
		{
			text: "Schedule Name",
			key: "name",
			width: '1.5fr',
		},
		{
			text: "Tax Period",
			key: "tax_period",
		},
		{
			text: "Pay Run",
			key: "pay_run_period",
			width: '1.5fr'
		},
		{
			text: "Pay Date",
			key: "period_end_date",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{formatDateFromBackendWithTime(row.period_end_date)}
				</Typography>
			),

		},
		{
			text: "Status",
			key: "status",
			customRender: (row, index) => (
				<Chip key={index} color={renderPayRunStatusColor(row.status)} >
					{row.status}
				</Chip>
			),
		},
		{
			text: "Actions",
			width: '0.75fr',
			customRender: (row, index) => (
				<FreepayrollButton
					variant={"primary"}
					onClick={() => {
						push(`/main/run-payroll/${btoa(row.id.toString())}`);
					}}
					size={"small"}
					height={0.833}
					disabled={disableRunPayrollButton(employer)}
					key={index}
				>
					Run Payroll
				</FreepayrollButton>
			),
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Home',
		});
	}, [setLayoutProps]);

	if (isLoadingEmployer) {
		return <Loading/>;
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '3.5vw' }} >
			<Typography
				variant={'title'}
			>
				{Greeting()}, {user?.name.split(" ")[0]}
			</Typography>

			{/*FPS Failed Notification*/}
			{
				failedSubmissions?.map((payScheduleRun) => (
					<StickerAlert
						text={`Your FPS submission for pay schedule ${payScheduleRun?.pay_schedule?.name}, tax period ${payScheduleRun?.tax_period} has failed.`}
						iconColor={'red'}
						actionText={'Resubmit'}
						action={() => push(`/main/reports/fps?employerPayScheduleId=${payScheduleRun?.id}&payScheduleId=${payScheduleRun?.pay_schedule?.id}`)}
					/>
				))
			}


			{/*Close Tax Year*/}
			{
				employer?.show_close_year && (
					<TaxYearBanner push={push}/>
				)
			}

			{
				employer?.active_pay_schedule === null && (
					<StickerAlert
						text="You currently have no active pay schedule."
						actionText={'+ Add Pay Schedule'}
						action={() => push('/main/company/add-pay-schedule')}
					/>
				)
			}

			{/*Waiting for direct debit*/}
			{
				employer?.basic_setup_step === 2 && employer?.basic_setup_complete && (
					<StickerAlert
						text="You need to finish setting up your direct debit before running payroll."
						actionText={'Finish now'}
						action={() => window.open(`/auto-enrolment/${employer.pending_direct_debit?.token}`)}
					/>
				)
			}

			<FPTable
				headers={columns}
				hookName={useListPayrolls}
				hookKey={'payrolls'}
				hookParams={{
					mode: 'active',
				}}
				paginate={false}
			/>
		</div>
	);
};

export default MainPage;
