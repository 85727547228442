import {useQuery} from "@tanstack/react-query";
import Request from "../../utils/Request";

export function useGetPayrollAndEmail() {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['email-and-payroll',],
		queryFn: async () => {
			return await Request.get(`/api/employees/get-payroll-and-email`)
				.then(response => response.data)
		},
		staleTime: 1000 * 60 * 60,
	});

	return {
		emails: data?.data?.employees_email,
		payroll: data?.data?.employees_payroll,
		isPending,
		isError,
	};
}
