import TeamMembers from "./TeamMembers";
import AddEmployeeMethod from "./AddEmployeeMethod";
import AddSingleEmployeeMain from "./AddSingleEmployeeMain";
import BulkEmployees from "./BulkEmployees";
import EmployeeRecord from "./EmployeeRecord/EmployeeRecord";

const PeoplePages = {
	TeamMembers,
	EmployeeRecord,
	AddEmployeeMethod,
	AddSingleEmployeeMain,
	BulkEmployees
}

export default PeoplePages;