import {
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import Request from "../utils/Request";
import {toast} from "sonner";

export function useUpdateStatutoryAmounts() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/update-statutory-ytd-amounts', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success('Statutory amounts updated successfully');
			queryClient.setQueryData(['employer', 'active_employer_ytd_figure'], data?.data);
		},
	});
}