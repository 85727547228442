import React, {useCallback, useEffect, useReducer, useState} from "react";
import Loading from "../../../../components/Loading";
import {useNavigate, useParams} from "react-router-dom";
import {resolveTaxPeriodAndPeriodDates} from "../../../../utils/Helpers";
import {Divider, FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import {
	PayScheduleConfirmDialog
} from "../../../../components/Pay Schedules/PayScheduleSettings/PayScheduleConfirmDialog";
import {
	GeneralFields,
	RequireClientApproval, UponApprovalClosePayroll,
} from "../../../../components/Pay Schedules/PayScheduleSettings";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import {useGetEmployer} from "../../../../hooks/employer";
import {useUser} from "../../../../context/UserContext";
import {useFindPaySchedule} from "../../../../hooks/pay-schedules";

function StepsPaySchedule ({
   step = 0,
   schedule = {},
   setSchedule = () => {},
   minDate,
   maxDate,
   shouldDisableField,
   isBureau = false,
}) {
	switch (step) {
		case 0:
			return (
				<GeneralFields
					scheduleName={schedule.name}
					setScheduleName={(e) => {
						schedule.name = e.target.value;
						setSchedule(schedule);
					}}
					payFrequency={schedule.frequency_code}
					setPayFrequency={(option) => {
						if (option.value !== schedule.frequency_code) {
							schedule.week_payday = null;
							schedule.month_payday = null;
							schedule.custom_payday = null;
							schedule.starting_period_end_date = '';
						}
						schedule.frequency_code = option.value;
						setSchedule(schedule);
					}}
					payDay={schedule.custom_payday || schedule.week_payday || schedule.month_payday?.toString()}
					setPayDay={(option) => {
						if (!option) return;
						if (
							option.value === schedule.custom_payday ||
							option.value === schedule.week_payday ||
							option.value === schedule.month_payday
						) return;
						if (schedule.frequency_code === "W1" || schedule.frequency_code === "W2" || schedule.frequency_code === "W4") {
							schedule.week_payday = option.value;
							schedule.custom_payday = null;
							schedule.month_payday = null;
						} else {
							if (option.value === "Last Day of Month") {
								schedule.custom_payday = option.value;
								schedule.month_payday = null;
								schedule.week_payday = null;
							} else {
								schedule.month_payday = option.value;
								schedule.custom_payday = null;
								schedule.week_payday = null;
							}
						}
						setSchedule(schedule);
					}}
					minDate={minDate}
					maxDate={maxDate}
					payDate={schedule.starting_period_end_date}
					setPayDate={(value) => {
						schedule.starting_period_end_date = value.format("YYYY-MM-DD");
						schedule.taxPeriod = resolveTaxPeriodAndPeriodDates(
							schedule?.frequency_code,
							value,
							schedule?.custom_payday === "Last Day of Month"
						)["tax_period"];
						setSchedule(schedule);
					}}
					dayRateMethod={schedule.day_rate_method}
					setDayRateMethod={(option) => {
						if (!option) return;
						schedule.day_rate_method = option.value;
						setSchedule(schedule);
					}}
					isAutomaticPaySchedule={schedule.automatic_pay_schedule}
					setIsAutomaticPaySchedule={(value) => {
						schedule.automatic_pay_schedule = value;
						if (!value) {
							schedule.submission_date_offset = null;
							schedule.require_client_authorisation = false;
							schedule.close_upon_approval = false;
							schedule.authoriser_name = "";
							schedule.authoriser_email = "";
						}
						setSchedule(schedule);
					}}
					submissionDateOffset={schedule.submission_date_offset}
					setSubmissionDateOffset={(value) => {
						schedule.submission_date_offset = value;
						setSchedule(schedule);
					}}
					shouldDisableField={shouldDisableField}
					isBureau={isBureau}
				/>
			);
		case 1:
			return (
				<RequireClientApproval
					requireClientApproval={schedule.require_client_authorisation}
					authorizerName={schedule.authoriser_name}
					authorizerEmail={schedule.authoriser_email}
					setRequireClientApproval={(value) => {
						schedule.require_client_authorisation = value;
						if (!value) {
							schedule.authoriser_name = "";
							schedule.authoriser_email = "";
							schedule.close_upon_approval = false;
						}
						setSchedule(schedule);
					}}
					setAuthorizerName={(e) => {
						schedule.authoriser_name = e.target.value;
						setSchedule(schedule);
					}}
					setAuthorizerEmail={(e) => {
						schedule.authoriser_email = e.target.value;
						setSchedule(schedule);
					}}
				/>
			);
		case 2:
			return (
				<UponApprovalClosePayroll
					submitPayrollUponClientApproval={schedule.close_upon_approval}
					setSubmitPayrollUponClientApproval={(value) => {
						schedule.close_upon_approval = value;
						setSchedule(schedule);
					}}
				/>
			);
		default:
			return;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EditPaySchedule = (): JSX.Element => {
	const { setLayoutProps } = useLoggedLayout();
	const push = useNavigate();
	const [step, setStep] = useState(0);
	const [scheduleRecord, setScheduleRecord] =  useReducer(
		(state, updates) => ({
			...state,
			...updates,
		}),
		{
		name: "",
		frequency_code: "",
		week_payday: "",
		month_payday: "",
		custom_payday: "",
		starting_period_end_date: "",
		day_rate_method: "",
		automatic_pay_schedule: false,
		submission_date_offset: null,
		require_client_authorisation: false,
		close_upon_approval: false,
		authoriser_name: "",
		authoriser_email: "",
	});
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	function disable(schedule) {
		if (step === 0) {
			return schedule?.name?.length === 0 ||
				schedule?.frequency_code?.length === 0 ||
				(!schedule?.week_payday && !schedule?.custom_payday && !schedule?.month_payday) ||
				(schedule?.automatic_pay_schedule && schedule?.submission_date_offset === null) ||
				schedule?.starting_period_end_date === null
		} else if (step === 1) {
			return (
				schedule?.require_client_authorisation &&
				(
					!schedule.authoriser_name ||
					!schedule.authoriser_email
				)
			)
		}
	}

	const scheduleId = useParams().scheduleId;
	const { paySchedule, isPending: isLoadingPaySchedule } = useFindPaySchedule({ id: scheduleId, relations: ['not_draft_pay_run'] });

	const { employer, isPending: isLoadingEmployer } = useGetEmployer({ relations: ['tax_year'] })
	const { profileData:user } = useUser();

	const onNext = useCallback(() => {
		if (user?.is_bureau_user) {
			if (step === 0 && !scheduleRecord.automatic_pay_schedule ) {
				setOpenConfirmDialog(true);
			} else if (step === 1 && !scheduleRecord?.require_client_authorisation) {
				setOpenConfirmDialog(true);
			} else if (step === 2) {
				setOpenConfirmDialog(true);
			} else {
				setStep(step + 1);
			}
		} else {
			setOpenConfirmDialog(true);
		}
	},[scheduleRecord.automatic_pay_schedule, scheduleRecord?.require_client_authorisation, step, user?.is_bureau_user]);
	const onBack = useCallback(() => {
		if (step === 0) {
			push("/main/company/pay-schedules");
		}
		setStep(step - 1);
	}, [push, step]);
	
	useEffect(() => {
		setScheduleRecord(paySchedule);
	}, [paySchedule]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Pay Schedules',
			showBackButton: true,
			custom: true,
			customOnClick: () => onBack(),
		});
	}, [setLayoutProps, onBack]);
	
	if (isLoadingPaySchedule || isLoadingEmployer) {
		return (
			<Loading/>
		);
	}

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", gap: "2vw", width: "100%" }}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					Edit pay schedule
				</Typography>
				<Divider color={"gray"}/>
				<StepsPaySchedule
					step={step}
					schedule={scheduleRecord}
					setSchedule={setScheduleRecord}
					minDate={employer?.tax_year?.from}
					maxDate={employer?.tax_year?.to}
					shouldDisableField={typeof paySchedule?.not_draft_pay_run?.id === "number"}
					isBureau={user?.is_bureau_user}
				/>
				<div style={{ width: '15vw'}}>
					<FreepayrollButton
						variant={"primary"}
						size={"medium"}
						onClick={onNext}
						disabled={disable(scheduleRecord)}
						className={"next-button-pay-schedule"}
					>
						{
							user?.is_bureau_user ? "Next" : "Save"
						}
					</FreepayrollButton>
				</div>
			</div>
			
			<PayScheduleConfirmDialog
				open={openConfirmDialog}
				setOpen={setOpenConfirmDialog}
				schedule={{
					id: scheduleRecord.id,
					scheduleName: scheduleRecord.name,
					firstPayDate: scheduleRecord.starting_period_end_date,
					payFrequency: scheduleRecord.frequency_code,
					payDays: scheduleRecord.custom_payday || scheduleRecord.week_payday || scheduleRecord.month_payday?.toString(),
					dayRateMethod: scheduleRecord.day_rate_method,
					automaticPaySchedule: scheduleRecord.automatic_pay_schedule,
					submissionDateOffset: scheduleRecord.submission_date_offset,
					clientAuthorisation: scheduleRecord.require_client_authorisation,
					closePayRun: scheduleRecord.close_upon_approval,
					authoriserName: scheduleRecord.authoriser_name,
					authoriserEmail: scheduleRecord.authoriser_email,
				}}
				isOnboarding={false}
				isBureau={user?.is_bureau_user}
				isUpdate={true}
			/>
		</>
	
	);
};

export default EditPaySchedule;