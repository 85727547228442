import DateIconBlueIcon from "../../../images/svg/date-icon-blue.svg";
import SettingsBlueIcon from "../../../images/svg/settings-blue.svg";
import GrowArrowIcon from "../../../images/svg/grow-arrow.svg";
import React, { useEffect } from 'react';
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useGetEmployer} from "../../../hooks/employer";
import Loading from "../../../components/Loading";
import {Typography} from "@collegia-partners/ui-kit";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";

function MenuItem({title, subtitle, link, icon}) {
	
	const push = useNavigate();
	
	return (
		<span className={"navigation-list-item"} onClick={() => push(link)}>
			<img src={icon} alt={"icon"} className={"navigation-list-item-icon"}/>
			<Typography
				variant={"subtitle"}
				weight={"bold"}
				color={"black"}
			>
				{title}
			</Typography>
			<Typography
				variant={"body"}
				weight={"normal"}
				color={"gray"}
			>
				{subtitle}
			</Typography>
		</span>
	);
}

function AutomaticEnrolmentMenu() {

	const { setLayoutProps } = useLoggedLayout();

	const { employer, isPending } = useGetEmployer({ relations: ['address'] });

	const COLLEGIA_DATA = [
		[
			{
				name: "Scheme Name",
				value: "Collegia",
			},
			{
				name: "Scheme Address",
				value: "3 West Way, Buxton Court OX2 0JB, United Kingdom",
			},
			{
				name: "Type of Scheme",
				value: "Personal",
			}
		],
		[
			{
				name: "Employer Pension Scheme Reference (EPSR)",
				value: "Col-" + employer?.company_number ?? "",
			},
			{
				name: "Pension Scheme Tax Reference (PSTR)",
				value: "20004161RJ",
			},
			{
				name: "Pension Scheme Registry (PSR) number",
				value: "12018421"
			}
		]
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Auto Enrolment',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	if (isPending) {
		return <Loading />;
	}

	return (
		<div id={"auto-enrolment-menu"}>
			<div>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					Workplace pension
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					Setup your workplace pension for auto enrolment
				</Typography>
			</div>
			<Divider/>

			<div className={"auto-enrolment-data"}>
				{COLLEGIA_DATA.map((data, index) => (
					<>
						<div className={"auto-enrolment-data-row"}>
							{data.map((item, index) => (
								<div key={index} className={"auto-enrolment-data-item"}>
									<Typography
										variant={"body"}
										weight={"normal"}
										color={"gray"}
									>
										{item.name}
									</Typography>
									<Typography
										variant={"subtitle"}
										weight={"bold"}
										color={"black"}
									>
										{item.value}
									</Typography>
								</div>
							))}
						</div>
						{index !== data.length - 1 && <Divider/>}
					</>
				))}
			</div>

			<div className={"navigation-list"}>
				<MenuItem
					title={"Pension payments"}
					subtitle={"View your pension payments"}
					link={"/main/auto-enrolment-payments"}
					icon={DateIconBlueIcon}
				/>
				<MenuItem
					title={"Pension settings"}
					subtitle={"Manage your automatic enrolment settings"}
					link={"/main/auto-enrolment-settings"}
					icon={SettingsBlueIcon}
				/>
				<MenuItem
					title={"Direct debit instruction"}
					subtitle={"Manage your direct debit instruction with Collegia"}
					link={"/main/auto-enrolment-ddi"}
					icon={GrowArrowIcon}
				/>
			</div>
		</div>
	)
}

export default AutomaticEnrolmentMenu;