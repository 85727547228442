import React, {useEffect, useState} from "react";
import {formatDateFromBackend} from "../../../utils/Helpers";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {Typography, Divider, FreepayrollTabs, FreepayrollDropbox, Icon} from "@collegia-partners/ui-kit";
import {
	useDownloadBreakdown, useDownloadInvoice,
	useGetPensionPaymentsOptOut,
	useGetPensionPaymentsPast,
	useGetPensionPaymentsUpcoming
} from "../../../hooks/automatic-enrolment";
import FPTable from "../../../components/common/FPTable/FPTable";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AutomaticEnrolmentPayments = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();

	const [tabValue, setTabValue] = useState(0);
	const [selectedRow, setSelectedRow] = useState(null);

	const { pension_payments_opt_out } = useGetPensionPaymentsOptOut();

	const { mutate: downloadBreakdown } = useDownloadBreakdown();

	const { mutate: downloadInvoice } = useDownloadInvoice();

	const handleMenuClose = () => {
		setSelectedRow(null);
	};

	const columns = [
		{
			text: "Payment Date",
			color: "black",
			key: "payment_date",
			customRender: (row, index) => (
				<Typography
					key={index}
					variant={"body"}
					color={"black"}
					weight={"bold"}
				>
					{formatDateFromBackend(row?.payment_date)}
				</Typography>
			)
		},
		{
			text: "Payment Status",
			color: "black",
			key: "status",
			customRender: (row, index) => row?.status && (
				<Typography
					key={index}
					variant={"body"}
					color={"black"}
					weight={"bold"}
				>
					{row?.status}
				</Typography>
			),
			hint: "Scheduled: Payment is scheduled to be made on the payment date. The payment amount is not final on this status." +
				"Exported: Payment has been exported to BACS and the payment amount is final on this status." +
				"Accepted: Payment has been paid to the pension provider." +
				"Rejected: Payment has been rejected. Please contact the pension provider for more information.",
		},
		{
			text: "N of Employees",
			color: "black",
			key: "total_employees",
			customRender: (row, index) => row?.total_employees && (
				<Typography
					key={index}
					variant={"body"}
					color={"black"}
					weight={"bold"}
				>
					{row?.total_employees}
				</Typography>
			)
		},
		{
			text: "Payment Amount",
			color: "black",
			key: "total_contributions",
			customRender: (row, index) => {
				return (
					<Typography
						key={index}
						variant={"body"}
						color={"black"}
						weight={"bold"}
					>
						{row?.total_contributions}
					</Typography>
				);
			}
		},
		{
			text: "Total Employee Contribution",
			color: "black",
			key: "total_employee_contribution",
			customRender: (row, index) => {
				return (
					<Typography
						key={index}
						variant={"body"}
						color={"black"}
						weight={"bold"}
					>
						{row?.total_employee_contribution}
					</Typography>
				);
			}
		},
		{
			text: "Total Employer Contribution",
			color: "black",
			key: "total_employer_amount",
			customRender: (row, index) => {
				return (
					<Typography
						key={index}
						variant={"body"}
						color={"black"}
						weight={"bold"}
					>
						{row?.total_employer_amount}
					</Typography>
				);
			}
		},
		{
			text: "Actions",
			color: "black",
			key: "id",
			customRender: (row, index) =>
				<div
					key={index}
					onClick={() => setSelectedRow(row)}
				>
					<FreepayrollDropbox
						target={
							<button
								style={{all: "unset", cursor: "pointer"}}
							>
								<Icon
									iconName={"dots-horizontal"}
								/>
							</button>
						}
						key={index}
					>
						<div className={"auto-enrolment-table-dot"}>
							<button
								className={"auto-enrolment-table-dot-item"}
								onClick={() => {
									if (selectedRow?.schedule_id === null) {
										downloadBreakdown({
											ae: {
												export_date: selectedRow?.export_date,
											}
										});
									} else {
										downloadBreakdown({
											ae: {
												schedule_id: selectedRow?.schedule_id,
											}
										});
									}
									handleMenuClose();
								}}
							>
								<Typography
									variant={"body"}
									color={"black"}
									weight={"bold"}
								>
									Download payment breakdown
								</Typography>
							</button>

							<button
								className={"auto-enrolment-table-dot-item"}
								onClick={() => {
									downloadInvoice({
										ae: {
											data: selectedRow?.json_agg,
											total_value: selectedRow?.total_contributions,
											date: selectedRow?.payment_date,
										}
									});
									handleMenuClose();
								}}
								disabled={selectedRow !== null && selectedRow?.status === "Upcoming"}
							>
								<Typography
									variant={"body"}
									color={"black"}
									weight={"bold"}
								>
									Download invoice
								</Typography>
							</button>
						</div>
					</FreepayrollDropbox>
				</div>
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Auto Enrolment',
			showBackButton: true,
			backLink: '/main/auto-enrolment-menu',
		});
	}, [setLayoutProps]);

	return (
		<div
			id={'auto-enrolment-page'}
		>
			<Typography
				variant={"title"}
				weight={"bold"}
				color={"#0160FD"}
			>
				Pension payments
			</Typography>
			<Typography
				variant={"subtitle2"}
				weight={"normal"}
				color={"gray"}
			>
				Here you can view all your pension payments, download invoices and
				download payment breakdown.
			</Typography>
			<Divider color={"light-gray"}/>
			<div>
				<div className={"auto-enrolment-page-information-container"}>
					{/*Total contributions under opt out*/}
					<div
						className={"auto-enrolment-page-total"}
					>
						<Typography
							variant={"subtitle2"}
							weight={"normal"}
							color={"black"}
						>
							Total pension contributions<br/>
							under opt-out
						</Typography>
						<Typography
							variant={"subtitle"}
							weight={"bold"}
							color={"#0160FD"}
						>
						£ {pension_payments_opt_out?.under_opt_out?.total_contributions ?? 0.00}
						</Typography>
					</div>

					{/*Total employees under opt out*/}
					<div
						className={"auto-enrolment-page-total"}
					>
						<Typography
							variant={"subtitle2"}
							weight={"normal"}
							color={"black"}
						>
							Total employees<br/>
							under opt-out
						</Typography>
						<Typography
							variant={"subtitle"}
							weight={"bold"}
							color={"#0160FD"}
						>
							{pension_payments_opt_out?.under_opt_out?.total_employees ?? 0}
						</Typography>
					</div>
				</div>

				<FreepayrollTabs
					className={"tabs-basic-setup"}
					value={tabValue}
					contents={[]}
					onValueChange={(value) => setTabValue(value)}
					items={[
						{
							children: "Upcoming payments",
							value: 0,
						},
						{
							children: "Past Payments",
							value: 1,
						}
					]}
				/>
				<div className={"auto-enrolment-page-table"}>
					{
						tabValue === 0 &&
						(
							<FPTable
								headers={columns}
								hookName={useGetPensionPaymentsUpcoming}
								hookKey={'pension_payments_upcoming'}
								hookParams={{}}
							/>
						)
					}
					{
						tabValue === 1 &&
						(
							<FPTable
								headers={columns}
								hookName={useGetPensionPaymentsPast}
								hookKey={'pension_payments_past'}
								hookParams={{}}
							/>
						)
					}
				</div>
			</div>
		</div>
	);
}

export default AutomaticEnrolmentPayments;