import {useQuery} from "@tanstack/react-query";
import Request from "../../../utils/Request";

export function useListPayItems({page, orderBy, orderDir, perPage = 10, is_salary = null}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['list-pay-items', {page, orderBy, orderDir, perPage, is_salary}],
		queryFn: async () => {
			return await Request.get('/api/employers/pay-items/get',{params: {page, orderBy, orderDir, perPage, is_salary}})
				.then(response => response.data)
		},
		keepPreviousData: false,

	});

	return {
		payItems: data?.data,
		isPending,
		isError,
	};
}