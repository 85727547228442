import Axios from "axios";
import {useMutation} from "@tanstack/react-query";

export function useRunPayroll() {

	const options = {
		baseURL: process.env.REACT_APP_FP_GOLANG_API,
		withCredentials: false,
		timeout: 960000,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
		}
	};

	// creating the axios instance.
	let runPayrollRequest = Axios.create(options);

	return useMutation({
		mutationFn: async (data) => {
			return await runPayrollRequest.post(`/run-payroll`, data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			return data;
		},
	});
}