import Login from "./Login";
import SignUp from "./SignUp";
import ConfirmEmail from "./app/ConfirmEmail";
import OnboardingPages from "./app/Onboarding";
import PeoplePages from "./app/People";
import MainPage from "./app/MainPage";
import CompanyPages from "./app/Company";
import HMRC from "./app/HMRC & Compliance/HMRC";
import RunPayroll from "./app/Payroll/RunPayroll";
import ErrorPages from "./Errors";
import SettingsPages from "./app/Settings";
import ReportsPage from "./app/Reports";
import PayrollPages from "./app/Payroll";
import ViewPayroll from "./app/Payroll/ViewPayroll";
import AEPages from "./app/Auto Enrolment";
import Tutorials from "./app/Tutorials/Tutorials";
import CloseTaxYear from "./app/CloseTaxYear";
import AutoEnrolment from "./AutoEnrolment";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const Pages = {
	AutoEnrolment,
	Login,
	SignUp,
	ConfirmEmail,
	PeoplePages,
	OnboardingPages,
	MainPage,
	CompanyPages,
	HMRC,
	AEPages,
	RunPayroll,
	ErrorPages,
	SettingsPages,
	ReportsPage,
	PayrollPages,
	ViewPayroll,
	Tutorials,
	CloseTaxYear,
	ForgotPassword,
	ResetPassword,
};

export default Pages;
