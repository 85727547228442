import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import copy from '../../../images/svg/copy.svg';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import {useGetDirectors} from "../../../hooks/signup";

const SelectDirector = ({
	step = 0,
	setStep = () => {},
	director = '',
	setDirector = () => {},
	company = {
		company_number: '',
	},
}): JSX.Element => {

	const [directors, setDirectors] = useState([]);

	const { mutateAsync: getDirectors, isPending } = useGetDirectors(setDirectors);

	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const handleOpen = () => {
		setOpen(true);
		setDirector('');
	};

	useEffect(() => {
		getDirectors({
			employers: {
				company_number: company['company_number'],
			},
		});
	}, [company, getDirectors]);

	return (
		<Grid
			id={'company-details-content'}
			container
			justifyContent={'flex-start'}
			alignItems={'flex-start'}
			direction={'column'}
		>
			<Typography className={'typography-black-header'}>
				Which Director or Authorised Signatory is signing up on behalf of the
				company?
			</Typography>

			{isPending ? (
				<CircularProgress className={'circular-progress'} />
			) : (
				<>
					{directors.length === 0 && (
						<Typography className={'typography-p'}>
							No directors found. Enter director manually.
						</Typography>
					)}
					<RadioGroup
						id={'directors-radio-group'}
						defaultValue={null}
						onChange={(e) => setDirector(e.target.value)}
						value={director}
					>
						{directors.map((d) => (
							<FormControlLabel
								key={`${d.name}`}
								className={'director'}
								control={
									<Radio
										icon={<CheckBoxOutlineBlank />}
										checkedIcon={<CheckBox />}
									/>
								}
								label={d.name}
								value={d.name}
							/>
						))}
					</RadioGroup>

					<Typography
						className={'enter-details-manually'}
						onClick={handleOpen}
					>
						<img
							src={copy}
							alt={'none of the above'}
						/>
						{directors.length === 0
							? 'Insert director manually'
							: 'None of the above'}
					</Typography>

					<Modal
						open={open}
						onClose={handleClose}
					>
						<Box id={'director-modal'}>
							<Grid
								container
								justifyContent={'center'}
								alignItems={'center'}
								direction={'column'}
							>
								<Typography className={'director-modal-title'}>
									Authorised Signatory
								</Typography>

								<Typography className={'director-modal-subtitle'}>
									Please confirm your role in the company
								</Typography>

								<Grid
									container
									id={'director-modal-form'}
								>
									<InputLabel className={'default-input-label'}>
										Full Name
									</InputLabel>
									<TextField
										className={'default-text-field'}
										fullWidth
										required
										value={director}
										onChange={(e) => setDirector(e.target.value)}
									/>
								</Grid>

								<Button
									className={'default-black-button'}
									fullWidth
									onClick={() => setStep(step + 1)}
									disabled={!director}
								>
									Save
								</Button>
							</Grid>
						</Box>
					</Modal>

					<Button
						className={'default-black-button'}
						id={'company-details-continue-button'}
						disabled={!director}
						onClick={() => setStep(step + 1)}
					>
						Continue
					</Button>
				</>
			)}
		</Grid>
	);
};

export default SelectDirector;