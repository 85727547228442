import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Chip} from "@mui/material";
import {formatDateFromBackendWithTime} from "../../../utils/Helpers";
import {FreepayrollDropbox, Icon, Typography} from "@collegia-partners/ui-kit";
import FPTable from "../../../components/common/FPTable/FPTable";
import {useListPayrolls} from "../../../hooks/payrolls";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";

function renderPayRunStatusColor(status) {
	switch (status) {
		case "Submitted":
			return {
				backgroundColor: "#0360FD",
				color: "#FFFFFF"
			};
		default:
			return {
				backgroundColor: "#C4C4C4",
				color: "#FFFFFF"
			};
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollArchivePage = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();
	
	const push = useNavigate();

	const columns = [
		{
			text: "Schedule Name",
			key: "name",
			width: '1.5fr',
			hasOrderBy: true,
		},
		{
			text: "Tax Period",
			key: "tax_period",
			hasOrderBy: true,
		},
		{
			text: "Pay Date",
			key: "period_end_date",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{formatDateFromBackendWithTime(row.period_end_date)}
				</Typography>
			),

		},
		{
			text: "Status",
			key: "status",
			customRender: (row, index) => (
				<Chip key={index} color={renderPayRunStatusColor(row.status)} >
					{row.status}
				</Chip>
			),
		},
		{
			text: "Actions",
			width: '1.5fr',
			customRender: (row, index) => (
				<FreepayrollDropbox
					target={
						<button
							style={{all: "unset", cursor: "pointer"}}
						>
							<Icon
								iconName="dots-horizontal"
								className={"EmployeeSummaryIcon"}
							/>
						</button>
					}
					key={index}
					className={"EmployeeSummaryActions"}
				>
					<button
						onClick={() => push(`/main/view-payroll/${btoa(row.id)}`)}
						className={"EmployeeSummaryAction"}
					>
						View Payroll
					</button>
				</FreepayrollDropbox>
			),
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Payroll',
			activeSubpage: 'Archived',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
			<div
				style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					Payroll Archive
				</Typography>
			</div>
			<div>
				<FPTable
					headers={columns}
					hookName={useListPayrolls}
					hookKey={'payrolls'}
					hookParams={{
						mode: 'archive',
					}}
					paginate={true}
				/>
			</div>
		</div>
	);
};

export default PayrollArchivePage;