import {
	useMutation,
	useQuery, useQueryClient,
} from '@tanstack/react-query';
import Request from "../utils/Request";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

export function useMe() {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['me'],
		queryFn: async () => {
			return await Request.get('/api/employers/users/me')
				.then(response => response.data)
		},
		keepPreviousData: false,
	});

	return {
		profileData: data,
		isPending,
		isError,
	};
}

export function useConfirmEmail() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/users/confirm-email`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {

			if (data?.user?.temporary_password) {
				window.location.href = '/main/settings/edit-password';
			} else {
				window.location.href = '/onboarding/dashboard';
			}
		},
	});
}

export function useResendEmail() {
	return useMutation({
		mutationFn: async () => {
			return await Request.get(`/api/employers/users/send-email-confirmation`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success("Email resent successfully");
		},
	});
}

export function useUpdateEmail() {
	
	const navigate = useNavigate();
	
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/users/update-email`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			toast.success("Email updated successfully");
			navigate('/main/settings/edit-info');
		},
	});
}

export function useUpdatePassword() {
	
	const navigate = useNavigate();
	
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/users/update-password`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			toast.success("Password updated successfully");
			navigate('/main/settings/edit-info');
		},
	});
}

export function useToggle2FA() {

	const navigate = useNavigate();

	return useMutation({
		mutationFn: async () => {
			return await Request.put('/api/employers/users/toggle-2fa')
				.then(response => {
					return response.data;
				})
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			navigate('/main/settings/confirm-2fa');
			toast.success("Code sent successfully");
		}
	});
}

export function useVerify2FA() {

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/users/verify-2fa', data)
				.then(response => {
					return response.data;
				})
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['me'] });
			navigate('/main/settings/edit-2fa');
			toast.success("2FA enabled successfully");
		}
	});
}

export function useResend2FA() {
	return useMutation({
		mutationFn: async () => {
			return await Request.post('/api/employers/users/resend-2fa')
				.then(response => response.data)
		},
		onError: (error) => {
			toast.error(error.message);
		},
		onSuccess: () => {
			toast.success("Code sent successfully");
		}
	});
}

export function useCloseAccount() {
	
	const navigate = useNavigate();
	
	return useMutation({
		mutationFn: async () => {
			return await Request.post(`/api/employers/users/close-account`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data, variables) => {
			toast.success("Account closed successfully");
			localStorage.clear();
			navigate('/login');
			return true;
		},
	});
}

export function useGetUsers({ page, orderBy, orderDir }) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['users', { page, orderBy, orderDir }],
		queryFn: async () => {
			return await Request.get('/api/employers/users/get',{ params: { page, orderBy, orderDir } })
				.then(response => response.data)
		},
		keepPreviousData: false,
		staleTime: 1000 * 60 * 60,
	});

	return {
		users: data?.users,
		isPending,
		isError,
	};
}

export function useAddUser() {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/users/add`, data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data, variables) => {
			toast.success("User added successfully");
			queryClient.invalidateQueries({ queryKey: ['users'] });
			return true;
		},
	});
}

export function useLogin(setStep, setAccounts) {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/users/login', data)
				.then(response => response.data)
		},
		onError: (error) => {
			console.error(error);
		},
		onSuccess: (data) => {
			if (data?.two_fa_enabled) {
				setStep(1);
				localStorage.clear();
				localStorage.setItem('fpEmployerToken', data.token);
			} else if (data?.has_multiple_employers) {
				setAccounts(data?.accounts);
			} else {
				localStorage.clear();
				localStorage.setItem('fpEmployerToken', data.token);
				window.location.href = "/main";
			}
		},
	});
}

export function useLoginTwoFactorAuth(setAccounts) {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/users/login-2fa', data)
				.then(response => response.data)
		},
		onError: (error) => {
			console.error(error);
		},
		onSuccess: (data) => {
			if (data?.has_multiple_employers) {
				setAccounts(data?.accounts);
			} else {
				localStorage.clear();
				localStorage.setItem('fpEmployerToken', data.token);
				window.location.href = "/main";
			}
		},
	});
}

export function useSelectAccount() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/users/select-account', data)
				.then(response => response.data)
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			localStorage.clear();
			localStorage.setItem('fpEmployerToken', data.token);
			window.location.href = "/main"
		},
	});
}

