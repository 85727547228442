import {
	keepPreviousData,
	useMutation, useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import Request from "../../utils/Request";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";

export function useCreatePayrollTemplate() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/journal-template/create', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.setQueryData(['payroll-template'], data?.data);
		},
	});
}

export function useGetPayrollTemplate() {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['payroll-template'],
		queryFn: async () => {
			return await Request.get(`/api/employers/journal-template/get`)
				.then(response => response?.data?.data);
		},
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // 5 minutes
	});

	return {
		regular_template: data?.data,
		exist_template: data?.exist_template,
		isPending,
		isError,
	};
}

export function useDownloadJournal() {
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post("api/employers/journal-template/download-journal", data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const worksheet = XLSX.utils.json_to_sheet(data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

			FileDownload(
				excelFileContent,
				`payroll-journal.xlsx`
			);
		},
	});
}