import Axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {toast} from "sonner";

export function useManagePayItem() {
	
	const options = {
		baseURL: process.env.REACT_APP_FP_GOLANG_API,
		withCredentials: false,
		timeout: 960000,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
		}
	};
	
	// creating the axios instance.
	let managePayItemRequest = Axios.create(options);
	
	return useMutation({
		mutationFn: async (data) => {
			return await managePayItemRequest.post(`/manage-single-pay-item`, data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success("Payslip updated successfully");
			return data;
		},
	});
}