import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { Divider, Tab, Tabs } from "@mui/material";
import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import Loading from "../../../components/Loading";
import StyleButton from "../../../components/StyledComponents/StyleButton";
import { useParams } from "react-router-dom";
import { EmploymentDetails } from "../../../components/Pages/AddSingleEmployee/EmploymentDetails/EmploymentDetails";
import { PersonalDetails } from "../../../components/Pages/AddSingleEmployee/PersonalDetails/PersonalDetails";
import { StarterDetails } from "../../../components/Pages/AddSingleEmployee/StarterDetails/StarterDetails";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useAddEmployee, useCompleteEmployeeSetup, useGetNiCategories} from "../../../hooks/employees";
import {useGetEmployee} from "../../../hooks/employee/useGetEmployee";
import {useGetEmployer} from "../../../hooks/employer";

function _customBackButton(tabIndex, setTabIndex) {
	if (tabIndex > 0) {
		return setTabIndex(tabIndex - 1);
	}
}

function _renderTabs(
	tabIndex,
	employeeInformation,
	setEmployeeInformation,
	ni_categories,
	employer,
	nicCategoryValues,
	setNicCategoryValues
) {
	switch (tabIndex) {
		case 0:
			return (
				<PersonalDetails
					employeeInformation={employeeInformation}
					setEmployeeInformation={setEmployeeInformation}
					ni_categories={ni_categories}
				/>
			)
		case 1:
			return (
				<EmploymentDetails
					employeeInformation={employeeInformation}
					setEmployeeInformation={setEmployeeInformation}
					employer={employer}
				/>
			)
		case 2:
			return (
				<StarterDetails
					employeeInformation={employeeInformation}
					setEmployeeInformation={setEmployeeInformation}
					ni_categories={ni_categories}
					nicCategoryValues={nicCategoryValues}
					setNicCategoryValues={setNicCategoryValues}
				/>
			)
		default:
			return "";
	}
}

function checkDisable(activeStep, employeeInformation, nicCategoryValues) {

	const regexTaxCode = /^(?:[CS])?(([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5}))$/;
	const ninValidator = /^([ACEHJLMOPRSWXY][A-CEGHJ-NPR-TW-Z]|B[A-CEHJ-NPR-TW-Z]|G[ACEGHJ-NPR-TW-Z]|[KT][A-CEGHJ-MPR-TW-Z]|N[A-CEGHJL-NPR-SW-Z]|Z[A-CEGHJ-NPR-TW-Y])[0-9]{6}[A-D ]$/;

	//Check if the user has filled in all the required fields for Personal Details
	if (activeStep === 0) {
		if (
			employeeInformation.title === ' ' ||
			employeeInformation.forename === '' ||
			employeeInformation.surname === '' ||
			employeeInformation.email === '' ||
			employeeInformation?.payroll_id?.includes('#') ||
			employeeInformation.gender === ' ' ||
			employeeInformation.birthdate === null ||
			employeeInformation.birthdate === '' ||
			employeeInformation.birthdate_error ||
			employeeInformation.ni_category === '' ||
			employeeInformation.ni_category === null ||
			employeeInformation.address.address_line_1 === '' ||
			employeeInformation.address?.address_line_1 === null ||
			employeeInformation.address.city === '' ||
			employeeInformation.address.postal_code === '' ||
			employeeInformation.address.country === '' ||
			employeeInformation.duplicatedEmployee ||
			(
				(employeeInformation.national_insurance_number !== null && employeeInformation.national_insurance_number !== '') &&
				!ninValidator.test(employeeInformation.national_insurance_number)
			)
		) {
			return true;
		}
	}
	else if (activeStep === 1) {
		if (
			employeeInformation.payroll_id === '' ||
			(employeeInformation?.join_date === null || employeeInformation?.join_date === "") ||
			employeeInformation.pay_schedule?.id === undefined ||
			employeeInformation.salary?.employment_type === undefined ||
			employeeInformation.duplicatedEmployee ||
			(
				employeeInformation.salary?.employment_type === 'salaried' &&
				(
					(employeeInformation.salary?.annual_salary === '' || employeeInformation.salary?.annual_salary === undefined) ||
					(employeeInformation.salary?.weekly_salary === '' || employeeInformation.salary?.weekly_salary === undefined) ||
					(employeeInformation.salary?.monthly_salary === '' || employeeInformation.salary?.monthly_salary === undefined) ||
					(employeeInformation.salary?.expected_work_hours_per_week === '' || employeeInformation.salary?.expected_work_hours_per_week === undefined)
				)
			) ||
			(
				employeeInformation.salary?.employment_type === 'hourly' &&
				(
					(employeeInformation.salary?.hourly_salary === '' || employeeInformation.salary?.hourly_salary === undefined) ||
					(employeeInformation.salary?.expected_work_hours_per_week === '' || employeeInformation.salary?.expected_work_hours_per_week === undefined)
				)
			) ||
			(
				employeeInformation.is_director === true &&
				(
					employeeInformation.active_director_detail?.start_date === undefined ||
					employeeInformation.active_director_detail?.cumulative_calculation === undefined
				)
			) || employeeInformation.active_director_detail?.start_date_error ||
			employeeInformation.active_director_detail?.end_date_error
		) {
			return true;
		}
	}
	else {
		if (
			employeeInformation?.starter_type === '' ||
			(employeeInformation?.tax_code === '' || employeeInformation?.tax_code === null) ||
			(employeeInformation?.is_cumulative === '' || employeeInformation?.is_cumulative === null) ||
			!regexTaxCode.test(employeeInformation?.tax_code) ||
			(
				employeeInformation?.starter_type === 'new_with_p45' &&
				(
					employeeInformation?.ytd_figure?.previous_employment_tax_deducted === '' ||
					employeeInformation?.ytd_figure?.previous_employment_taxable_pay === ''
				)
			) ||
			(
				employeeInformation?.starter_type === 'new_without_p45' && employeeInformation?.starter_declaration === null
			) || (
				employeeInformation?.starter_type === 'existing_employee' &&
				(
					employeeInformation?.ytd_figure?.gross_for_tax_ytd === '' ||
					employeeInformation?.ytd_figure?.tax_deducted_ytd === '' ||
					nicCategoryValues.find((item) =>
						item.national_insurance_category === ' ' ||
						item.gross_pay_for_nic_ytd === '' ||
						item.earnings_at_lel_ytd === '' ||
						item.earnings_to_pt_ytd === '' ||
						item.earnings_to_uel_ytd === '' ||
						item.employee_nic_ytd === '' ||
						item.employer_nic_ytd === ''
					) !== undefined
				)
			)
		) {
			return true;
		}

	}

	return false;
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AddSingleEmployeeMain = ({
	isOnboarding,
}): JSX.Element => {
	
	let employeeId = useParams().employeeId;
	const { setLayoutProps } = useLoggedLayout();
	
	if (!employeeId) {
		employeeId = null;
	}
	const { employee, isPending: isLoadingEmployee } = useGetEmployee({
		id: employeeId,
		relations: ["address", "pay_schedule", "salary", "active_director_detail", "ytd_figure", "active_ni_categories_ytd"]
	});
	
	const { mutate: completeEmployeeSetup, isPending: isCompleting } = useCompleteEmployeeSetup();
	const { mutate: addEmployee, isPending: isAdding } = useAddEmployee();
	
	const { niCategories } = useGetNiCategories();
	
	const { employer } = useGetEmployer({ relations: ['pay_schedules'] })

	/*
	* This page serves as the main page for adding a single employee
	* or finishing the setup of an employee
	 */

	const handleContinueButton = () => {
		if (tabIndex < 2) {
			window.scrollTo(0, 0);  // Scrolls the window to the top
			setTabIndex((prevActiveStep) => prevActiveStep + 1);
		}
	};
	const handleSaveAndFinishLaterButton = () => {
		switch (tabIndex) {
			case 0:
				addEmployee({
					data: {
						employees: {
							step: 'personal_information',
							employee: {
								forename: employeeInformation.forename,
								surname: employeeInformation.surname,
								email: employeeInformation.email,
								telephone: employeeInformation.telephone,
								gender: employeeInformation.gender,
								birthdate: employeeInformation.birthdate,
								title: employeeInformation.title,
								national_insurance_number: employeeInformation.national_insurance_number,
								ni_category: employeeInformation.ni_category,
								first_payroll_run: false,
								status: "Pending Information",
								setup_step_one: true,
							},
							address: {
								...employeeInformation.address
							},
						}
					},
					isOnboarding: isOnboarding
				});
				break;
			case 1:
				addEmployee({
					data: {
						employees: {
							step: 'employment_information',
							employee: {
								forename: employeeInformation.forename,
								surname: employeeInformation.surname,
								email: employeeInformation.email,
								telephone: employeeInformation.telephone,
								gender: employeeInformation.gender,
								birthdate: employeeInformation.birthdate,
								title: employeeInformation.title,
								national_insurance_number: employeeInformation.national_insurance_number,
								ni_category: employeeInformation.ni_category,
								payroll_id: employeeInformation.payroll_id,
								join_date: employeeInformation.join_date,
								is_director: employeeInformation.is_director,
								first_payroll_run: false,
								status: "Pending Information",
								setup_step_one: true,
								setup_step_two: true,
							},
							address: {
								...employeeInformation.address
							},
							pay_schedule: {
								...employeeInformation.pay_schedule
							},
							employee_salary: {
								...employeeInformation.salary
							},
							director_details: {
								...employeeInformation.active_director_detail
							},
						},
					},
					isOnboarding: isOnboarding
				});
				break;
			case 2:
				addEmployee({
					data: {
						employees: {
							step: 'starter_type',
							employee: {
								forename: employeeInformation.forename,
								surname: employeeInformation.surname,
								email: employeeInformation.email,
								telephone: employeeInformation.telephone,
								gender: employeeInformation.gender,
								birthdate: employeeInformation.birthdate,
								title: employeeInformation.title,
								national_insurance_number: employeeInformation.national_insurance_number,
								ni_category: employeeInformation.ni_category,
								payroll_id: employeeInformation.payroll_id,
								join_date: employeeInformation.join_date,
								is_director: employeeInformation.is_director,
								tax_code: employeeInformation.tax_code,
								starter_type: employeeInformation.starter_type,
								starter_declaration: employeeInformation.starter_declaration,
								is_cumulative: employeeInformation.is_cumulative,
								first_payroll_run: employeeInformation.starter_type !== 'existing_employee',
								status: "Active",
								active: true,
								setup_step_one: true,
								setup_step_two: true,
								setup_step_three: true,
							},
							address: {
								...employeeInformation.address
							},
							pay_schedule: {
								...employeeInformation.pay_schedule
							},
							employee_salary: {
								...employeeInformation.salary
							},
							director_details: {
								...employeeInformation.active_director_detail
							},
							ytd_figure: {
								...employeeInformation.ytd_figure
							},
							duplicatedEmployee: false,
							employee_ni_categories: nicCategoryValues,
						}
						
					},
					isOnboarding: isOnboarding
				});
				break;
			default:
				break;
		}
	};
	const handleSaveButton = () => {
		if (tabIndex === 2) {
			completeEmployeeSetup({
				'employees': {
					'safe_id': employeeInformation.safe_id,
					'employee_data': {
						title: employeeInformation.title,
						forename: employeeInformation.forename,
						surname: employeeInformation.surname,
						gender: employeeInformation.gender,
						birthdate: employeeInformation.birthdate,
						email: employeeInformation.email,
						national_insurance_number: employeeInformation.national_insurance_number,
						telephone: employeeInformation.telephone,
						tax_code: employeeInformation.tax_code,
						is_cumulative: employeeInformation.is_cumulative,
						ni_category: employeeInformation.ni_category,
						payroll_id: employeeInformation.payroll_id,
						is_director: employeeInformation.is_director,
						join_date: employeeInformation.join_date,
						starter_type: employeeInformation.starter_type,
						starter_declaration: employeeInformation.starter_declaration,
						setup_step_one: true,
						setup_step_two: true,
						setup_step_three: true,
						status: 'Active',
						active: true,
						first_payroll_run: employeeInformation.starter_type !== 'existing_employee',
					},
					'employee_address': {
						address_line_1: employeeInformation.address?.address_line_1,
						address_line_2: employeeInformation.address?.address_line_2,
						city: employeeInformation.address?.city,
						country: employeeInformation.address?.country,
						postal_code: employeeInformation.address?.postal_code,
					},
					'employee_salary': {
						employment_type: employeeInformation.salary?.employment_type,
						annual_salary: employeeInformation.salary?.annual_salary,
						monthly_salary: employeeInformation.salary?.monthly_salary,
						weekly_salary: employeeInformation.salary?.weekly_salary,
						hourly_salary: employeeInformation.salary?.hourly_salary,
						expected_work_hours_per_week: employeeInformation.salary?.expected_work_hours_per_week,
					},
					'active_director_detail': {
						start_date: employeeInformation.active_director_detail?.start_date,
						end_date: employeeInformation.active_director_detail?.end_date,
						cumulative_calculation: employeeInformation.active_director_detail?.cumulative_calculation,
					},
					'pay_schedule': {
						id: employeeInformation.pay_schedule?.id,
					},
					'employee_ytd_figure': {
						previous_employment_tax_deducted: employeeInformation.ytd_figure?.previous_employment_tax_deducted,
						previous_employment_taxable_pay: employeeInformation.ytd_figure?.previous_employment_taxable_pay,
						gross_for_tax_ytd: employeeInformation.ytd_figure?.gross_for_tax_ytd,
						tax_deducted_ytd: employeeInformation.ytd_figure?.tax_deducted_ytd,
						student_loan_ytd: employeeInformation.ytd_figure?.student_loan_ytd,
						pg_loan_ytd: employeeInformation.ytd_figure?.pg_loan_ytd,
						smp_ytd: employeeInformation.ytd_figure?.smp_ytd,
						spp_ytd: employeeInformation.ytd_figure?.spp_ytd,
						sap_ytd: employeeInformation.ytd_figure?.sap_ytd,
						shpp_ytd: employeeInformation.ytd_figure?.shpp_ytd,
						spbp_ytd: employeeInformation.ytd_figure?.spbp_ytd,
						ssp_ytd: employeeInformation.ytd_figure?.ssp_ytd,
						employee_pension_ytd: employeeInformation.ytd_figure?.employee_pension_ytd,
						bik_payrolled_amount_ytd: employeeInformation.ytd_figure?.bik_payrolled_amount_ytd,
					},
					'employee_ni_categories': nicCategoryValues,
				}
			});
		}
	}

	const [tabIndex, setTabIndex] = useState(0);
	const [employeeInformation, setEmployeeInformation] = useState({
		forename: "",
		surname: "",
		email: "",
		telephone: "",
		gender: " ",
		birthdate: null,
		birthdate_error: false,
		title: " ",
		national_insurance_number: "",
		ni_category: "",
		tax_code: "",
		is_cumulative: false,
		starter_type: "",
		starter_declaration: "",
		join_date: null,
		is_director: false,
		payroll_id: "",
		duplicatedEmployee: false,
		address: {
			postal_code: "",
			address_line_1: "",
			address_line_2: "",
			city: "",
			country: "",
		},
		salary: {
			employment_type: undefined,
			annual_salary: undefined,
			monthly_salary: undefined,
			weekly_salary: undefined,
			hourly_salary: undefined,
			expected_work_hours_per_week: undefined,
		},
		active_director_detail: {
			start_date: undefined,
			start_date_error: false,
			end_date: undefined,
			end_date_error: false,
			cumulative_calculation: undefined,
		},
		pay_schedule: {
			id: undefined,
		},
		ytd_figure: {
			previous_employment_tax_deducted: 0,
			previous_employment_taxable_pay: 0,
			gross_for_tax_ytd: 0,
			tax_deducted_ytd: 0,
			student_loan_ytd: 0,
			pg_loan_ytd: 0,
			smp_ytd: 0,
			spp_ytd: 0,
			sap_ytd: 0,
			shpp_ytd: 0,
			spbp_ytd: 0,
			ssp_ytd: 0,
			employee_pension_ytd: 0,
			bik_payrolled_amount_ytd: 0,
		},
	});
	const [nicCategoryValues, setNicCategoryValues] = useState([
		{
			national_insurance_category: " ",
			gross_pay_for_nic_ytd: "",
			earnings_at_lel_ytd: "",
			earnings_to_pt_ytd: "",
			earnings_to_uel_ytd: "",
			employee_nic_ytd: "",
			employer_nic_ytd: "",
			directorDeclaration: " ",
			director_earnings_ytd: 0,
			director_at_lel_ytd: 0,
			director_to_pt_ytd: 0,
			director_to_uel_ytd: 0,
			director_nic_ytd: 0,
			director_employer_nic_ytd: 0,
		}
	]);

	useEffect(() => {
		if (employee?.id && employeeId) {
			setEmployeeInformation(employee);
			if (employee.active_ni_categories_ytd.length > 0) {
				setNicCategoryValues(employee?.active_ni_categories_ytd)
			} else {
				setNicCategoryValues([
					{
						national_insurance_category: employee.ni_category,
						gross_pay_for_nic_ytd: "",
						earnings_at_lel_ytd: "",
						earnings_to_pt_ytd: "",
						earnings_to_uel_ytd: "",
						employee_nic_ytd: "",
						employer_nic_ytd: "",
						directorDeclaration: " ",
						director_earnings_ytd: 0,
						director_at_lel_ytd: 0,
						director_to_pt_ytd: 0,
						director_to_uel_ytd: 0,
						director_nic_ytd: 0,
						director_employer_nic_ytd: 0,
					}
				])
			}

			if (!employee.setup_step_one) {
				setTabIndex(0);
			} else if (!employee.setup_step_two) {
				setTabIndex(1);
			} else if (!employee.setup_step_three) {
				setTabIndex(2);
			}
		}
	}, [employee, employeeId]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'People',
			showBackButton: true,
			custom: true,
			customOnClick: () => _customBackButton(tabIndex, setTabIndex),
		});
	}, [setLayoutProps, tabIndex]);

	if (isCompleting || isLoadingEmployee || isAdding) {
		return (
			<Loading />
		);
	}

	return (
		<Grid
			id={'add-single-employee-page'}
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={27}
						fontWeight={"bold"}
					>
						Adding a new employee
					</StyleTypography>
				</Grid>
				<Grid
					className={"sub-title-container"}
				>
					<StyleTypography
						fontSize={18}
						fontSizeMedium={14}
						color={"#ABAEB7"}
					>
						Follow the three steps below to add a new employee to your company payroll. <br />
						Please make sure all information is accurate.
					</StyleTypography>
				</Grid>
				<Divider />
			</Grid>
			<Grid
				id={"tab-container"}
				container
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
				>
					<Tabs variant="fullWidth" className={"tabs-basic-setup"} value={tabIndex}>
						<Tab
							sx={{ borderBottom: tabIndex > 0 ? "5px solid #0160FD" : "5px solid transparent" }}
							label={"Personal and Contact details"}
							value={0}
						/>
						<Tab
							sx={{ borderBottom: tabIndex > 1 ? "5px solid #0160FD" : "5px solid transparent" }}
							label={"Employment details"}
							value={1}
						/>
						<Tab
							sx={{ borderBottom: tabIndex > 2 ? "5px solid #0160FD" : "5px solid transparent" }}
							label={"Starter details"}
							value={2}
						/>
					</Tabs>
				</Grid>
				{
					_renderTabs(
						tabIndex,
						employeeInformation,
						setEmployeeInformation,
						niCategories,
						employer,
						nicCategoryValues,
						setNicCategoryValues
					)
				}
				<Grid
					container
					paddingTop={4}
					paddingBottom={'250px'}
				>
					{/*Save and Finish Later button only shows when
								adding employee for first time*/}
					<Grid
						item
						hidden={employeeId}
					>
						<StyleButton
							disabled={checkDisable(tabIndex, employeeInformation, nicCategoryValues)}
							onClick={() => handleSaveAndFinishLaterButton()}
							isWhiteButton={true}
						>
							{
								tabIndex === 2 ? "Save" : "Save and Finish Later"
							}
						</StyleButton>
					</Grid>

					{/*Save Button only show if you are finishing the setup of an employee,
								that was added and not finished*/}
					<Grid
						xs={2}
						item
						hidden={!employeeId || tabIndex !== 2}
					>
						<StyleButton
							disabled={checkDisable(tabIndex, employeeInformation, nicCategoryValues)}
							onClick={() => handleSaveButton()}
						>
							Save
						</StyleButton>
					</Grid>

					{/*Continue button shows on all pages, excetp when tab = 2*/}
					<Grid
						xs={2}
						item
						hidden={tabIndex === 2}
						marginLeft={2}
					>
						<StyleButton
							disabled={checkDisable(tabIndex, employeeInformation, nicCategoryValues)}
							onClick={() => handleContinueButton()}
						>
							Continue
						</StyleButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default AddSingleEmployeeMain;