import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	formatDateFromBackendWithTime,
} from "../../../utils/Helpers";
import {FreepayrollDropbox, Icon, Typography, Chip, FreepayrollButton} from "@collegia-partners/ui-kit";
import FPTable from "../../../components/common/FPTable/FPTable";
import {
	useDownloadGrossToNetReport,
	useListPayrolls
} from "../../../hooks/payrolls";
import {renderPayRunStatusColor} from "./PayrollActive";
import {ReopenPayRun} from "../../../components/Pages/ReopenPayrun";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useGetEmployer} from "../../../hooks/employer";
import {useEmailAllPayslips} from "../../../hooks/payroll/useEmailAllPayslips";
import {useDownloadAllPayslips} from "../../../hooks/payroll/useDownloadAllPayslips"
import {useDownloadJournal} from "../../../hooks/reports/payroll-journal";
import {useListPaySchedules} from "../../../hooks/pay-schedules";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollHistoryPage = (): JSX.Element => {
	
	const { employer } = useGetEmployer({ relations: [] });
	const { paySchedules } = useListPaySchedules({ page: 1, perPage: 20 });

	const {
		mutate: downloadGrossToNetReport,
		isPending: isDownloading
	} = useDownloadGrossToNetReport();
	
	const {
		mutate: emailAllPayslips,
		isPending: isEmailing
	} = useEmailAllPayslips();
	
	const {
		mutate: downloadAllPayslips,
		isPending: isDownloadingPayslips
	} = useDownloadAllPayslips();

	const {
		mutate: downloadJournal,
		isPending: isDownloadingJournal
	} = useDownloadJournal();

	const push = useNavigate();
	const {setLayoutProps} = useLoggedLayout();

	const [openModal, setOpenModal] = useState(false),
		[payrollId, setPayrollId] = useState(null);

	const columns = [
		{
			text: "Schedule Name",
			key: "name",
			width: '1.5fr',
			filter: true,
			filterOptions: paySchedules?.data?.map((schedule) => ({ value: schedule.name })),
		},
		{
			text: "Tax Period",
			key: "tax_period",
			hasOrderBy: true,
		},
		{
			text: "Pay Date",
			key: "period_end_date",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{formatDateFromBackendWithTime(row.period_end_date)}
				</Typography>
			),

		},
		{
			text: "Status",
			key: "status",
			customRender: (row, index) => (
				<Chip key={index} color={renderPayRunStatusColor(row.status)} >
					{row.status}
				</Chip>
			),
		},
		{
			text: "Actions",
			width: '1.5fr',
			customRender: (row, index) => (
				<div style={{ display: "flex", flexDirection: "row", gap: "3.8vw" }}>
					<div style={{width: "fit-content" }}>
						<FreepayrollButton
							onClick={() => {
								setPayrollId(row.id);
								setOpenModal(true);
							}}
							size={"small"}
							variant={"red"}
							height={0.833}
							disabled={employer?.account_locked || !row.can_be_rolled_back}
						>
							Reopen Payroll
						</FreepayrollButton>
					</div>
					<FreepayrollDropbox
						target={
							<button
								style={{all: "unset", cursor: "pointer"}}
							>
								<Icon
									iconName="dots-horizontal"
									className={"EmployeeSummaryIcon"}
								/>
							</button>
						}
						key={index}
						className={"EmployeeSummaryActions"}
					>
						<button
							onClick={() => push(`/main/view-payroll/${btoa(row.id)}`)}
							disabled={isDownloading || isEmailing}
							className={"EmployeeSummaryAction"}
						>
							View Payroll
						</button>
						<button
							onClick={() => {
								emailAllPayslips(row.id);
							}}
							disabled={isDownloading || isEmailing || isDownloadingJournal}
							className={"EmployeeSummaryAction"}
						>
							{isEmailing ? "Sending..." : "Email all payslips"}
						</button>
						<button
							onClick={() => {
								downloadAllPayslips(row.id);
							}}
							disabled={isDownloading || isEmailing || isDownloadingJournal}
							className={"EmployeeSummaryAction"}
						>
							{isDownloadingPayslips ? "Downloading..." : "Download all payslips"}
						</button>
						<button
							onClick={() => {
								downloadGrossToNetReport(row.id);
							}}
							disabled={isDownloading || isEmailing || isDownloadingJournal}
							className={"EmployeeSummaryAction"}
						>
							{isDownloading ? "Downloading..." : "Download Gross to Net Report"}
						</button>
						<button
							onClick={() => {
								downloadJournal({
									"journal_template": {
										'employer_pay_schedule_run_id': row?.id,
									}
								});
							}}
							disabled={isDownloading || isEmailing || isDownloadingJournal}
							className={"EmployeeSummaryAction"}
						>
							{isDownloadingJournal ? "Downloading..." : "Download Journal"}
						</button>
					</FreepayrollDropbox>
				</div>
			),
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Payroll',
			activeSubpage: 'History',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div>
			<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
				<div
					style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Submitted Payrolls
					</Typography>
				</div>
				<div>
					<FPTable
						headers={columns}
						hookName={useListPayrolls}
						hookKey={'payrolls'}
						hookParams={{
							mode: 'history',
						}}
						paginate={true}
					/>
				</div>
			</div>
			<ReopenPayRun
				open={openModal}
				setOpen={setOpenModal}
				payrollId={payrollId}
				setPayrollId={setPayrollId}
			/>
		</div>
	);
};

export default PayrollHistoryPage;
