import Grid from '@mui/material/Grid';
import { useState } from 'react';
import {SignUpCard} from '../components/Pages/SignUp/SignUpCard';
import {CreateAccountCard} from '../components/Pages/SignUp/CreateAccountCard';
import Loading from '../components/Loading';
import {useCreateLead, useCreateUser} from "../hooks/signup";

const SignUp = (): JSX.Element => {

	const { isPending: isCreatingUser } = useCreateUser();
	const { isPending: isCreatingLead } = useCreateLead();

	const [step, setStep] = useState(0),
		[leadId, setLeadId] = useState(null);

	if (isCreatingUser || isCreatingLead) {
		return <Loading />;
	}

	return (
		<Grid
			id={'sign-up-image'}
			container
		>
			<Grid
				item
				md={6}
				sm={12}
				xs={12}
				lg={6}
				xl={6}
			/>
			<Grid
				id={'sign-up-container'}
				container
				justifyContent={'flex-end'}
				alignItems={'center'}
				item
				md={6}
				sm={12}
				xs={12}
				lg={6}
				xl={6}
			>
				{
					step === 0
						? <SignUpCard
							step={step}
							setStep={setStep}
							setLeadId={setLeadId}
						/>
						: <CreateAccountCard
							leadId={leadId}
						/>
				}
			</Grid>
		</Grid>
	);
};

export default SignUp;
