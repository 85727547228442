import React, {useEffect, useState} from "react";
import House from "../../../images/svg/hmrc-page/black-house.svg";
import {capitalize} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {EditModal} from "../../../components/Pages/AutomaticEnrolment/EditModal";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {Typography, Divider} from "@collegia-partners/ui-kit";
import Skeleton from "../../../components/Skeleton";
import {useGetEmployer} from "../../../hooks/employer";

function LeftSide({
  title = "",
  subtitle = "",
}) {
	return (
		<div
			className={"auto-enrolment-setting-left-container-items"}
		>
			<div>
				<Typography
					variant={"subtitle"}
					weight={"normal"}
					color={"black"}
				>
					{title}
				</Typography>
			</div>
			<div className={"auto-enrolment-setting-left-container-typography"}>
				<Typography
					variant={"subtitle"}
					weight={"bold"}
					color={"black"}
				>
					{subtitle}
				</Typography>
			</div>
		</div>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AutomaticEnrolmentSettings = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();

	const [showEditModal, setShowEditModal] = useState(false);
	const { employer, isPending } = useGetEmployer({ relations: ['pension_scheme'] });

	const employerInformation = [
		{
			title: 'Pension scheme',
			subtitle: capitalize(employer?.pension_scheme?.pension_scheme_selected ?? "N/A"),
		},
		{
			title: "Pension Type",
			subtitle: employer?.pension_scheme?.relief_at_source ? "Relief at source" : "Salary sacrifice",
		},
		{
			title: "Pension calculation",
			subtitle: employer?.pension_scheme?.pensionable_earnings ? "Pensionable earnings" : "Qualifying earnings",
		},
		{
			title: "Employee contribution",
			subtitle: `${employer?.pension_scheme?.employee_rate_percentage ?? 'N/A'} %`
		},
		{
			title: "Employer contribution",
			subtitle: `${employer?.pension_scheme?.employer_rate_percentage ?? 'N/A'} %`,
		},
	]

	useEffect(() => {
		setLayoutProps({
			activePage: 'Auto Enrolment',
			showBackButton: true,
			backLink: '/main/auto-enrolment-menu',
		});
	}, [setLayoutProps]);
	
	return (
		<div
			id={"auto-enrolment-settings"}
		>
			<div className={"auto-enrolment-page-title"}>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					Automatic Enrolment
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					Setup your workplace pension for auto enrolment
				</Typography>
			</div>
			<Divider color={"light-gray"}/>
			<div className={"auto-enrolment-settings-information-container"}>
				<div className={"auto-enrolment-settings-left-container"}>
					<div
						className={"auto-enrolment-settings-left-container-edit"}
						onClick={() => setShowEditModal(true)}
					>
						<Typography
							variant={"subtitle"}
							weight={"bold"}
							color={"black"}
							className={"auto-enrolment-settings-typography-edit"}
						>
							Edit
							<EditIcon className={"icon"}/>
						</Typography>
					</div>
					<Divider color={"light-gray"}/>
					{
						employerInformation.map((item, index) => isPending ? (
							<Skeleton height={50} width={'100%'} background={'#D6D8E1'}/>
						) : (
							<div
								key={index}
							>
								<LeftSide
									title={item.title}
									subtitle={item.subtitle}
								/>
								{
									index !== employerInformation.length - 1
										? <div className={"auto-enrolment-settings-divider"}>
											<Divider color={"light-gray"}/></div>
										: ""
								}
							</div>
						))
					}
				</div>
				<div className={"auto-enrolment-settings-right-container"}>
					<div>
						<img
							alt={"logo"}
							src={House}
						/>
					</div>
					<div>
						<Typography
							variant={"subtitle"}
							weight={"bold"}
							color={"black"}
						>
							Workplace pension
						</Typography>
						<Typography
							variant={"body"}
							weight={"normal"}
							color={"gray"}
						>
							Set up your mandatory workplace pension for automatic enrolment
						</Typography>
					</div>
				</div>
			</div>
			<EditModal
				open={showEditModal}
				setOpen={setShowEditModal}
				employer={employer}
			/>
		</div>
	);
}

export default AutomaticEnrolmentSettings;