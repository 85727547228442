import {
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import Request from "../utils/Request";

export function useUpdateApprenticeSettings() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/update-apprentice-settings/', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.setQueryData(['employer', 'active_employer_ytd_figure'], data?.data);
		},
	});
}

export function useUpdateEmploymentAllowanceSettings() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/update-employment-allowance-settings/', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.setQueryData(['employer', 'active_employer_ytd_figure'], data?.data);
		},
	});
}