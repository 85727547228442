import {StyleAppBar} from "../components/StyleAppBar";
import {LeftMenu} from "../components/LeftMenu";
import {useLoggedLayout} from "../context/LoggedLayoutContext";

function LoggedLayout({
	children,
}) {

	const {
		backLink,
		showBackButton,
		activePage,
		expanded,
		activeSubpage,
		isOnboarding,
		lockMenuItems,
		custom,
		customOnClick,
	} = useLoggedLayout();

	return (
		<div>
			<StyleAppBar
				showBackButton={showBackButton}
				backLink={backLink}
				custom={custom}
				customOnClick={customOnClick}
			/>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					paddingTop: '2.9688vw',
					paddingBottom: '2.9688vw',
					paddingLeft: '37px',
					gap: '2.9688vw',
				}}
			>
				<div>
					<LeftMenu
						activePage={activePage}
						activeSubpage={activeSubpage}
						expanded={expanded}
						isOnboarding={isOnboarding}
						lockMenuItems={lockMenuItems}
					/>
				</div>

				<div
					style={{
						paddingBottom: '2.9688vw',
						paddingRight: '37px',
						width: '100%',
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
}

export default LoggedLayout;


