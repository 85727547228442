import Grid from "@mui/material/Grid";
import React, {useCallback, useEffect, useState} from "react";
import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import House from "../../../images/svg/hmrc-page/black-house.svg";
import Plane from "../../../images/svg/hmrc-page/black-paper-plane.svg";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useGetHmrcSettings, useUpdateHmrcSettings} from "../../../hooks/hmrc-settings";
import {FreepayrollButton, FreepayrollTextField, Modal, Typography, Switch} from "@collegia-partners/ui-kit";
import {toast} from "sonner";
import Skeleton from "../../../components/Skeleton";

function Row({ index, label, value, isLoading, isLast}) {
	return (
		<div key={index} style={{width: "100%"}}>
			<div>
				<div style={{display: "flex", justifyContent: "space-between", width: "100%", padding: "1.3vw 0"}}>
					<Typography variant={"subtitle2"} color={"black"} weight={"normal"}>{label}</Typography>
					{
						isLoading ? (
							<Skeleton width={'50%'} background={'#e0e0e0'}/>
						) : (
							<Typography variant={"subtitle2"} color={"black"} weight={"bold"}>{value}</Typography>
						)
					}
				</div>
				{
					!isLast && <Divider className={"divider"}/>
				}
			</div>
		</div>
	);
}

function EditHmrcSettings({
	open,
	onClose,
	employerRecord = {},
	setEmployerRecord = () => {
	},
}) {
	
	const {mutateAsync: updateSettings, isPending: isLoading} = useUpdateHmrcSettings({
		onSuccess: () => {
			onClose();
			toast.success("HMRC settings updated");
		}
	});
	
	const onSubmit = useCallback(async () => {
		await updateSettings({
			'rti_keys': {
				'paye_reference': employerRecord?.paye_reference,
				'account_office_reference': employerRecord?.account_office_reference,
				'user': employerRecord?.user,
				'password': employerRecord?.password,
			}
		});
	},[updateSettings, employerRecord?.paye_reference, employerRecord?.account_office_reference, employerRecord?.user, employerRecord?.password]);
	
	
	const regex_office_reference = /^[0-9]{3}P[A-Z][0-9]{7}[0-9X]$/;
	const regex_paye_reference = /^\d{3}\/.*$/i;
	
	return (
		<Modal
			isOpen={open}
			onClose={onClose}
		>
			<div style={{display: "flex", flexDirection: "column", gap: "1.83vw"}}>
				<Typography
					variant={"title"}
				>
					Edit HMRC settings
				</Typography>
				
				<div style={{ display: "flex", flexDirection: "row", gap: "1.03vw" }}>
					<FreepayrollTextField
						fieldType={"text"}
						name={"paye_reference"}
						label={"PAYE reference"}
						value={employerRecord?.paye_reference}
						error={!regex_paye_reference.test(employerRecord?.paye_reference)}
						helperText={!regex_paye_reference.test(employerRecord?.paye_reference) ? "Invalid PAYE reference" : ""}
						onChange={(e) => setEmployerRecord({...employerRecord, paye_reference: e.target.value})}
					/>
					<FreepayrollTextField
						fieldType={"text"}
						name={"account_office_reference"}
						label={"Accounts office reference"}
						value={employerRecord?.account_office_reference}
						error={!regex_office_reference.test(employerRecord?.account_office_reference)}
						helperText={!regex_office_reference.test(employerRecord?.account_office_reference) ? "Invalid Accounts office reference" : ""}
						onChange={(e) => setEmployerRecord({...employerRecord, account_office_reference: e.target.value})}
					/>
				</div>
				<div style={{display: "flex", flexDirection: "row", gap: "1.03vw"}}>
					<FreepayrollTextField
						fieldType={"text"}
						name={"user"}
						label={"HMRC Gateway User ID"}
						value={employerRecord?.user}
						onChange={(e) => setEmployerRecord({...employerRecord, user: e.target.value})}
					/>
					<FreepayrollTextField
						fieldType={"password"}
						name={"password"}
						label={"HMRC Gateway Password"}
						value={employerRecord?.password}
						onChange={(e) => setEmployerRecord({...employerRecord, password: e.target.value})}
					/>
				</div>
				<div style={{display: "flex", flexDirection: "row", gap: "1.03vw"}}>
					<FreepayrollButton
						variant={"white-button"}
						onClick={onClose}
						isLoading={isLoading}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={onSubmit}
						disabled={
							!regex_office_reference.test(employerRecord?.account_office_reference) ||
							!regex_paye_reference.test(employerRecord?.paye_reference) ||
							employerRecord?.user === "" ||
							employerRecord?.password === "" ||
							!employerRecord?.user ||
							!employerRecord?.password
						}
						isLoading={isLoading}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

function EditAutoEpsSubmission({
	  open,
	  onClose,
	  employerRecord = {},
}) {
	
	const { mutateAsync: updateSettings, isPending: isLoading } = useUpdateHmrcSettings();
	
	return (
		<Modal
			isOpen={open}
			onClose={onClose}
		>
			<div style={{ display: "flex", flexDirection: "column", gap: "1.83vw"}}>
				<Typography
					variant={"title"}
				>
					{
						employerRecord?.eps_automatic
							? "Are you sure you want to disable automatic EPS submission?"
							: "Are you sure you want to enable automatic EPS submission?"
					}
				</Typography>
				<Typography
					variant={"subtitle"}
					color={"black"}
					weight={"normal"}
				>
					{
						employerRecord?.eps_automatic
							? "If you disable automatic EPS submission, you will need to handle it manually"
							: "Enabling automatic EPS submission will automatically submit EPS to HMRC, every " +
							"18th of the month. You will still be able to send any custom submission, " +
							"by accessing the reports section."
					}
				</Typography>
				<div style={{display: "flex", flexDirection: "row", gap: "1.03vw"}}>
					<FreepayrollButton
						variant={"white-button"}
						onClick={onClose}
						isLoading={isLoading}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={async () => {
							await updateSettings({
								'rti_keys': {
									'eps_automatic': !employerRecord?.eps_automatic
								}
							});
							onClose();
							toast.success("HMRC settings updated successfully");
						}}
						isLoading={isLoading}
					>
						{
							employerRecord?.eps_automatic
								? "Disable"
								: "Enable"
						}
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const HMRC = (): JSX.Element => {

	const { setLayoutProps } = useLoggedLayout();
	
	const { hmrcSettings, isPending } = useGetHmrcSettings();
	
	const [showEditModal, setShowEditModal] = useState(false);
	const [employerRecord, setEmployerRecord] = useState({});
	const [openEpsModal, setOpenEpsModal] = useState(false);
	
	const HMRCItems = [
		{
			label: "PAYE reference",
			value: hmrcSettings?.paye_reference,
		},
		{
			label: "Accounts office reference",
			value: hmrcSettings?.account_office_reference,
		},
		{
			label: "HMRC Gateway User ID",
			value: hmrcSettings?.user,
		},
		{
			label: "HMRC Gateway Password",
			value: "xxxxxx",
		}
	];

	useEffect(() => {
		setEmployerRecord(hmrcSettings);
	},[hmrcSettings]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'HMRC',
			showBackButton: false,
		});
	}, [setLayoutProps]);
	
	return (
		<div id={"hmrc-page"}>
			<Grid
				container
			>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={"bold"}
					>
						Company HMRC settings
					</StyleTypography>
					<Grid
						sx={{
							paddingTop: "10px",
							paddingBottom: "30px"
						}}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#ABAEB7"}
						>
							Credentials for HMRC reporting and RTI submissions
						</StyleTypography>
					</Grid>
				</Grid>
				<Divider className={"divider"}/>
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					className={"information-container"}
					direction={"row"}
				>
					<Grid
						xl={5.18}
						lg={5.18}
						md={5.18}
						sm={5.18}
						xs={5.18}
						item
						container
						className={"left-container"}
					>
						<Grid
							container
							justifyContent={"space-between"}
							className={'edit'}
						>
							<Grid>
								<Chip
									label={
										hmrcSettings?.hmrc_connected ?
											"Connected"
											:
											"Not connected"
									}
									className={"mui-chip"}
									sx={{backgroundColor: "#000000", color: "#FFFFFF", marginLeft: "10px"}} />
							</Grid>
							<Grid sx={{cursor: "pointer"}} onClick={() => setShowEditModal(true)}>
								<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={16} fontSizeMedium={13} color={"black"}>
									Edit
									<EditIcon className={"icon"}/>
								</StyleTypography>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						{
							HMRCItems.map((item, index) => (
								<Row
									key={index}
									label={item.label}
									value={item.value}
									isLoading={isPending}
									isLast={index === HMRCItems.length - 1}
								/>
							))
						}
					</Grid>

					<Grid
						xl={5.18}
						lg={5.18}
						md={5.18}
						sm={5.18}
						xs={5.18}
						item
						container
						sx={{
							marginLeft: "36px"
						}}
						className={"right-container"}
						alignItems={"flex-start"}
						alignSelf={"flex-start"}
					>
						<Grid container className={"information-item-grid"}>
							<Grid item xs={true}>
								<Grid container alignItems={"center"}>
									<Grid sx={{marginRight: '20px'}}>
										<img
											alt={"logo"}
											src={House}
											className={"right-icon"}
										/>
									</Grid>
									<StyleTypography fontSize={22} color={"black"} fontSizeMedium={16} fontWeight={"bold"}>
										HMRC
									</StyleTypography>
								</Grid>
								<Grid container alignItems={"center"}>
									<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
										PAYE is the HMRC system for collecting income tax and National Insurance from employment.
									</StyleTypography>
								</Grid>
							</Grid>
						</Grid >
						<Divider className={"divider"}/>
						<Grid container className={"information-item-grid"}>
							<Grid item xs={true}>
								<Grid container alignItems={"center"}>
									<Grid sx={{marginRight: '20px'}}>
										<img
											alt={"logo"}
											src={Plane}
											className={"right-icon"}
										/>
									</Grid>
									<StyleTypography fontSize={22} color={"black"} fontSizeMedium={16} fontWeight={"bold"}>
										RTI Submission
									</StyleTypography>
								</Grid>
								<Grid>
									<StyleTypography fontSize={18} fontSizeMedium={13} color={"#AAAEB7"}>
										Each time you run payroll for your employees, we perform a RTI Submission to HMRC.
									</StyleTypography>
								</Grid>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						<Grid container className={"information-item-grid"}>
							<Switch
								isChecked={hmrcSettings?.eps_automatic}
								onCheckChange={() => setOpenEpsModal(true)}
								textPosition={"left"}
								label={`Automatic EPS submission`}
								labelTypographyProps={{
									color: "black",
									weight: "bold",
									variant: "subtitle2"
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<EditHmrcSettings
				open={showEditModal}
				onClose={() => {
					setShowEditModal(false);
					setEmployerRecord(hmrcSettings);
				}}
				employerRecord={employerRecord}
				setEmployerRecord={setEmployerRecord}
			/>
			<EditAutoEpsSubmission
				open={openEpsModal}
				onClose={() => {
					setOpenEpsModal(false);
					setEmployerRecord(hmrcSettings);
				}}
				employerRecord={employerRecord}
			/>
		</div>
	);
};

export default HMRC;