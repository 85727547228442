import {useQuery} from "@tanstack/react-query";
import Request from "../../utils/Request";

export function useGetSickLeaves({ page, perPage, safeId }) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['sick-leaves', { page, perPage, safeId }],
		queryFn: async () => {
			return await Request.get(`/api/employees/${safeId}/sick-leaves`, { params: { page, perPage } })
				.then(response => response.data)
		},
		staleTime: 1000 * 60 * 60, // 1 hour
	});

	return {
		sickLeaves: data?.data,
		isPending,
		isError,
	};
}