import {useMutation} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useDownloadAllPayslips() {

	return useMutation({
		mutationFn: async (id) => {
			return await Request.get(`/api/employers/payrolls/${id}/download-payslips`,{ responseType: 'blob' })
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const url = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'payslips.zip');
			document.body.appendChild(link);
			link.click();
			toast.success("Downloaded payslips successfully.");
		},
	});
}