import {Details} from "./Details";
import AddPaySchedule from "./Pay Schedules/AddPaySchedule";
import PayItems from "./PayItems/PayItems";
import PaySchedules from "./Pay Schedules/PaySchedules";
import Salaries from "./Salaries";
import CompanyProfile from "./CompanyProfile";
import Allowances from "./Allowances";
import EditPaySchedule from "./Pay Schedules/EditPaySchedule";
import Statutories from "./Statutories";
import ListPayItems from "./PayItems/ListPayItems";

const CompanyPages = {
	Details,
	AddPaySchedule,
	EditPaySchedule,
	PaySchedules,
	PayItems,
	ListPayItems,
	Salaries,
	CompanyProfile,
	Allowances,
	Statutories,
}

export default CompanyPages;