import { clsx } from 'clsx';
import styles from './styles.module.scss';

export default function Skeleton({
    className,
    ...props
}) {
    let backgroundColor = props.background || 'rgb(239, 239, 239)';
    return (
        <div
            className={clsx(styles.Skeleton, className)}
            style={{ width: props.width, height: props.height, background:backgroundColor, ...props.style }}
            {...props}>
        </div>
    )
}
