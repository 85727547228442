import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import FolderReport from "../../../images/svg/ReportsIcon/folder-report.svg";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {Typography, Accordion, Divider} from "@collegia-partners/ui-kit";

function AccordionReports({
  title = "",
  subtitle1 = "",
  subtitle2 = "",
  url1 = "",
  url2 = "",
  push = () => {},
	disabled = false
}) {
	return (
		<Accordion
			title={
				<div className={"accordion-style"}>
					<img
						className={"img"}
						src={FolderReport}
						alt={"FolderReport"}
					/>
					<Typography
						variant={"subtitle"}
						weight={"bold"}
						color={"black"}
					>
						{title}
					</Typography>
				</div>
			}
			disabled={disabled}
			children={
				<div className={"accordion-details-margin"}>
					<div
						onClick={() => push(url1)}
					>
						<Typography
							variant={"body"}
							weight={"normal"}
							color={"gray"}
							className={"accordion-details-cursor"}
						>
							{subtitle1}
						</Typography>
					</div>
					<div
						className={"accordion-details-cursor"}
						onClick={() => push(url2)}
					>
						<Typography
							variant={"body"}
							weight={"normal"}
							color={"gray"}
							className={"accordion-details-cursor"}
						>
							{subtitle2}
						</Typography>
					</div>
				</div>
			}
		/>
	)
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsList = (): JSX.Element => {
	const {setLayoutProps} = useLoggedLayout();
	const push = useNavigate();

	useEffect(() => {
		setLayoutProps({
			activePage: 'Reports',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div
			id={'reports'}
		>
			<div>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					Reports
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					Download standard reports or create custom data exports
				</Typography>
				<div className={"reports-divider"}>
					<Divider color={"light-gray"}/>
				</div>
			</div>

			<div
				className={"reports-list-item"}
			>
				<AccordionReports
					title={"HMRC Submissions"}
					subtitle1={"Full Payment Submission (FPS)"}
					subtitle2={"Employer Payment Summary (EPS)"}
					url1={"/main/reports/fps"}
					url2={"/main/reports/eps"}
					push={push}
				/>
				<div className={"reports-divider"}>
					<Divider color={"light-gray"} />
				</div>
				<AccordionReports
					title={"Year End Reports"}
					subtitle1={"P60"}
					subtitle2={"P11Ds and P11D(b)"}
					url1={"/main/reports/p60"}
					url2={"/main/reports/p11"}
					disabled={true}
					push={push}
				/>
				<div className={"reports-divider"}>
					<Divider color={"light-gray"}/>
				</div>
				<div
					className={"accordion-style"}
					onClick={() => push("/main/reports/taxes-filings")}
				>
					<img
						className={"img"}
						src={FolderReport}
						alt={"FolderReport"}
					/>
					<Typography
						variant={"subtitle"}
						weight={"bold"}
						color={"black"}
					>
						P32 - Taxes and Filings
					</Typography>
				</div>
				<div className={"reports-divider"}>
					<Divider color={"light-gray"}/>
				</div>
				<div
					className={"accordion-style"}
					onClick={() => push("/main/reports/payroll-journals")}
				>
					<img
						className={"img"}
						src={FolderReport}
						alt={"FolderReport"}
					/>
					<Typography
						variant={"subtitle"}
						weight={"bold"}
						color={"black"}
					>
						Payroll Journals
					</Typography>
				</div>
			</div>
		</div>
	);
}

export default ReportsList;