import {
	keepPreviousData, useMutation,
	useQuery, useQueryClient, useInfiniteQuery
} from '@tanstack/react-query';
import Request from "../utils/Request";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

export function useListPaySchedules({page, perPage = 10}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['pay-schedules', page, perPage],
		queryFn: async () => {
			return await Request.get('/api/employers/pay-schedules/get',{params: {page, perPage}})
				.then(response => response.data)
		},
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 60, // 60 minutes
	});

	return {
		paySchedules: data?.data,
		isPending,
		isError,
	};
}

export function useFindPaySchedule({id, relations = []}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['pay-schedule', id],
		queryFn: async () => {

			let queryString = '';

			if (relations.length) {
				// Create a string like 'relations[]=relation1&relations[]=relation2'
				queryString = relations.map(relation => `relations[]=${relation}`).join('&');
			}

			return await Request.get(`/api/employers/pay-schedule/${id}` + (queryString ? `?${queryString}` : ''))
				.then(response => response.data)
		},
		placeholderData: keepPreviousData,
	});

	return {
		paySchedule: data,
		isPending,
		isError,
	};
}

export function useAddPaySchedule(isOnboarding) {

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/pay-schedule', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			if (isOnboarding) {
				queryClient.invalidateQueries({queryKey: ['employer']});
				navigate("/onboarding/dashboard");
				toast.success("Pay Schedule created successfully");
			} else {
				navigate("/main/company/pay-schedules");
				queryClient.invalidateQueries({queryKey: ['pay-schedules']});
				queryClient.invalidateQueries({queryKey: ['payrolls']});
				toast.success("Pay Schedule created successfully");
			}
			return true;
		},
	})
}

export function useDeletePaySchedule() {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({id, data}) => {
			return await Request.post(`/api/employers/delete-pay-schedule/${id}`, data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: ['pay-schedules']});
			toast.success("Pay Schedule deleted successfully");
			return true;
		},
	})
}

export function useUpdatePaySchedule() {

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation({
		mutationFn: async ({id, data}) => {
			return await Request.post(`/api/employers/update-pay-schedule/${id}`, data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: ['pay-schedules']});
			navigate("/main/company/pay-schedules");
			toast.success("Pay Schedule updated successfully");
			return true;
		},
	})
}

export function useUpdatePayScheduleStatus() {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({id, data}) => {
			return await Request.post(`/api/employers/deactivate-pay-schedule/${id}`, data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: ['pay-schedules']});
			queryClient.invalidateQueries({queryKey: ['payrolls']});
			toast.success("Pay Schedule deactivated successfully");
			return true;
		},
	})
}

export function useInfinitePaySchedules() {
	const {
		data,
		isLoading,
		isError,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ['pay-schedules'],
		initialPageParam: 1,
		queryFn: async ({pageParam}) => {
			return await Request.get('/api/employers/pay-schedules/get',{params: {page: pageParam}})
				.then(response => response.data)
		},
		getNextPageParam: (lastPage) => {
			const hasMorePages = lastPage?.data?.current_page < lastPage?.data?.last_page;
			return hasMorePages ? lastPage.data.current_page + 1 : undefined;
		},
		keepPreviousData: true,
	});
	
	// Flatten the pages into a single array of pay schedules
	const paySchedules = data?.pages?.flatMap(page => page.data.data) || [];
	
	return {
		paySchedules,
		isLoading,
		isError,
		fetchNextPage,
		hasNextPage,
	};
}