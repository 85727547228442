import { useLoggedLayout } from "../../../context/LoggedLayoutContext";
import { useUpdatePassword } from "../../../hooks/user";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { FreepayrollButton, FreepayrollTextField, Typography } from "@collegia-partners/ui-kit";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Check from "../../../components/common/Check";

const formSchema = z
	.object({
		password: z
			.string()
			.min(8, 'MIN_LENGTH')
			.regex(/[A-Za-z]/, 'LETTER_REQUIRED')
			.regex(/[0-9]/, 'NUMBER_REQUIRED'),
		passwordRepeat: z.string(),
		currentPassword: z
			.string()
			.min(8, 'MIN_LENGTH')
			.regex(/[A-Za-z]/, 'LETTER_REQUIRED')
			.regex(/[0-9]/, 'NUMBER_REQUIRED'),
	})
	.refine((data) => data.password === data.passwordRepeat, {
		path: ['passwordRepeat'],
		message: 'Passwords are not the same',
	});

const EditPasswordForm = (): JSX.Element => {
	const { setLayoutProps } = useLoggedLayout();
	const { mutate: updatePassword, isPending: isUpdating } = useUpdatePassword();
	const navigate = useNavigate();
	
	const { register, handleSubmit, formState: { errors }, watch } = useForm({
		mode: 'onChange',
		resolver: zodResolver(formSchema),
	});
	
	useEffect(() => {
		setLayoutProps({
			activePage: 'Home',
			showBackButton: true,
			backLink: '/main/settings/edit-info',
		});
	}, [setLayoutProps]);
	
	const password = watch('password');
	
	const onSubmit = (data) => {
		updatePassword({
			"users": {
				"current_password": data.currentPassword,
				"new_password": data.password,
				"confirm_password": data.passwordRepeat
			}
		});
	};
	
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '1.53vw' }}>
			<Typography variant={"title"}>Change email</Typography>
			<Typography variant={"subtitle"}>
				To update your password, please type the new password
				below and your current password.
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}
			      style={{display: 'flex', flexDirection: 'column', maxWidth: '40%', gap: '0.5vw'}}>
				<FreepayrollTextField
					fieldType={"password"}
					name={"new_password"}
					label={"New Password*"}
					{...register('password')}
					error={!!errors.password}
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.781vw'
					}}
				>
					<Check
						value={password}
						error={errors.password?.message || ''}
						caseError="MIN_LENGTH"
						message="Use 8 or more characters."
					/>
					<Check
						value={password}
						error={errors.password?.message || ''}
						caseError="LETTER_REQUIRED"
						message="Use a minimum of one letter."
					/>
					<Check
						value={password}
						error={errors.password?.message || ''}
						caseError="NUMBER_REQUIRED"
						message="Use a minimum of one number."
					/>
				</div>
				<FreepayrollTextField
					fieldType={"password"}
					name={"confirm_password"}
					label={"Confirm Password*"}
					{...register('passwordRepeat')}
					error={!!errors.passwordRepeat}
					helperText={errors.passwordRepeat?.message}
				/>
				<FreepayrollTextField
					fieldType={"password"}
					name={"password"}
					label={"Current Password*"}
					{...register('currentPassword')}
					error={!!errors.currentPassword}
				/>
				<div style={{display: 'flex', gap: '0.83vw'}}>
					<FreepayrollButton
						variant={"white-button"}
						onClick={() => navigate("/main/settings/edit-info")}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						type="submit"
						isLoading={isUpdating}
					>
						Save
					</FreepayrollButton>
				</div>
			</form>
		</div>
	);
};

export default EditPasswordForm;