import {
    keepPreviousData, useMutation,
    useQuery,
} from '@tanstack/react-query';
import Request from "../../utils/Request";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";

export function useGetTaxesFillingsReportTable({
    from_tax_month,
    to_tax_month,
    tax_year_id,
    page,
    orderBy,
    orderDir
}) {
    const {
        data,
        isPending,
        isError,
    } = useQuery({
        queryKey: ['report_taxes_fillings', {
            from_tax_month,
            to_tax_month,
            tax_year_id,
            page,
            orderBy,
            orderDir
        }],
        queryFn: async () => {
            return await Request.get(`/api/employers/employer-payment-summary`,
                {
                    params: {
                        from_tax_month,
                        to_tax_month,
                        tax_year_id,
                        page,
                        orderBy,
                        orderDir
                    }
                }).then(response => response?.data ?? {});
        },
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });
    return {
        report_taxes_fillings: data?.data,
        isPending,
        isError,
    };
}

export function useDownloadTaxesFillings() {

    return useMutation({
        mutationFn: async (data) => {
            return await Request.post("/api/employers/download-taxes-fillings", data)
                .then(response => response.data);
        },
        onError: (error) => {
            return error;
        },
        onSuccess: (data) => {
            const worksheet = XLSX.utils.json_to_sheet(data.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
            const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "csv" });

            FileDownload(
                excelFileContent,
                `taxes-fillings.csv`
            );

        },
    });
}