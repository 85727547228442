import React, {useCallback, useState} from "react";
import {
	Typography,
	FreepayrollTextField,
	Icon,
	Modal
} from "@collegia-partners/ui-kit";
import {Tab, Tabs} from "@mui/material";
import ApprovePayrollContent from "./PayrollModalContent/ApprovePayrollContent";
import PayrollSummary from "./PayrollSummary";
import {PayrollTable} from "./PayrollTable";
import Skeleton from "../Skeleton";
import RejectPayrollContent from "./PayrollModalContent/RejectPayrollContent";
import {InputHoursTable} from "./InputHoursTable";
import {formatDateFromBackend} from "../../utils/Helpers";
import {toast} from "sonner";
import Request from '../../utils/Request';
import {useNavigate} from "react-router-dom";

type PayrollContentProps = {
	tabIndex: number;
	onChangeTabs: (event: React.SyntheticEvent, newValue: number) => void;
	searchValue: string;
	setSearchValue: (value: string) => void;
	payrollMode: "RUN_PAYROLL" | "VIEW_PAYROLL" | "PAYROLL_APPROVAL";
	payrollSummary: {
		net_pay: number,
		total_pension_deducted: number,
		total_taxes: number,
		base_pay_amount: number,
		gross_additions: number,
		net_additions: number,
		gross_deductions: number,
		income_tax_deducted: number,
		student_loan: number,
		pg_loan: number,
		employee_ni_deducted: number,
		employee_pension_deducted: number,
		net_deductions: number,
		employer_ni_deducted: number,
		employer_pension_deducted: number,
		total_payroll_cost: number,
		number_of_employees: number,
	};
	employeePayRuns: [];
	setEmployeePayRuns?: (employeePayRuns: []) => void;
	isLoading: boolean;
	payrollId: string;
	paginationParams: {
		page: number;
		rowsPerPage: number;
		lastPage: number;
	};
	setPaginationParams: (params: {page: number, rowsPerPage: number, lastPage: number}) => void;
	payScheduleRun: {};
}


/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollContent = ({
	tabIndex,
	onChangeTabs,
    searchValue,
    setSearchValue,
	payrollMode,
	payrollSummary,
	employeePayRuns,
	setEmployeePayRuns,
	isLoading,
	payrollId,
	paginationParams,
	setPaginationParams,
	payScheduleRun,
}: PayrollContentProps): JSX.Element => {

	const [openApprovePayroll, setOpenApprovePayroll] = useState(false);
	const [openRejectPayroll, setOpenRejectPayroll] = useState(false);
	const push = useNavigate();

	const actionPayrollApproval = useCallback( async (approvalMethod, rejectReason = "") => {
		try {
			await Request.post(`api/employers/payroll-approval-action`, {
				'pay_schedule_runs': {
					token: payrollId,
					approval_method: approvalMethod,
					reason: rejectReason
				}
			});
			push("/payroll-approval-completed/" + payrollId);

		} catch (e) {
			if (e.response.status < 500) {
				toast.error("An error occurred while approving payroll, please contact support. CODE:" + e.response?.status);
			}
			console.error(e);
		}
	}, [payrollId, push]);

	return (
		<div id={"payroll-content"}>
			<div className={"heading"}>
				{
					isLoading ? (
						<Skeleton width={'10%'} height={'2vw'} background={'#D6D8E1'}/>
					) : (
						<Typography variant={"title"} weight={"bold"} color={"primary"}>
							Pay Date: {formatDateFromBackend(payScheduleRun?.period_end_date)}
						</Typography>
					)
				}
				{
					isLoading ? (
						<Skeleton width={'10%'} height={'2vw'} background={'#D6D8E1'}/>
					) : (
						<Typography variant={"title"} weight={"bold"} color={"primary"}>
							Tax Period: {payScheduleRun?.tax_period}
						</Typography>
					)
				}
			</div>
			<div>
				<Tabs
					className={"tabs-run-payroll"}
					value={tabIndex}
					variant={"fullWidth"}
					onChange={onChangeTabs}
				>
					<Tab
						sx={{borderBottom: "5px solid transparent"}}
						label={"Summary"}
						value={0}
					/>
					<Tab
						sx={{borderBottom: "5px solid transparent"}}
						label={"Employees"}
						value={1}
					/>
				</Tabs>
			</div>
			{
				//Render search field on the appropriate tabs, depending on the payroll mode
				(
					(tabIndex === 1 && payrollMode !== 'RUN_PAYROLL') ||
					(tabIndex !== 3 && payrollMode === 'RUN_PAYROLL')
				) && (
					<FreepayrollTextField
						placeholder="Search employee"
						name="search"
						fieldType="text"
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
						}}
						noGhostLabel
						noGhostHelperText
						customStartAdornment={
							<Icon iconName="magnifying-class" />
						}
						isLoading={isLoading}
					/>
				)
			}
			{
				payrollMode === "RUN_PAYROLL" ? (
					tabIndex < 3 ? (
						tabIndex === 1 ? (
							<InputHoursTable
								employeePayRuns={employeePayRuns}
								isLoading={isLoading}
								paginationParams={paginationParams}
								setPaginationParams={setPaginationParams}
								payrollMode={payrollMode}
								setEmployeePayRuns={setEmployeePayRuns}
							/>
						) : (
							<PayrollTable
								employeePayRuns={employeePayRuns}
								payrollMode={payrollMode}
								isLoading={isLoading}
								paginationParams={paginationParams}
								setPaginationParams={setPaginationParams}
							/>
						)
					) : (
						<PayrollSummary
							payrollMode={payrollMode}
							payrollSummary={payrollSummary}
							onApprovePayroll={() => setOpenApprovePayroll(true)}
							onRejectPayroll={() => setOpenRejectPayroll(true)}
							payrollId={payrollId}
							isLoading={isLoading}
							payScheduleRun={payScheduleRun}
						/>
					)
				) : (
					tabIndex === 0 ? (
						<PayrollSummary
							payrollMode={payrollMode}
							payrollSummary={payrollSummary}
							onApprovePayroll={() => setOpenApprovePayroll(true)}
							onRejectPayroll={() => setOpenRejectPayroll(true)}
							payrollId={payrollId}
							isLoading={isLoading}
							payScheduleRun={payScheduleRun}
						/>
					) : (
						<PayrollTable
							employeePayRuns={employeePayRuns}
							payrollMode={payrollMode}
							isLoading={isLoading}
							paginationParams={paginationParams}
							setPaginationParams={setPaginationParams}
						/>
					)
				)
			}
			{
				payrollMode === "PAYROLL_APPROVAL" && (
					<>
						<Modal isOpen={openApprovePayroll} onClose={() => setOpenApprovePayroll(false)} size={"sm"}>
							<ApprovePayrollContent
								taxPeriod={payScheduleRun?.tax_period}
								payDate={formatDateFromBackend(payScheduleRun?.period_end_date)}
								taxYear={payScheduleRun?.tax_year?.formated_tax_year}
								onApprovePayroll={() => actionPayrollApproval('approve')}
							/>
						</Modal>
						<Modal isOpen={openRejectPayroll} onClose={() => setOpenRejectPayroll(false)} size={"md"}>
							<RejectPayrollContent
								taxPeriod={payScheduleRun?.tax_period}
								payDate={formatDateFromBackend(payScheduleRun?.period_end_date)}
								taxYear={payScheduleRun?.tax_year?.formated_tax_year}
								onRejectPayroll={actionPayrollApproval}
							/>
						</Modal>
					</>
				)
			}
		</div>
	);
};

export default PayrollContent;
