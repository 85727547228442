import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PayrollContent from "../../../components/PayrollContent/PayrollContent";
import Request from "../../../utils/Request";
import {toast} from "sonner";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ViewPayroll = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();
	const id = useParams().payRunId;

	const [tabIndex, setTabIndex] = useState(0);

	const [searchText, setSearchText] = useState("");
	const [searchValue, setSearchValue] = useState("");

	const [paginationParams, setPaginationParams] = useState({
		page: 1,
		rowsPerPage: 10,
		lastPage: 1,
		from: 1,
		to: 10
	});

	const { page } = paginationParams;

	const [isLoading, setIsLoading] = useState(true);

	const [employeePayRuns, setEmployeePayRuns] = useState([]);
	const [payrollSummary, setPayrollSummary] = useState({});
	const [payScheduleRun, setPayScheduleRun] = useState({});
	const timeoutSet = React.useRef(null);

	const fetchPayrollData = useCallback(async () => {
		setIsLoading(true); // Set loading state to true before the request
		setEmployeePayRuns([]); // Clear the employee pay runs
		try {
			const { data } = await Request.get(`api/employers/view-payroll?id=${id}&search=${searchText}&page=${page}`);

			setPaginationParams({
				page: data.data.current_page,
				rowsPerPage: data.data.per_page,
				lastPage: data.data.last_page,
				from: data.data.from,
				to: data.data.to,
				total: data.data.total
			});
			setEmployeePayRuns(data.data.data); // Update state with fetched data
			setPayrollSummary(data.payroll_summary);
			setPayScheduleRun(data.pay_schedule_run);
		} catch (e) {
			toast.error("An error occurred while fetching payroll data, please contact support");
			console.error(e); // Handle errors
		} finally {
			setIsLoading(false); // Set loading state to false after the request
		}
	},[id, page, searchText]);

	useEffect(() => {
		fetchPayrollData();
	}, [fetchPayrollData]);


	useEffect(() => {
		if (timeoutSet.current) clearTimeout(timeoutSet.current);

		timeoutSet.current = setTimeout(() => {
			setSearchText(searchValue);
			setPaginationParams(prevState => ({
				...prevState,
				page: 1
			}));
		}, 500);
	}, [searchValue]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Payroll',
			activeSubpage: 'History',
			showBackButton: true,
			backLink: '/main/payroll/payroll-history',
		});
	}, [setLayoutProps]);

	return (
		<PayrollContent
			tabIndex={tabIndex}
			onChangeTabs={(event, newValue) => {
				setTabIndex(newValue);
			}}
			payScheduleRun={payScheduleRun}
			payrollMode={"VIEW_PAYROLL"}
			payrollSummary={payrollSummary}
			employeePayRuns={employeePayRuns}
			isLoading={isLoading}
			payrollId={id}
			paginationParams={paginationParams}
			setPaginationParams={setPaginationParams}
			searchValue={searchValue}
			setSearchValue={setSearchValue}
		/>
	);
}

export default ViewPayroll;