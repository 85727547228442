import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import {FreepayrollButton, Typography} from "@collegia-partners/ui-kit";
import FPTable from "../../../../components/common/FPTable/FPTable";
import {useListPayItems} from "../../../../hooks/employer/pay-items/useListPayItems";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ListPayItems = (): JSX.Element => {

	const { setLayoutProps } = useLoggedLayout();
	const push = useNavigate();

	const columns = [
		{
			text: "Name",
			color: "black",
			key: "name",
		},
		{
			text: "Type",
			color: "black",
			customRender: (row) => {
				return (
					<Typography
						variant="body"
						size="medium"
						color={"black"}
					>
						{
							row.is_gross && !row.is_net ? "Gross " + row.type :
								!row.is_gross && row.is_net ? "Net " + row.type : ""
						}
					</Typography>
				);
			},
		},
		{
			text: "Taxable",
			color: "black",
			customRender: (row) => {
				return (
					<Typography
						variant="body"
						size="medium"
						color={"black"}
					>
						{row?.before_tax ? "Yes" : "No"}
					</Typography>
				);
			},
		},
		{
			text: "NIable",
			color: "black",
			customRender: (row) => {
				return (
					<Typography
						variant="body"
						size="medium"
						color={"black"}
					>
						{row?.before_ni ? "Yes" : "No"}
					</Typography>
				);

			},
		},
		{
			text: "Pensionable",
			color: "black",
			customRender: (row) => {
				return (
					<Typography
						variant="body"
						size="medium"
						color={"black"}
					>
						{row?.before_pension ? "Yes" : "No"}
					</Typography>
				);
			},
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Pay Items',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "4.57vw"}}>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
				<div>
					<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
						Pay Items
					</Typography>
					<Typography variant={"title"} size={"medium"} color={"gray"} weight={"normal"}>
						Pay items allow you to set up custom payments and track each payment in the payroll journal
					</Typography>
				</div>
				<div style={{width: "fit-content"}}>
					<FreepayrollButton
						style={{height: "100px !important"}}
						size={"small"}
						variant={"primary"}
						height={1}
						custom
						onClick={() => {
							push("/main/company/create-pay-item");
						}}
					>
						+ Add Pay Item
					</FreepayrollButton>
				</div>
			</div>
			<div>
				<FPTable
					headers={columns}
					hookName={useListPayItems}
					hookKey={'payItems'}
					hookParams={{
						is_salary: false,
					}}
				/>
			</div>
		</div>
	);
};

export default ListPayItems;