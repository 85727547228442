import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import {useCallback, useEffect, useMemo, useRef} from "react";
import countryList from "react-select-country-list";
import {
	Divider,
	FreepayrollDateField,
	FreepayrollSelect,
	FreepayrollTextField, Typography
} from "@collegia-partners/ui-kit";
import {validateEmail} from "../../../../utils/Helpers";
import {useVerifyDuplicateEmployee} from "../../../../hooks/employee/useGetEmployee";
import moment from "moment";
import {InputLabel} from "@mui/material";

function returnErrorMessage(email = "", duplicateEmployee = false) {
	if (email !== '' && !validateEmail(email)) {
		return 'Invalid email address';
	}
	else if (email !== '' && duplicateEmployee) {
		return "Already exist an employee with this email";
	} else {
		return "";
	}
}


export const PersonalDetails = ({
	employeeInformation,
	setEmployeeInformation,
	ni_categories,
}) => {

	const countries = useMemo(() => countryList().getData(), []);
	let transformedCountries = countries?.map(country => ({
		value: country.label,
		label: country.label,
	}));
	const ninValidator = /^([ACEHJLMOPRSWXY][A-CEGHJ-NPR-TW-Z]|B[A-CEHJ-NPR-TW-Z]|G[ACEGHJ-NPR-TW-Z]|[KT][A-CEGHJ-MPR-TW-Z]|N[A-CEGHJL-NPR-SW-Z]|Z[A-CEGHJ-NPR-TW-Y])[0-9]{6}[A-D ]$/;
	let timeout = useRef(null);
	const {
		mutateAsync: verifyDuplicateEmployeeMutate,
		isPending: isLoading
	} = useVerifyDuplicateEmployee();

	const verifyDuplicateEmployee = useCallback(async (email) => {
		try {
			const { exist_employee_email } = await verifyDuplicateEmployeeMutate({
				employees: {
					email: email,
					payroll_id: null,
					employee_id: employeeInformation?.id,
				},
			});
			setEmployeeInformation((prevInfo) => ({
				...prevInfo,
				duplicatedEmployee: exist_employee_email,
			}));
		} catch (e) {
			console.error(e);
		}
	}, [employeeInformation?.id, setEmployeeInformation, verifyDuplicateEmployeeMutate]);

	useEffect(() => {
		if (employeeInformation.email !== '' && validateEmail(employeeInformation.email)) {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				verifyDuplicateEmployee(employeeInformation.email);
			}, 1000);
		}

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [employeeInformation.email, verifyDuplicateEmployee]);

	const genderOptions = [
		{ label: "Male", value: "M" },
		{ label: "Female", value: "F" },
	];

	const titleOptions = [
		{ label: "Mr", value: "Mr" },
		{ label: "Mrs", value: "Mrs" },
		{ label: "Miss", value: "Miss" },
		{ label: "Ms", value: "Ms" },
	];

	const personalItems = [
		{
			value: employeeInformation?.title,
			label: "Title",
			onChange: (option) => {
				setEmployeeInformation({
					...employeeInformation,
					title: option.value,
				});
			},
			fieldType: "select",
			options: titleOptions,
			gridArea: "title",
		},
		{
			value: employeeInformation?.forename,
			label: "Forename",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					forename: event.target.value,
				});
			},
			gridArea: "forename",
		},
		{
			value: employeeInformation?.surname,
			label: "Surname",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					surname: event.target.value,
				});
			},
			gridArea: "surname",
		},
		{
			value: employeeInformation?.gender,
			label: "Gender",
			onChange: (option) => {
				setEmployeeInformation({
					...employeeInformation,
					gender: option.value,
				});
			},
			fieldType: "select",
			options: genderOptions,
			gridArea: 'gender'
		},
		{
			value: employeeInformation?.birthdate,
			fieldType: "date",
			label: "Date of Birth",
			onChange: (value) => {
				setEmployeeInformation({
					...employeeInformation,
					birthdate: value.format('YYYY-MM-DD'),
				});
			},
			gridArea: 'dob'
		},
		{
			value: employeeInformation?.email,
			label: "Work Email*",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					email: event.target.value.toLowerCase(),
					duplicatedEmployee: false,
				});
			},
			error: (employeeInformation?.email !== '' && !validateEmail(employeeInformation?.email)) ||
				employeeInformation?.duplicatedEmployee,
			helperText: returnErrorMessage(employeeInformation?.email, employeeInformation?.duplicatedEmployee),
			gridArea: 'email',
			isLoading: isLoading,
		},
		{
			value: employeeInformation?.telephone,
			fieldType: 'phone',
			label: "Telephone (Optional)",
			onChange: (value) => {
				setEmployeeInformation({
					...employeeInformation,
					telephone: value,
				});
			},
			gridArea: 'phone'
		},
		{
			value: employeeInformation?.ni_category,
			fieldType: "select",
			label: "NI Category",
			onChange: (option) => {
				setEmployeeInformation({
					...employeeInformation,
					ni_category: option.value,
				});
			},
			options: ni_categories?.map((category) => ({ label: `${category.category} - ${category.description}`, value: category.category })) || [],
			gridArea: 'ni_category'
		},
		{
			value: employeeInformation?.national_insurance_number,
			label: "National Insurance Number",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					national_insurance_number: event.target.value,
				});
			},
			error: (employeeInformation.national_insurance_number !== null && employeeInformation.national_insurance_number !== '') &&
				!ninValidator.test(employeeInformation.national_insurance_number),
			gridArea: 'national_insurance_number'
		},
	];

	const addressItems = [
		{
			value: employeeInformation.address?.postal_code,
			label: "Postcode",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					address: {
						...employeeInformation.address,
						postal_code: event.target.value,
					},
				});
			},
			maxLength: 8,
		},
		{
			value: employeeInformation.address?.address_line_1,
			label: "Address Line 1",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					address: {
						...employeeInformation.address,
						address_line_1: event.target.value,
					},
				});
			},
			maxLength: 35,
		},
		{
			value: employeeInformation.address?.address_line_2,
			label: "Address Line 2 (Optional)",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					address: {
						...employeeInformation.address,
						address_line_2: event.target.value,
					},
				});
			},
			maxLength: 35,
		},
		{
			value: employeeInformation.address?.city,
			label: "City",
			onChange: (event) => {
				setEmployeeInformation({
					...employeeInformation,
					address: {
						...employeeInformation.address,
						city: event.target.value,
					},
				});
			},
			maxLength: 35,
		},
		{
			value: employeeInformation.address?.country,
			label: "Country",
			onChange: (option) => {
				setEmployeeInformation({
					...employeeInformation,
					address: {
						...employeeInformation.address,
						country: option.value,
					},
				});
			},
			fieldType: "select",
			options: transformedCountries,
		}
	];

	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '1.3vw', width: '100%', paddingTop: '20px'}}>
			<div style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(3,1fr)',
				columnGap: '1.3vw',
				rowGap: '0.83vw',
				gridTemplateAreas: `
					"title forename surname"
					"gender dob empty"
					"email phone empty2"
					"ni_category national_insurance_number empty3"
				`,
			}}>
				{
					personalItems.map((item, index) => {
						switch (item.fieldType) {
							case "select":
								return (
									<div style={{gridArea: item.gridArea}}>
										<FreepayrollSelect
											key={index}
											label={item.label}
											name={item.label}
											value={item?.value}
											onSelect={item.onChange}
											options={item.options}
											size={'small'}
										/>
									</div>
								)
							case "phone":
								return (
									<div style={{ gridArea: item.gridArea, display: 'flex', flexDirection: 'column' }} >
										<InputLabel
											className={"default-input-label"}
										>
											{item.label}
										</InputLabel>
										<MuiTelInput
											className={"default-phone-input"}
											defaultCountry={"GB"}
											flagSize={"medium"}
											forceCallingCode
											value={item.value ?? ""}
											error={
												(item.value !== null && item.value !== "") &&
												!matchIsValidTel(item.value ?? "")
											}
											onChange={item.onChange}
										/>
									</div>
								)
							case 'date':
								return (
									<div style={{gridArea: item.gridArea}}>
										<FreepayrollDateField
											key={index}
											label={item.label}
											name={item.label}
											value={moment(item.value)}
											onChange={item.onChange}
										/>
									</div>
								)
							default:
								return (
									<div style={{gridArea: item.gridArea}}>
										<FreepayrollTextField
											fieldType={"text"}
											name={"name"}
											label={item.label}
											value={item.value}
											onChange={item.onChange}
											error={item.error}
											helperText={item.helperText}
											isLoading={item.isLoading}
										/>
									</div>
								)
						}
					})
				}
			</div>
			<Typography
				variant={'subtitle'}
				weight={'normal'}
			>
				Address
			</Typography>
			<Divider color="light-gray"/>
			<div style={{display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', columnGap: '1.3vw'}}>
				{
					addressItems.map((item, index) => item.fieldType ? (
						<FreepayrollSelect
							key={index}
							label={item.label}
							name={item.label}
							value={item?.value}
							onSelect={item.onChange}
							options={item.options}
							size={'small'}
						/>
					) : (
						<FreepayrollTextField
							key={index}
							fieldType={"text"}
							label={item.label}
							name={item.label}
							value={item.value}
							onChange={item.onChange}
							maxLength={item.maxLength}
						/>
					))
				}
			</div>
		</div>
	);
};