import React, {useCallback, useEffect, useState} from "react";
import Loading from "../../../components/Loading";
import Divider from "@mui/material/Divider";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {FreepayrollButton, FreepayrollTextField, Typography} from "@collegia-partners/ui-kit";
import {useGetEmployer} from "../../../hooks/employer";
import {useUpdateStatutoryAmounts} from "../../../hooks/statutory";
import Skeleton from "../../../components/Skeleton";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Statutories = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();

	const [employerRecord, setEmployerRecord] = useState({});
	const { employer, isPending } = useGetEmployer({ relations: ['active_employer_ytd_figure'] });

	const {
		mutateAsync: updateStatutoryAmounts,
		isPending: isLoading
	} = useUpdateStatutoryAmounts();

	const submitUpdateStatutoryAmounts = useCallback(async () => {
		await updateStatutoryAmounts({
			"employers": {
				ytd_figure: employerRecord?.active_employer_ytd_figure,
			}
		})
	},[employerRecord?.active_employer_ytd_figure, updateStatutoryAmounts]);

	const employerStatutory = [
		{
			label: 'Recovered SMP YTD',
			value: employerRecord?.active_employer_ytd_figure?.smp_recovered_ytd,
			onChange: (e) => 	setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					smp_recovered_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'SMP NI Compensation YTD',
			value: employerRecord?.active_employer_ytd_figure?.smp_nic_ytd,
			onChange: (e) => 	setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					smp_nic_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'Recovered SPP YTD',
			value: employerRecord?.active_employer_ytd_figure?.spp_recovered_ytd,
			onChange: (e) => 	setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					spp_recovered_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'SPP NI Compensation YTD',
			value: employerRecord?.active_employer_ytd_figure?.spp_nic_ytd,
			onChange: (e) => 	setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					spp_nic_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'Recovered SAP YTD',
			value: employerRecord?.active_employer_ytd_figure?.sap_recovered_ytd,
			onChange: (e) => 	setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					sap_recovered_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'SAP NI Compensation YTD',
			value: employerRecord?.active_employer_ytd_figure?.sap_nic_ytd,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					sap_nic_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'Recovered SPBP YTD',
			value: employerRecord?.active_employer_ytd_figure?.spbp_recovered_ytd,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					spbp_recovered_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'SPBP NI Compensation YTD',
			value: employerRecord?.active_employer_ytd_figure?.spbp_nic_ytd,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					spbp_nic_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'Recovered ShPP YTD',
			value: employerRecord?.active_employer_ytd_figure?.shpp_recovered_ytd,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					shpp_recovered_ytd: e.target.value,
				}
			}),
		},
		{
			label: 'ShPP NI Compensation YTD',
			value: employerRecord?.active_employer_ytd_figure?.shpp_nic_ytd,
			onChange: (e) => setEmployerRecord({
				...employerRecord,
				active_employer_ytd_figure: {
					...employerRecord?.active_employer_ytd_figure,
					shpp_nic_ytd: e.target.value,
				}
			}),
		},
	];

	useEffect(() => {
		setEmployerRecord(employer);
	}, [employer]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Statutory',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	if (isPending || isLoading) {
		return <Loading/>;
	}

	return (
		<div
			id={"statutory"}
		>
			<div>
				<div>
					<div>
						<Typography
							variant={"title"}
							weight={"bold"}
							color={"#0160FD"}
						>
							Statutory pay
						</Typography>
						<Typography
							variant={"subtitle2"}
							weight={"normal"}
							color={"gray"}
						>
							If you have paid any Statutory Maternity Pay or
							Statutory Paternity Pay, we will claim 92% of it from HMRC.<br/>
							If you qualify for small Employer Relief, it will be 103%.
						</Typography>
					</div>
					<div>
						<div className={"statutory-divider-margin"}>
							<Divider/>
						</div>
						<div className={"statutory-fields"}>
							{
								employerStatutory.map((item, index) => isPending ? (
									<Skeleton height={50} width={'100%'} background={'#D6D8E1'}/>
								) : (
									<div
										key={index}
										className={"statutory-text-field"}
									>
										<FreepayrollTextField
											name={item?.label}
											label={item?.label}
											value={item?.value}
											onChange={item?.onChange}
											noGhostHelperText={true}
											fieldType={"currency"}
											maxDecimalPlaces={2}
										/>
									</div>
								))
							}
						</div>
						<div className={"statutory-row"}>
							<div>
								<FreepayrollButton
									fullWidth
									onClick={() => submitUpdateStatutoryAmounts()}
									variant={"primary"}
									size={"medium"}
									custom
									height={0.85}
									width={5.5}
								>
									Save
								</FreepayrollButton>
							</div>
							<div>
								<FreepayrollButton
									onClick={() => setEmployerRecord(employer)}
									variant={"white-button"}
									size={"medium"}
									custom
									height={0.76}
									width={5}
								>
									Discard
								</FreepayrollButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Statutories