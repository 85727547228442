import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PinInput from "react-pin-input";
import {useEffect, useState} from "react";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import {useUser} from "../../context/UserContext";
import {useConfirmEmail, useResendEmail} from "../../hooks/user";
import {FreepayrollButton} from "@collegia-partners/ui-kit";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmEmail = (): JSX.Element => {

	const { profileData: user, isPending: isLoadingUser } = useUser();

	const { mutate: confirmEmail, isPending: isConfirmingEmail } = useConfirmEmail();
	const { mutate: resendEmail, isPending: isResendingEmail } = useResendEmail();

	const [pin, setPin] = useState("");

	useEffect(() => {
		if (user?.temporary_password) {
			resendEmail();
		}

	}, [resendEmail, user?.temporary_password]);

	if (isLoadingUser || isResendingEmail) {
		return (
			<Loading/>
		);
	}

	return (
		<Grid
			container
			item
			xs={12}
			md={12}
			lg={12}
			xl={12}
			sm={12}
		>
			<Grid
				container
				id={"confirm-email-image"}
				alignItems={"center"}
				justifyContent={"center"}
				direction={"column"}
				item
				md={6}
			/>

			<Grid
				id={"confirm-email-container"}
				alignContent={"flex-start"}
				container
				item
				md={6}
			>
				<Grid
					xs={12}
					item
					textAlign={"center"}
					paddingBottom={'13px'}
				>
					<StyleTypography
						fontSize={50}
						fontSizeMedium={36}
						fontWeight={"bold"}
					>
						Enter the code <br/>
						to confirm your email
					</StyleTypography>
				</Grid>

				<Grid
					xs={12}
					item
					textAlign={"center"}
				>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={15}
						color={"#000000"}
					>
						Enter the code we sent to the address <b>{user?.email}</b> to confirm your email
					</StyleTypography>
				</Grid>

				<Grid
					xs={12}
					item
				>
					<PinInput
						className={"pin-input"}
						length={6}
						initialValue={pin}
						type={"numeric"}
						onChange={(value) => setPin(value)}
						onComplete={(value) => setPin(value)}
					/>
				</Grid>

				<Grid>

				</Grid>

				<Typography
					id={"resend"}
					className={"typography-p"}
					onClick={() => resendEmail()}
				>
					Didn't receive it? <span className={'resend-sub'}>Click here to resend it</span>.
				</Typography>

				<FreepayrollButton
					className={"default-black-button"}
					isLoading={isConfirmingEmail}
					variant={"primary"}
					disabled={
						pin.length < 6
					}
					onClick={() => {
						confirmEmail({
							"users": {
								pin,
							}
						});
					}}
				>
					CONFIRM
				</FreepayrollButton>
			</Grid>
		</Grid>
	)
};

export default ConfirmEmail;