import FormControlLabel from "@mui/material/FormControlLabel";
import React, {useCallback, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import {useUpdateApprenticeSettings} from "../../../../hooks/allowances";
import {Typography, Modal, FreepayrollTextField, FreepayrollButton} from "@collegia-partners/ui-kit";

export const ApprenticeModal = ({
	open,
	setOpen,
	employerRecord,
	setEmployerRecord,
	employer,
}): JSX.Element => {
	const [shareAllowance, setShareAllowance] = useState(false);
	const {
		mutateAsync: updateApprenticeSettings,
		isPending: isLoading
	} = useUpdateApprenticeSettings();

	const submitUpdateApprenticeSettings = useCallback(async (status) => {
		await updateApprenticeSettings({
			"employers": {
				action: status,
				levy_due_ytd: employerRecord?.active_employer_ytd_figure?.levy_due_ytd,
				pay_bill_ytd: employerRecord?.active_employer_ytd_figure?.pay_bill_ytd,
			}
		})
		setOpen(false);
	},[
		employerRecord?.active_employer_ytd_figure?.levy_due_ytd,
		employerRecord?.active_employer_ytd_figure?.pay_bill_ytd,
		setOpen,
		updateApprenticeSettings
	]);

	return (
		<Modal
			onClose={() => setOpen(false)}
			size={'sm'}
		 	isOpen={open}
		>
			<div id={"allowance-modal"}>
				<div>
					<Typography
						variant={"subtitle"}
						weight={"bold"}
						color={"primary"}
					>
						Apprenticeship Levy allowance
					</Typography>
					<Typography
						variant={"body"}
						weight={"normal"}
						color={"black"}
						className={"allowance-subtitle"}
					>
						If you have an annual pay bill of more than £3 million, you are required to pay the
						Apprenticeship Levy. You can use the Apprenticeship Levy each month.
					</Typography>
				</div>
				<div>

					<div className={"allowance-modal-row"}>
						{/*Pay bill YTD*/}
						<div>
							<FreepayrollTextField
								name={"pay_bill_ytd"}
								label={"Pay bill YTD"}
								value={employerRecord?.active_employer_ytd_figure?.pay_bill_ytd}
								onChange={(e) => {
									setEmployerRecord({
										...employerRecord,
										active_employer_ytd_figure: {
											...employerRecord?.active_employer_ytd_figure,
											pay_bill_ytd: e.target.value,
										}
									})
								}}
								customEndAdornment={
									<Typography
										variant={"body"}
										weight={"normal"}
										color={"black"}
									>
										£
									</Typography>
								}
								fieldType={"number"}
								maxDecimalPlaces={2}
							/>
						</div>

						{/*Levy due YTD*/}
						<div>
							<FreepayrollTextField
								name={"pay_bill_ytd"}
								label={"Levy due YTD"}
								value={employerRecord?.active_employer_ytd_figure?.levy_due_ytd}
								onChange={(e) => {
									setEmployerRecord({
										...employerRecord,
										active_employer_ytd_figure: {
											...employerRecord?.active_employer_ytd_figure,
											levy_due_ytd: e.target.value,
										}
									})
								}}
								customEndAdornment={
									<Typography
										variant={"body"}
										weight={"normal"}
										color={"black"}
									>
										£
									</Typography>
								}
								fieldType={"number"}
								maxDecimalPlaces={2}
							/>
						</div>
					</div>

					{/*Company share allowance ?*/}
					<div className={"allowance-modal-check-box"}>
						<FormControlLabel
							control={
								<Checkbox
									value={shareAllowance}
									checked={shareAllowance}
									icon={<CheckBoxOutlineBlankSharpIcon/>}
									checkedIcon={<CheckBoxSharpIcon/>}
									onChange={() =>
										setShareAllowance(!shareAllowance)
									}
								/>
							}
							label={
								<Typography
									color={"black"}
									weight={"normal"}
									className={"checkbox-label"}
									variant={"body"}
								>
									My company shares apprentice levy allowance with other companies.
								</Typography>
							}
						/>
					</div>
				</div>
				<div>
					<div className={"allowance-modal-row"}>
						<div>
							<FreepayrollButton
								onClick={() => {
									setEmployerRecord(employer);
									setOpen(false);
								}}
								variant={"white-button"}
								size={"medium"}
								custom
								height={0.85}
								width={5.5}
							>
								Cancel
							</FreepayrollButton>
						</div>

						{
							!employer?.apprentice_levy_due ? (
								<div>
									<FreepayrollButton
										fullWidth
										onClick={() => submitUpdateApprenticeSettings('enable')}
										variant={"primary"}
										size={"medium"}
										custom
										height={0.85}
										width={5.5}
										disabled={isLoading}
									>
										Enable
									</FreepayrollButton>
								</div>
							) : (
								<div>
									<FreepayrollButton
										fullWidth
										onClick={() => submitUpdateApprenticeSettings('disable')}
										variant={"primary"}
										size={"medium"}
										custom
										height={0.85}
										width={5.5}
										disabled={isLoading}
									>
										Stop
									</FreepayrollButton>
								</div>
							)
						}

					</div>
				</div>
			</div>
		</Modal>
	)
}