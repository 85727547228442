import Grid from "@mui/material/Grid";
import React, {useCallback, useEffect, useState} from "react";
import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import Loading from "../../../components/Loading";
import {Drawer, Tab, Tabs, Tooltip} from "@mui/material";
import EmployeeSummary from "../../../components/Pages/RunPayroll/EmployeeSummary";
import {useNavigate, useParams} from "react-router-dom";
import {ReactSpreadsheetImport} from "react-spreadsheet-import";
import {formatDateFromBackendWithTime} from "../../../utils/Helpers";
import EmployeesTable from "../../../components/Pages/RunPayroll/EmployeesTable";
import Request from "../../../utils/Request";
import PayrollSummary from "../../../components/PayrollContent/PayrollSummary";
import {useRunPayroll} from "../../../hooks/payroll/useRunPayroll";
import {useSaveHoursWorked} from "../../../hooks/payroll/useSaveHoursWorked";
import {useBulkImportPayItems} from "../../../hooks/payroll/useBulkImportPayItems";
import CircularProgress from "@mui/material/CircularProgress";
import {FreepayrollButton, FreepayrollDropbox} from "@collegia-partners/ui-kit";
import {useEmailAllPayslips} from "../../../hooks/payroll/useEmailAllPayslips";

const fieldsForHours = [
	{
		label: "Payroll ID",
		key: "payroll_id",
		alternateMatches: ["payroll id", "Payroll ID"],
		fieldType: {
			type: "input",
		},
		example: "EC1",
		validations: [
			{
				rule: "required",
				errorMessage: "Payroll ID is required",
				level: "error",
			},
			{
				rule: "unique",
				errorMessage: "Payroll ID needs to be unique",
				level: "error",
			},
		],
	},
	{
		label: "Hours Worked",
		key: "hours_worked",
		alternateMatches: ["hours worked", "Hours Worked"],
		fieldType: {
			type: "input",
		},
		example: "30.3",
		validations: [
			{
				rule: "regex",
				value: /^-?\d+(\.\d+)?$/,
				errorMessage: "Hours Worked must be a number",
				level: "error",
			},
		],
	},
];

const fieldsPayItem = [
	{
		label: "Payroll ID",
		key: "payroll_id",
		alternateMatches: ["payroll id", "Payroll ID"],
		fieldType: {
			type: "input",
		},
		example: "EC1",
		validations: [
			{
				rule: "required",
				errorMessage: "Payroll ID is required",
				level: "error",
			},
		],
	},
	{
		label: "Item Name",
		key: "item_name",
		alternateMatches: ["item name", "Item name"],
		fieldType: {
			type: "input",
		},
		example: "Director bonus",
		validations: [
			{
				rule: "required",
				errorMessage: "Item name is required",
				level: "error",
			},
		],
	},
	{
		label: "Amount",
		key: "total_amount",
		alternateMatches: ["amount", "Amount"],
		fieldType: {
			type: "input",
		},
		example: "2000.53",
		validations: [
			{
				rule: "regex",
				errorMessage: "Total amount is required. It must be a number between 0 and 300000. For decimals, we only accept up to two decimal places, and you muse a dot.",
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
			},
		],
	},
];

const fieldsRates = [
	{
		label: "Payroll ID",
		key: "payroll_id",
		alternateMatches: ["payroll id", "Payroll ID"],
		fieldType: {
			type: "input",
		},
		example: "EC1",
		validations: [
			{
				rule: "required",
				errorMessage: "Payroll ID is required",
				level: "error",
			},
		],
	},
	{
		label: "Rate Name",
		key: "rate_name",
		alternateMatches: ["rate name", "Rate Name"],
		fieldType: {
			type: "input",
		},
		example: "Holiday Pay",
		validations: [
			{
				rule: "required",
				errorMessage: "Item name is required",
				level: "error",
			},
		],
	},
	{
		label: "Hours/Units",
		key: "hours",
		alternateMatches: ["hours", "hours/units", "Hours/Units"],
		fieldType: {
			type: "input",
		},
		example: "30 or 27.5",
		validations: [
			{
				rule: "required",
				errorMessage: "Hours/Units is required",
				level: "error",
			},
			{
				rule: "regex",
				value: /^(?:300(?:\.0{1,2})?|[1-2]?\d?\d(?:\.\d{1,2})?)?$|^$/,
				errorMessage: "Hours must be a number between 0 and 300. For decimals, we only accept up to two decimal places, and you muse a dot.",
			},
		],
	},
	{
		label: "Rate",
		key: "rate",
		alternateMatches: ["rate", "Rate"],
		fieldType: {
			type: "input",
		},
		example: "2000.53",
		validations: [
			{
				rule: "regex",
				errorMessage: "Rate is required. It must be a number between 0 and 300000. For decimals, we only accept up to two decimal places, and you muse a dot.",
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
			},
		],
	},
];

function fillHoursOrPayItem(
	updateEmployeePayRuns = [],
	csvData = [],
	setUpdateEmployeePayRuns = () => {},
	isHours = false,
	isPayItem = false,
) {
	const updatedPayRuns = updateEmployeePayRuns.map(item => {
		const matchingObject = csvData.find(obj => obj?.payroll_id === String(item?.employee?.payroll_id));
		if (matchingObject) {
			if (isHours) {
				return {
					...item,
					work_hours: matchingObject?.hours_worked
				};
			} else if (isPayItem) {
				return {
					...item,
					pay_items: [...(item.pay_items || []), matchingObject?.pay_item]
				};
			} else {
				return item;
			}
		} else {
			return item;
		}
	});
	setUpdateEmployeePayRuns(updatedPayRuns);
}

function renderPayrollStepContent(
	tabIndex,
	setTabIndex,
	id,
	updateEmployeePayRuns,
	setUpdateEmployeePayRuns,
	employee_pay_runs,
	openMenu,
	setOpenMenu,
	anchorEl,
	setAnchorEl,
	setOpenImportFlow,
	setSelectedEmployee,
	showSummary,
	setShowSummary,
	payroll_summary,
	pay_schedule_run,
	push,
	validationData,
	setValidationData,
	today_date,
	setImportMode,
	setEmployeePayRuns,
	saveHoursWorked,
) {
	if (tabIndex < 3) {
		return (
			<>
				{/*Render Buttons*/}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: "20px",
						marginTop: "40px",
						flexGrow: 1
					}}
				>
					{/*Import Button*/}
					<div
						style={{
							display: "flex",
							flexGrow: 1
						}}
					>
						<div
							style={{
								display: "flex",
							}}
						>
							<FreepayrollDropbox
								target={
									<button
										style={{all: "unset", cursor: "pointer"}}
									>
										<FreepayrollButton
											variant="outline"
											custom
											width={3}
											height={0.3}
											size="small"
										>
											Import
										</FreepayrollButton>
									</button>
								}
							>
								<div>
									{
										tabIndex === 0 &&
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												gap: "1vw",
											}}
										>
											<button
												onClick={() => {
													setImportMode("pay_items");
													setOpenImportFlow(true);
													Request.get("/api/employers/validate-import-data?mode=salaried&importMode=pay_items")
														.then(response => {
															setValidationData(response?.data?.data);
														})
												}}
												disabled={tabIndex !== 0}
											>
												Import pay items for salaried employees
											</button>
											
											<button
												onClick={() => {
													setImportMode("rates");
													setOpenImportFlow(true);
													Request.get("/api/employers/validate-import-data?mode=salaried&importMode=rates")
														.then(response => {
															setValidationData(response?.data?.data);
														})
												}}
												disabled={tabIndex !== 0}
											>
												Import rates for salaried employees
											</button>
										</div>
									}
									{
										tabIndex === 1 &&
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												gap: "1vw",
											}}
										>
											<button
												onClick={() => {
													setOpenImportFlow(true);
												}}
											>
												Import hours for hourly employees
											</button>
										</div>
									}
									{
										tabIndex === 2 &&
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												gap: "1vw",
											}}
										>
											<button
												onClick={() => {
													setImportMode("pay_items");
													setOpenImportFlow(true);
													Request.get("/api/employers/validate-import-data?mode=hourly&importMode=pay_items")
														.then(response => {
															setValidationData(response?.data?.data);
														})
												}}
												disabled={tabIndex !== 2}
											>
												Import pay items for hourly employees
											</button>
											
											<button
												onClick={() => {
													setImportMode("rates");
													setOpenImportFlow(true);
													Request.get("/api/employers/validate-import-data?mode=salaried&importMode=rates")
														.then(response => {
															setValidationData(response?.data?.data);
														})
												}}
												disabled={tabIndex !== 2}
											>
												Import rates for hourly employees
											</button>
										</div>
									}
								</div>
							</FreepayrollDropbox>
						</div>
					</div>
					
					{/*Next Button and Save Hours*/}
					<div
						style={{
							display: "flex",
							flexGrow: 1,
							justifyContent: "flex-end",
						}}
					>
						<div
							style={{
								marginRight: 2,
								display: tabIndex === 1 ? "block" : "none",
							}}
						>
							<FreepayrollButton
								variant="outline"
								custom
								width={3}
								height={0.3}
								size="small"
								onClick={async () => {
									try {
										const {employee_pay_runs} = await saveHoursWorked({
											"pay_schedule_runs": {
												id: id,
												pay_runs: updateEmployeePayRuns.map((item) => {
													return {
														id: item.id,
														work_hours: item.work_hours ?? 0,
														hourly_rate: item.hourly_rate,
													};
												})
											}
										});
										setEmployeePayRuns(employee_pay_runs);
									} catch (error) {
										console.error(error);
									}
								}}
							>
								Save Hours
							</FreepayrollButton>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<Tooltip
								arrow={true}
								title="Button is disabled, you need to save hours before proceeding."
								open={updateEmployeePayRuns !== employee_pay_runs}
							>
                                <span>
									<FreepayrollButton
										variant="blue-button"
										custom
										width={3}
										height={0.3}
										size="small"
										disabled={updateEmployeePayRuns !== employee_pay_runs}
										onClick={() => {
											setTabIndex(tabIndex + 1);
											setSelectedEmployee([]);
											setShowSummary(false);
										}}
									>
										Next
									</FreepayrollButton>
                                </span>
							</Tooltip>
						</div>
					</div>
				</div>
				
				{/* Employees Table */}
				<EmployeesTable
					tabIndex={tabIndex}
					setUpdateEmployeePayRuns={setUpdateEmployeePayRuns}
					setEmployeePayRuns={setEmployeePayRuns}
					updateEmployeePayRuns={updateEmployeePayRuns}
					employee_pay_runs={employee_pay_runs}
					setSelectedEmployee={setSelectedEmployee}
					showSummary={showSummary}
					setShowSummary={setShowSummary}
				/>
			</>
		)
	} else {
		return (
			<Grid
				marginTop={'2vw'}
				paddingBottom={'5vw'}
			>
				<PayrollSummary
					payrollMode={"RUN_PAYROLL"}
					payrollSummary={payroll_summary}
					payrollId={id}
					isLoading={false}
					payScheduleRun={pay_schedule_run}
					todayDate={today_date}
				/>
			</Grid>
		)
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const RunPayroll = (): JSX.Element => {
	const id = useParams().payRunId;
	const {mutateAsync: runPayRoll, isPending: isProcessing} = useRunPayroll();
	const {mutateAsync: saveHoursWorked} = useSaveHoursWorked();
	const {mutateAsync: bulkImportPayItems} = useBulkImportPayItems();
	const {mutate: emailAllPayslips, isPending: isEmailingAllPayslips} = useEmailAllPayslips();
	
	const [payrollSummary, setPayrollSummary] = useState({});
	const [employeePayRuns, setEmployeePayRuns] = useState([{}]);
	const [payScheduleRun, setPayScheduleRun] = useState({});
	const [todayDate, setTodayDate] = useState("");
	
	const [validationData, setValidationData] = useState({});
	const [showSummary, setShowSummary] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState({});
	const [tabIndex, setTabIndex] = useState(0);
	const [updateEmployeePayRuns, setUpdateEmployeePayRuns] = useState([{}]);
	const [openImportFlow, setOpenImportFlow] = useState(false);
	const [importMode, setImportMode] = useState("");
	
	const [openMenu, setOpenMenu] = useState(false),
		[anchorEl, setAnchorEl] = useState(null);
	
	const push = useNavigate();
	
	const validateRow = (rowData, addError, tabIndex, importMode) => {
		
		if (!validationData.payroll_ids?.find(obj => obj?.payroll_id === String(rowData?.payroll_id))) {
			addError("payroll_id", {message: 'There is no employee with this payroll id', level: "error"});
		}
		
		
		if (tabIndex !== 1) {
			if (importMode === 'pay_items') {
				
				if (!validationData.pay_items?.find(obj => obj?.name === String(rowData?.item_name))) {
					addError("item_name", {message: 'There is not a pay item with the provided name', level: "error"});
				}
			} else {
				if (!validationData.pay_items?.find(obj => obj?.name === String(rowData?.rate_name))) {
					addError("rate_name", {message: 'There is not a rate with the provided name', level: "error"});
				}
			}
		}
		
		return rowData;
	}
	
	const processData = useCallback(async () => {
		try {
			const response = await runPayRoll({
				"pay_schedule_runs": {
					id: id,
					stage: tabIndex + 1,
				}
			});
			if (tabIndex === 3) {
				setTodayDate(response?.today);
				setPayrollSummary(response?.data);
				setEmployeePayRuns([]);
			} else {
				setEmployeePayRuns(response?.data);
				setTodayDate('');
				setPayrollSummary({});
			}
			setPayScheduleRun(response?.pay_schedule_run);
		} catch (error) {
			console.error(error);
		}
	}, [id, runPayRoll, tabIndex]);
	
	const asyncBulkImportPayItems = useCallback(async (data) => {
		try {
			const response = await bulkImportPayItems({
				'pay_schedule_runs': {
					pay_schedule_run_id: id,
					stage: tabIndex + 1,
					import_data: data?.validData,
					mode: importMode,
				}
			});
			setEmployeePayRuns(response?.data);
		} catch (error) {
			console.error(error);
		}
	}, [bulkImportPayItems, id, importMode, tabIndex]);
	
	useEffect(() => {
		processData();
	}, [processData]);
	
	useEffect(() => {
		setUpdateEmployeePayRuns(employeePayRuns);
	}, [employeePayRuns]);
	
	if (isProcessing) {
		return <Loading/>;
	}
	
	return (
		<Grid id={'run-payroll-page'} container>
			{/*Screen Title (Pay Date and Tax Period)*/}
			<Grid
				marginBottom={2}
				container
				justifyContent={"space-between"}
			>
				<Grid>
					<StyleTypography
						fontSize={24}
						fontSizeMedium={20}
						fontWeight={"bold"}
					>
						Pay Date: {formatDateFromBackendWithTime(payScheduleRun?.period_end_date)}
					</StyleTypography>
				</Grid>
				<Grid>
					<StyleTypography
						fontSize={24}
						fontSizeMedium={20}
						fontWeight={"bold"}
					>
						Tax Period: {payScheduleRun?.tax_period}
					</StyleTypography>
				</Grid>
			</Grid>
			
			{/*Tabs*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Tabs
					className={"tabs-run-payroll"}
					value={tabIndex}
					variant={"fullWidth"}
					onChange={(_, newValue) => {
						if (newValue < tabIndex) {
							setTabIndex(newValue);
						}
					}}
				>
					<Tab
						sx={{borderBottom: "5px solid transparent"}}
						label={"Salaried Employees"}
						value={0}
					/>
					<Tab
						sx={{borderBottom: "5px solid transparent"}}
						label={"Input Hours"}
						value={1}
					/>
					<Tab
						sx={{borderBottom: "5px solid transparent"}}
						label={"Hourly Employees"}
						value={2}
					/>
					<Tab
						sx={{borderBottom: "5px solid transparent"}}
						label={"Review Payroll"}
						value={3}
					/>
				</Tabs>
			</Grid>
			
			{/*Screen Content*/}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
				}}
			>
				{
					tabIndex === 3 &&
					<div
						style={{
							display: "flex",
							gap: "10px",
							marginTop: "2vw",
						}}
					>
						<FreepayrollDropbox
							target={
								<button
									style={{all: "unset", cursor: "pointer"}}
								>
									<FreepayrollButton
										variant="outline"
										custom
										width={3}
										height={0.3}
										size="small"
									>
										Actions
									</FreepayrollButton>
								</button>
							}
						>
							<button
								className={"EmployeeSummaryActions"}
								onClick={() => emailAllPayslips(atob(id))}
								disabled={isEmailingAllPayslips}
							>
								{
									isEmailingAllPayslips ?
										<CircularProgress/> :
										"Send Draft Payslip To All Employees"
								}
							</button>
						</FreepayrollDropbox>
					</div>
				}
				{renderPayrollStepContent(
					tabIndex,
					setTabIndex,
					id,
					updateEmployeePayRuns,
					setUpdateEmployeePayRuns,
					employeePayRuns,
					openMenu,
					setOpenMenu,
					anchorEl,
					setAnchorEl,
					setOpenImportFlow,
					setSelectedEmployee,
					showSummary,
					setShowSummary,
					payrollSummary,
					payScheduleRun,
					push,
					validationData,
					setValidationData,
					todayDate,
					setImportMode,
					setEmployeePayRuns,
					saveHoursWorked,
				)}
			</div>
			
			{/*Employee summary*/}
			{
				showSummary && (
					<Grid
						item
						xl={3}
						lg={3}
						xs={3}
						md={3}
						sm={3}
					>
						<Drawer
							open={true}
							variant="persistent"
							anchor="right"
							sx={{
								width: '24.5313vw',
								flexShrink: 0,
								'& .MuiDrawer-paper': {
									width: '24.5313vw',
								},
							}}
							autoFocus={false}
						>
							<EmployeeSummary
								selectedEmployee={selectedEmployee}
								setSelectedEmployee={setSelectedEmployee}
								setUpdateEmployeePayRuns={setUpdateEmployeePayRuns}
								setEmployeePayRuns={setEmployeePayRuns}
							/>
						</Drawer>
					</Grid>
				)
			}
			
			{
				!showSummary && (
					<ReactSpreadsheetImport
						id={"react-spreadsheet"}
						isOpen={openImportFlow}
						onClose={() => {
							setImportMode("");
							setOpenImportFlow(false);
						}}
						fields={
							tabIndex === 1 ?
								fieldsForHours :
								importMode === "pay_items" ?
									fieldsPayItem :
									fieldsRates
						}
						rowHook={(data, addError) => validateRow(data, addError, tabIndex, importMode)}
						allowInvalidSubmit={false}
						onSubmit={(data) =>
							tabIndex === 1
								? fillHoursOrPayItem(
									updateEmployeePayRuns,
									data?.validData,
									setUpdateEmployeePayRuns,
									true,
									false
								)
								: asyncBulkImportPayItems(data)
						}
						customTheme={{
							components: {
								Button: {
									baseStyle: {
										borderRadius: "none",
									},
									variants: {
										solid: {
											bg: '#0160FD',
											color: 'white',
											_hover: {
												bg: '#0140AA',
											},
										},
									},
								},
								UploadStep: {
									baseStyle: {
										dropZoneBorder: "#0160FD"
									},
								},
							},
						}}
					/>
				)
			}
		</Grid>
	);
};

export default RunPayroll;