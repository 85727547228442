import {
	useMutation,
	useQuery,
} from '@tanstack/react-query';
import Request from "../utils/Request";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";

export function useListPayrolls({mode, page, orderBy, orderDir, filters}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['payrolls', {mode, page, orderBy, orderDir, ...filters}],
		queryFn: async () => {
			return await Request.get('/api/employers/payrolls/get',{params: {page, mode, orderBy, orderDir, ...filters}})
				.then(response => response.data)
		},
		keepPreviousData: false,
		staleTime: 1000 * 60 * 60, // 60 minutes
	});

	return {
		payrolls: data?.data,
		isPending,
		isError,
	};
}

export function useDownloadNetPayReport() {

	return useMutation({
		mutationFn: async (id) => {
			return await Request.get(`/api/employers/payrolls/${id}/reports/net-pay`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const worksheet = XLSX.utils.json_to_sheet(data?.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

			FileDownload(
				excelFileContent,
				`net-pay-breakdown.xlsx`
			);
		},
	});
}

export function useDownloadGrossToNetReport() {
	
	return useMutation({
		mutationFn: async (id) => {
			return await Request.get(`/api/employers/payrolls/${id}/reports/gross-to-net`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const worksheet = XLSX.utils.json_to_sheet(data?.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "csv" });
			
			FileDownload(
				excelFileContent,
				`gross-to-net-reports.csv`
			);
		},
	});
}
