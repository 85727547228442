import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {formatDateFromBackendWithTime} from "../../../utils/Helpers";
import {disableRunPayrollButton} from "../MainPage";
import {Typography, Chip, FreepayrollButton} from "@collegia-partners/ui-kit";
import FPTable from "../../../components/common/FPTable/FPTable";
import {useListPayrolls} from "../../../hooks/payrolls";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useGetEmployer} from "../../../hooks/employer";
import {useListPaySchedules} from "../../../hooks/pay-schedules";

export function renderPayRunStatusColor(status) {
	switch (status) {
		case "Submitted":
			return '#0360FD';
		case "Reopened":
			return '#FFA500';
		default:
			return '#C4C4C4';
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollActivePage = (): JSX.Element => {

	const { employer } = useGetEmployer({ relations: [] })
	const { paySchedules } = useListPaySchedules({ page: 1, perPage: 20 });

	const push = useNavigate();
	const {setLayoutProps} = useLoggedLayout();

	const columns = [
		{
			text: "Schedule Name",
			key: "name",
			width: '1.5fr',
			filter: true,
			filterOptions: paySchedules?.data?.map((schedule) => ({ value: schedule.name })),
		},
		{
			text: "Tax Period",
			key: "tax_period",
			hasOrderBy: true,
		},
		{
			text: "Pay Run",
			key: "pay_run_period",
			width: '1.5fr'
		},
		{
			text: "Pay Date",
			key: "period_end_date",
			customRender: (row, index) => (
				<Typography
					variant="body"
					size="medium"
					key={index}
					color={"black"}
				>
					{formatDateFromBackendWithTime(row.period_end_date)}
				</Typography>
			),

		},
		{
			text: "Status",
			key: "status",
			customRender: (row, index) => (
				<Chip key={index} color={renderPayRunStatusColor(row.status)} >
					{row.status}
				</Chip>
			),
		},
		{
			text: "Actions",
			width: '0.75fr',
			customRender: (row, index) => (
				<FreepayrollButton
					variant={"primary"}
					onClick={() => {
						push(`/main/run-payroll/${btoa(row.id.toString())}`);
					}}
					size={"small"}
					height={0.833}
					disabled={disableRunPayrollButton(employer)}
					key={index}
				>
					Run Payroll
				</FreepayrollButton>
			),
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Payroll',
			activeSubpage: 'Active',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "2.57vw"}}>
			<div
				style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
				<Typography variant={"title"} size={"large"} color={"primary"} weight={"bold"}>
					Current Payrolls
				</Typography>
			</div>
			<div>
				<FPTable
					headers={columns}
					hookName={useListPayrolls}
					hookKey={'payrolls'}
					hookParams={{
						mode: 'active',
					}}
					paginate={true}
				/>
			</div>
		</div>
	);
};

export default PayrollActivePage;