import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {CompletedContent} from "../../../components/CompletedContent/CompletedContent";
import Request from "../../../utils/Request";
import {toast} from "sonner";
import {formatDateFromBackend} from "../../../utils/Helpers";
import Loading from "../../../components/Loading";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import FullBlackLogo from "../../../images/svg/Logos/new-fp-logo-blakc-blue-horizontal.svg";
import AppBar from "@mui/material/AppBar";

function Header({ children }) {
	return (
		<div className="header">
			<AppBar
				position={'sticky'}
				className={'app-bar'}
			>
				<Toolbar>
					<IconButton
						size={'large'}
						edge={'start'}
						disableFocusRipple
						disableRipple
						disableTouchRipple
					>
						<img
							alt={'logo'}
							src={FullBlackLogo}
							className={'app-bar-full-logo'}
						/>
					</IconButton>
				</Toolbar>
			</AppBar>
			<div
				style={{
					display: 'flex',
					width: '100%',
					paddingTop: '2.9688vw',
					paddingBottom: '2.9688vw',
					paddingLeft: '37px',
					gap: '2.9688vw',
				}}
			>
				{children}
			</div>
		</div>
	);
}

export const CompletedPayrollApproval = (): JSX.Element => {
	const token = useParams().token;
	const push = useNavigate();
	const [approvalData, setApprovalData] = useState({});
	const [payScheduleRun, setPayScheduleRun] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const retrieveTokenData = useCallback(async () => {
		setApprovalData({}); // Clear the approval data
		setPayScheduleRun({}); // Clear the pay schedule run
		setIsLoading(true); // Set loading state to true before the request
		try {
			const { data } = await Request.get(`api/employers/retrieve-approval-data?token=${token}`);
			setApprovalData(data.data);
			setPayScheduleRun(data.data?.employer_pay_schedule_run);
			if (data?.data?.is_approved === null) {
				push("/payroll-approval/" + token);
			}
		} catch (e) {
			if (e.response?.status === 404) {
				// push("/login");
				toast.error("No payroll data found, please contact support");
			} else {
				toast.error("An error occurred while fetching payroll data, please contact support");
			}
			console.error(e); // Handle errors
		} finally {
			setIsLoading(false); // Set loading state to false after the request
		}
	},[token,push]);

	const downloadPaymentBreakdown = useCallback(async () => {
		setIsLoading(true); // Set loading state to true before the request
		try {
			const {data} = await Request.get(`api/employers/download-payment-breakdown?token=${token}`);
			const worksheet = XLSX.utils.json_to_sheet(data.data);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
			const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

			FileDownload(
				excelFileContent,
				`net-pay-breakdown.xlsx`
			);

		} catch (e) {
			if (e.response?.status < 500) {
				toast.error("Error occured while downloading the payment breakdown, " +
					"please contact support. CODE:" + e.response?.status);
			}
			console.error(e); // Handle errors
		} finally {
			setIsLoading(false); // Set loading state to false after the request
		}
	},[token]);

	useEffect(() => {
		retrieveTokenData();
	},[retrieveTokenData]);

	return (
		<Header>
			{
				isLoading ? (
					<Loading/>
				) : (
					<CompletedContent
						mode={approvalData.is_approved ? "approved" : "rejected"}
						approvalAction={approvalData.is_approved ? "Payroll approved" : "Payroll rejected"}
						scheduleName={payScheduleRun?.pay_schedule?.name}
						taxPeriod={payScheduleRun?.tax_period}
						payDate={formatDateFromBackend(payScheduleRun?.period_end_date)}
						onButtonClick={() => push('/')}
						onDownloadClick={() => downloadPaymentBreakdown()}
						isLoading={isLoading}
					/>
				)
			}
		</Header>
	);
};