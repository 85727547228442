import {useMutation, useQueryClient} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useDeleteSickLeave(safeId) {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (id) => {
			return await Request.delete(`/api/employees/${safeId}/sick-leaves/${id}`)
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['sick-leaves'] });
			toast.success('Sick leave delete successfully');
		},
	});
}