import React, {useState} from "react";
import DateIcon from "../../../../images/svg/ReportsIcon/date-icon.svg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Typography, Divider, Switch} from "@collegia-partners/ui-kit";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsP11 = (): JSX.Element => {
	const [reportsDownloads] = useState([
		"2023/2024",
		"2022/2023",
		"2021/2022"
	]),
		[switchHmrc, setSwitchHmrc] = useState(false),
		[switchEmployee, setSwitchEmployee] = useState(false);

	return (
		<div
			id={"reports-p11"}
		>
			<div
				className={"reports-p11-item"}
			>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					P11Ds and P11D(b)
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					Upload and manage your P11D reportable benefits.
				</Typography>
				<div className={"reports-p11-divider-title-margin"}>
					<Divider color={"light-gray"}/>
				</div>
				<div className={"reports-p11-content"}>
					{
						reportsDownloads.length > 0 &&
						<div className={"reports-p11-years"}>
							{
								reportsDownloads.map((download, index) => {
									return (
										<div
											key={index}
										>
											<div className={"reports-p11-years-row"}>
												<div className={"reports-p11-years-img-year"}>
													<img
														className={"reports-p11-years-img"}
														src={DateIcon}
														alt={"Date"}
													/>
													<Typography
														color={"black"}
														weight={"bold"}
														variant={"subtitle2"}
													>
														{download}
													</Typography>
												</div>
												<ArrowForwardIosIcon className={"reports-p11-arrow"}/>
											</div>
											{
												reportsDownloads.length - 1 !== index
													? <div className={"reports-p11-years-divider-margin"}>
														<Divider color={"light-gray"}/>
													</div>
													: ""
											}
										</div>
									)
								})
							}
						</div>
					}
					<div>
						<div>
							<Typography
								color={"black"}
								weight={"bold"}
								variant={"subtitle"}
							>
								Settings
							</Typography>
							<Typography
								color={"gray"}
								weight={"normal"}
								variant={"body"}
							>
								Control if FreePayroll will automatically submit documents to HMRC on your behalf.
							</Typography>
						</div>
						<div
							className={"reports-p11-box-border"}
						>
							<div>
								<div className={"reports-p11-box-border-title"}>
									<Typography
										variant={"subtitle2"}
										color={"black"}
										weight={"bold"}
									>
										Automatically submit P11Ds to HMRC
									</Typography>
									<Switch
										onCheckChange={() => {
											setSwitchHmrc(!switchHmrc);
										}}
										isChecked={switchHmrc}
										label={""}
									/>
								</div>
								<Typography
									variant={"body"}
									weight={"normal"}
									color={"gray"}
								>
									If enabled, FreePayroll will automatically send copies of each <br/> employee’s
									P11Ds
									to.
									HMRC on your behalf at the end of the tax year.
								</Typography>
							</div>
							<div
								className={"reports-p11-divider"}
							>
								<Divider color={"light-gray"}/>
							</div>
							<div>
								<div className={"reports-p11-box-border-title"}>
									<Typography
										color={"black"}
										weight={"bold"}
										variant={"subtitle2"}
									>
										Automatically send P11Ds to employees
									</Typography>
									<Switch
										onCheckChange={() => {
											setSwitchEmployee(!switchEmployee);
										}}
										isChecked={switchEmployee}
										label={""}
									/>
								</div>
								<Typography
									color={"gray"}
									weight={"normal"}
									variant={"body"}
								>
									If enabled, FreePayroll will automatically send copies of each <br/> employee’s P11D
									to
									them by email at the end of the tax year.
								</Typography>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}



export default ReportsP11;