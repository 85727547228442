import React, {useCallback, useState} from "react";
import {
	Divider,
	FreepayrollButton, FreepayrollDateField,
	FreepayrollDropbox, FreepayrollRadio, FreepayrollTextField, Icon,
	Typography
} from "@collegia-partners/ui-kit";
import {formatDateFromBackend, formatMoney} from "../../../../../utils/Helpers";
import {useParams} from "react-router-dom";
import FPTable from "../../../../../components/common/FPTable/FPTable";
import {useGetSickLeaves} from "../../../../../hooks/employee/useGetSickLeaves";
import moment from "moment";
import Link from "@mui/material/Link";
import {isDate} from "date-fns";
import {useCreateSickLeave} from "../../../../../hooks/employee/useCreateSickLeave";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import {toast} from "sonner";
import {useDeleteSickLeave} from "../../../../../hooks/employee/useDeleteSickLeave";
import {StickerAlert} from "../../../../../components/StickerAlert";

function SickLeaveDates({ sickLeaveDetails, setSickLeaveDetails }) {

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '1.3vw' }} >
			<div>
				<Typography
					variant={"title"}
				>
					When was employee's first sick day?
				</Typography>
				<Typography
					variant={"subtitle2"}
					color={"gray"}
				>
					This includes weekends, bank holidays, and non-working days.
				</Typography>
			</div>
			<div style={{ display: 'flex', width: 'fit-content'}} >
				<FreepayrollDateField
					label={"Leave start date"}
					value={moment(sickLeaveDetails.first_sick_day || null)}
					onChange={(value) => {
						setSickLeaveDetails({
							...sickLeaveDetails,
							first_sick_day: value.format('YYYY-MM-DD')
						});
					}}
				/>
			</div>
			<div>
				<Typography
					variant={"subtitle"}
				>
					Do you know the sick leave end date?
				</Typography>
				<Typography
					variant={"subtitle2"}
					color={"gray"}
				>
					If no, we will pay statutory payments until you tell us that the employee has returned to work.
				</Typography>
			</div>
			<FreepayrollRadio
				options={[
					{value: "Yes"},
					{value: "No"}
				]}
				horizontal={true}
				checkedOption={sickLeaveDetails?.last_sick_day_radio}
				onOptionChange={ (value) => {
					console.log(value);
					setSickLeaveDetails({
						...sickLeaveDetails,
						last_sick_day: null,
						last_sick_day_radio: value
					})
				}}
			/>
			{
				sickLeaveDetails?.last_sick_day_radio === 'Yes' && (
					<div style={{display: 'flex', maxWidth: 'fit-content'}}>
						<FreepayrollDateField
							label={"Leave end date"}
							value={moment(sickLeaveDetails.last_sick_day || null)}
							onChange={(value) => {
								setSickLeaveDetails({
									...sickLeaveDetails,
									last_sick_day: value.format('YYYY-MM-DD')
								});
							}}
						/>
					</div>
				)
			}
		</div>
	);
}

function SickLeaveWeeklyEarnings({ sickLeaveDetails, setSickLeaveDetails }) {

	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '1.3vw'}}>
			<div>
				<Typography
					variant={"title"}
				>
					Confirm average weekly earnings
				</Typography>
				<Typography
					variant={"subtitle"}
					color={"gray"}
				>
					Enter an amount below to check for SSP eligibility.
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1.3vw',
					width: 'fit-content'
				}}
			>
				<div style={{ width: 'fit-content' }} >
					<FreepayrollTextField
						fieldType={"number"}
						name={"avg_weekly_earnings"}
						label={"Average weekly earnings"}
						value={sickLeaveDetails?.average_weekly_earnings}
						onChange={(e) => setSickLeaveDetails({
							...sickLeaveDetails,
							average_weekly_earnings: e.target.value
						})}
						maxDecimalPlaces={2}
						helperText={
							<Link
								target={"_blank"}
								href={"https://www.gov.uk/guidance/statutory-sick-pay-manually-calculate-your-employees-payments"}
								color={"primary"}
								underline={"hover"}
							>
								Don't know the average weekly earnings?
							</Link>
						}
						customStartAdornment={
							<Typography
								variant={"subtitle2"}
								weight={"bold"}
								color={"black"}
							>
								£
							</Typography>
						}
					/>
				</div>
				{
					sickLeaveDetails?.average_weekly_earnings < 123 && (
						<StickerAlert
							stickerColor={'#0c1d3a'}
							text={
								'Employee is not eligible for Statutory Sick Pay. ' +
								'Recording this leave will still reduce employee pay.'
							}
						/>
					)
				}
			</div>
		</div>
	);
}

function SickLeaveSummary({ sickLeaveDetails }) {

	const SummaryLine = ({label, value}) => (
		<div style={{display: 'flex', justifyContent: 'space-between', columnGap: '10vw'}}>
			<Typography
				variant={"subtitle2"}
				color={"black"}
			>
				{label}
			</Typography>
			<Typography
				variant={"subtitle2"}
				weight={"normal"}
				color={"black"}
			>
				{value}
			</Typography>
		</div>
	);

	// Employee works Monday to Friday (1 = Monday, 5 = Friday)
	const workingPattern = sickLeaveDetails?.working_pattern;
	const workingPatternString = workingPattern.map(day => moment().isoWeekday(day)
		.format('ddd')).join(', ');

	let qualifyingDays = 'N/A';
	let absentDays = 'N/A';

	if (sickLeaveDetails.last_sick_day) {

		qualifyingDays = 0;
		absentDays = 0;
		// Loop through each day in the sick leave period
		for (let day = moment(sickLeaveDetails.first_sick_day); day.isSameOrBefore(sickLeaveDetails.last_sick_day); day.add(1, 'days')) {
			absentDays += 1;
			// Check if the day is in the employee's working pattern
			if (workingPattern.includes(day.isoWeekday())) {
				qualifyingDays += 1;
			}
		}
	}

	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '1.3vw'}}>
			<div>
				<Typography
					variant={"title"}
				>
					Summary
				</Typography>
				<Typography
					variant={"subtitle"}
					color={"gray"}
				>
					Confirm the details below before saving.
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1.3vw',
					width: 'fit-content'
				}}
			>
				<SummaryLine label={"First sick day"} value={moment(sickLeaveDetails?.first_sick_day).format('DD/MM/YYYY')} />
				<SummaryLine
					label={"Last sick day"}
					value={
						isDate(moment(sickLeaveDetails?.last_sick_day).format('DD/MM/YYYY')) ?
							moment(sickLeaveDetails?.last_sick_day).format('DD/MM/YYYY') :
							'N/A'
					}
				/>
				<SummaryLine label={"Absent days"} value={absentDays} />
				<Divider color={'light-gray'}/>
				<SummaryLine label={"Working pattern"} value={workingPatternString} />
				<SummaryLine label={"Average weekly earnings"} value={formatMoney(sickLeaveDetails?.average_weekly_earnings)} />
				<Divider color={'light-gray'}/>
				<SummaryLine label={"Qualifying days"} value={qualifyingDays} />
			</div>
		</div>
	);
}

export const SickLeaveTab = () => {

	const employeeId = useParams().employeeId;
	const [step, setStep] = useState(0);
	const [sickLeaveDetails, setSickLeaveDetails] = useState({
		first_sick_day: null,
		last_sick_day: null,
		last_sick_day_radio: 'No',
		average_weekly_earnings: null,
		working_pattern : [1, 2, 3, 4, 5] // Employee works Monday to Friday (1 = Monday, 5 = Friday)
	});

	const { mutateAsync: createSickLeave, isPending:isCreating } = useCreateSickLeave(employeeId);
	const { mutate: deleteSickLeave, isPending:isDeleting } = useDeleteSickLeave(employeeId);

	const { employee } = useGetEmployee({ id: employeeId, relations: ['active_payroll_period'] });

	const columns = [
		{
			text: 'Leave Start Date',
			key: 'leave.leave_start_date',
			customRender: (row) => (
				<Typography
					variant="body"
					size="medium"
					color={"black"}
				>
					{formatDateFromBackend(row?.leave?.leave_start_date)}
				</Typography>
			),
		},
		{
			text: 'Leave End Date',
			key: 'leave.leave_end_date',
			customRender: (row) => (
				<Typography
					variant="body"
					size="medium"
					color={"black"}
				>
					{formatDateFromBackend(row?.leave?.leave_end_date)}
				</Typography>
			),
		},
		{
			text: 'Deducted Days',
			key: 'deducted_days',
		},
		{
			text: 'Waiting Days',
			key: 'waiting_days',
		},
		{
			text: 'Payable Days',
			key: 'payable_days',
		},
		{
			text: 'Actions',
			key: '',
			customRender: (row) => (
				<FreepayrollDropbox
					target={
						<button
							style={{all: "unset", cursor: "pointer"}}
						>
							<Icon
								iconName="dots-horizontal"
								className={"EmployeeSummaryIcon"}
							/>
						</button>
					}
					className={"EmployeeSummaryActions"}
				>
					<button
						className={"EmployeeSummaryAction"}
						onClick={() => {

							if (
								!row?.leave?.leave_start_date ||
								!employee?.active_payroll_period?.period_start_date ||
								row?.leave?.leave_start_date < employee?.active_payroll_period?.period_start_date
							) {
								toast.error('Sick leave cannot be deleted as it has already been processed.');
								return;
							}

							deleteSickLeave(row?.id);
						}}
						disabled={isDeleting}
					>
						{isDeleting ? 'Deleting...' : 'Delete'}
					</button>
				</FreepayrollDropbox>
			),
		},
	];

	function checkDisabled() {
		if (step === 1) {
			return !sickLeaveDetails.first_sick_day ||
				(
					sickLeaveDetails.last_sick_day_radio === 'Yes' &&
					!sickLeaveDetails.last_sick_day
				);
		} else if (step === 2) {
			return sickLeaveDetails.average_weekly_earnings === '' ||
				!sickLeaveDetails.average_weekly_earnings;
		}
		return false;
	}

	const OnSave = useCallback(async () => {
		try {
			await createSickLeave({
				employees: {
					first_sick_day: sickLeaveDetails.first_sick_day,
					last_sick_day: sickLeaveDetails.last_sick_day,
					last_sick_day_radio: sickLeaveDetails.last_sick_day_radio,
					average_weekly_earnings: sickLeaveDetails.average_weekly_earnings,
					working_pattern: sickLeaveDetails.working_pattern
				}
			});
			setStep(0);
			setSickLeaveDetails({
				first_sick_day: null,
				last_sick_day: null,
				last_sick_day_radio: 'No',
				average_weekly_earnings: null,
				working_pattern : [1, 2, 3, 4, 5]
			});
		} catch (e) {
			console.error(e);
		}
	}, [createSickLeave, sickLeaveDetails]);

	return (
		<>
			{
				step === 0 && (
					<div style={{display: "flex", flexDirection: "column", gap: "3vw"}}>
						<div style={{display: "flex", justifyContent: "space-between"}}>
							<Typography
								variant={"subtitle"}
								size={"xmedium"}
							>
								Sick leaves
							</Typography>
							<FreepayrollButton
								variant={"primary"}
								size={"small"}
								fullWidth={false}
								custom={true}
								height={0.5}
								onClick={() => setStep(1)}
							>
								+ Add sick leave
							</FreepayrollButton>
						</div>
						<FPTable
							headers={columns}
							hookName={useGetSickLeaves}
							hookKey={'sickLeaves'}
							hookParams={{
								safeId: employeeId,
							}}
						/>
					</div>
				)
			}
			{
				step === 1 && (
					<SickLeaveDates
						sickLeaveDetails={sickLeaveDetails}
						setSickLeaveDetails={setSickLeaveDetails}
					/>
				)
			}
			{
				step === 2 && (
					<SickLeaveWeeklyEarnings
						sickLeaveDetails={sickLeaveDetails}
						setSickLeaveDetails={setSickLeaveDetails}
					/>
				)
			}
			{
				step === 3 && (
					<SickLeaveSummary
						sickLeaveDetails={sickLeaveDetails}
						setSickLeaveDetails={setSickLeaveDetails}
					/>
				)
			}
			{
				step > 0 && (
					<div style={{ display: 'flex', columnGap: '30px' }} >
						<FreepayrollButton
							variant={"white-button"}
							size={"small"}
							custom={true}
							fullWidth={false}
							width={6}
							height={0.5}
							onClick={() => {
								setStep(0);
								setSickLeaveDetails({
									first_sick_day: null,
									last_sick_day: null,
									last_sick_day_radio: 'No',
									average_weekly_earnings: null,
									working_pattern : [1, 2, 3, 4, 5]
								});
							}}
							isLoading={isCreating}
						>
							Cancel
						</FreepayrollButton>
						<FreepayrollButton
							variant={"primary"}
							size={"small"}
							custom={true}
							fullWidth={false}
							width={6}
							disabled={checkDisabled()}
							onClick={() => {
								if (step === 3) {
									OnSave();
								} else {
									setStep(step + 1);
								}
							}}
							isLoading={isCreating}
						>
							{step === 3 ? 'Save' : 'Continue'}
						</FreepayrollButton>
					</div>
				)
			}
		</>
	);
};
