import React, {useCallback} from "react";
import {useUpdateEmploymentAllowanceSettings} from "../../../../hooks/allowances";
import {Typography, Modal, FreepayrollTextField, FreepayrollButton} from "@collegia-partners/ui-kit";

export const EmploymentAllowanceModal = ({
	open,
	setOpen,
	employerRecord,
	setEmployerRecord,
	employer,
}): JSX.Element => {

	const {mutateAsync: updateEmploymentAllowanceSettings} = useUpdateEmploymentAllowanceSettings();

	const submitUpdateEmploymentAllowanceSettings = useCallback(async () => {
		await updateEmploymentAllowanceSettings({
			"employers": {
				employment_allowance: true,
				employment_allowance_used: employerRecord?.active_employer_ytd_figure?.employment_allowance_used,
				employment_allowance_remaining: employer?.active_employer_ytd_figure?.employment_allowance -
					employerRecord?.active_employer_ytd_figure?.employment_allowance_used,

			}
		});
		setOpen(false);
	},[
		employer?.active_employer_ytd_figure?.employment_allowance,
		employerRecord?.active_employer_ytd_figure?.employment_allowance_used,
		updateEmploymentAllowanceSettings,
		setOpen,
	]);

	return (
		<Modal
			onClose={() => setOpen(false)}
			isOpen={open}
			fullWidth={true}
			maxWidth={'sm'}
			id={"salary-modal"}
		>
			<div id={"allowance-modal"}>
				<div>
					<Typography
						variant={"subtitle"}
						weight={"bold"}
						color={"primary"}
					>
						Employment Allowance
					</Typography>
					<Typography
						variant={"body"}
						weight={"normal"}
						color={"black"}
						className={"allowance-subtitle"}
					>
						If you have already claimed Employment Allowance in the current tax year, add the claimed amount
						here. Otherwise, leave it at £ 0.00.
					</Typography>
				</div>
				<div>
					<div className={"allowance-modal-row"}>
						<div>
							<FreepayrollTextField
								name={"employment_allowance_used"}
								label={"Allowance already claimed"}
								value={parseFloat(employerRecord?.active_employer_ytd_figure?.employment_allowance_used)}
								onChange={(e) => {
									setEmployerRecord({
										...employerRecord,
										active_employer_ytd_figure: {
											...employerRecord?.active_employer_ytd_figure,
											employment_allowance_used: e.target.value,
										}
									});
								}}
								maxDecimalPlaces={2}
								customEndAdornment={
									<Typography
										variant={"body"}
										weight={"normal"}
										color={"black"}
									>
										£
									</Typography>
								}
								fieldType={"text"}
							/>
						</div>
						<div>
							<FreepayrollTextField
								name={"remaining_allowance"}
								label={"Remaining allowance"}
								disabled
								value={
									employer?.active_employer_ytd_figure?.employment_allowance -
									employerRecord?.active_employer_ytd_figure?.employment_allowance_used
								}
								customEndAdornment={
									<Typography
										variant={"body"}
										weight={"normal"}
										color={"black"}
									>
										£
									</Typography>
								}
								fieldType={"text"}
							/>
						</div>
					</div>
				</div>
				<div>
					<div
						className={!employer?.apprentice_levy_due
							? "allowance-modal-employment-allowance-two-button"
							: "allowance-modal-employment-allowance-one-button"
						}
					>
						<div>
							<FreepayrollButton
								onClick={() => {
									setEmployerRecord(employer);
									setOpen(false);
								}}
								variant={"white-button"}
								size={"medium"}
								custom
								height={0.85}
								width={7.5}
								fullWidth={false}
							>
								Cancel
							</FreepayrollButton>
						</div>

						{
							!employer?.employment_allowance && (
								<div>
									<FreepayrollButton
										onClick={() => submitUpdateEmploymentAllowanceSettings}
										variant={"primary"}
										size={"medium"}
										custom
										height={0.85}
										width={7.7}
										fullWidth={false}
									>
										Enable
									</FreepayrollButton>
								</div>
							)
						}

					</div>
				</div>
			</div>
		</Modal>
	)
}