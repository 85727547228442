import React, {useEffect, useState} from "react";
import House from "../../../images/svg/hmrc-page/black-house.svg";
import { ApprenticeModal } from "../../../components/Pages/Company/Allowances/ApprenticeModal";
import { EmploymentAllowanceModal } from "../../../components/Pages/Company/Allowances/EmploymentAllowanceModal";
import { formatMoney } from "../../../utils/Helpers";
import {useLoggedLayout} from "../../../context/LoggedLayoutContext";
import {useGetEmployer} from "../../../hooks/employer";
import {FreepayrollButton, Typography, Divider} from "@collegia-partners/ui-kit";

function LeftSide({
	title = "",
	subtitle = "",
	setOpen = () => {},
	renderExtra = false,
	buttonText = "",
}) {
	return (
		<div
			className={"information-item-grid"}
		>
			<div>
				<Typography
					variant={"subtitle"}
					weight={"bold"}
					color={"black"}
				>
					{title}
				</Typography>
				{
					renderExtra && (
						<div>
							<Typography
								variant={"subtitle2"}
								weight={"normal"}
								color={"gray"}
							>
								{subtitle}
							</Typography>
						</div>
					)
				}
			</div>
			<div>
				<FreepayrollButton
					variant={"primary"}
					onClick={() => setOpen(true)}
					custom
					height={0.85}
					width={1}
					size={"medium"}
				>
					{buttonText}
				</FreepayrollButton>
			</div>
		</div>
	);
}


function RightSide() {
	return (
		<div className={"right-container"}>
			<div className={"information-item-grid"}>
				<div className={"information-line"}>
					<img alt={"logo"} src={House} />
				</div>
				<div>
					<div>
						<Typography variant={"subtitle"} weight={"bold"} color={"black"}>
							Employment allowance
						</Typography>
					</div>
					<div>
						<Typography variant={"subtitle2"} weight={"normal"} color={"gray"}>
							An allowance of £5,000 per tax year is available to offset against your Class 1 NICs liability.
						</Typography>
					</div>
				</div>
			</div>
			<Divider color={"light-gray"}/>
			<div className={"information-item-grid"}>
				<div className={"information-line"}>
					<img alt={"logo"} src={House} />
				</div>
				<div>
					<div>
						<Typography variant={"subtitle"} weight={"bold"} color={"black"}>
							Apprenticeship Levy
						</Typography>
					</div>
					<div>
						<Typography variant={"subtitle2"} weight={"normal"} color={"gray"}>
							The Apprenticeship Levy is a levy on UK employers to fund new apprenticeships. An allowance of £15,000 per tax year is available to offset against your payments.
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Allowances = (): JSX.Element => {

	const { setLayoutProps } = useLoggedLayout();

	const [employerRecord, setEmployerRecord] = useState({
		apprentice_levy_due: false,
		employment_allowance: false,
	}),
		[openApprenticeModal, setOpenApprenticeModal] = useState(false),
		[openEAModal, setOpenEAModal] = useState(false);

	const { employer } = useGetEmployer({ relations: ['active_employer_ytd_figure'] });

	useEffect(() => {
		if (employer?.id) {
			setEmployerRecord(employer);
		}
	}, [employer, setEmployerRecord]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Company',
			activeSubpage: 'Allowances',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div
			id={"company-allowances-page"}
		>
			<Typography
				variant={"title"}
				weight={"bold"}
				color={"#0160FD"}
			>
				Allowances
			</Typography>
			<Divider color={"light-gray"}/>
			<div
				className={"information-container"}
			>
				<div
					className={"left-container"}
				>
					<LeftSide
						title={"Employment allowance"}
						subtitle={
							`Remaining allowance: 
								${formatMoney(employer?.active_employer_ytd_figure?.employment_allowance_remaining)}`
						}
						renderExtra={employer?.employment_allowance === true}
						setOpen={setOpenEAModal}
						buttonText={employer?.employment_allowance ? "Stop" : "Enable"}
					/>
					<Divider color={"light-gray"}/>
					<LeftSide
						title={"Apprenticeship Levy"}
						subtitle={
							`Allowance per year: 
								${formatMoney(employer?.active_employer_ytd_figure?.apprentice_allowance)}`
						}
						renderExtra={employer?.apprentice_levy_due === true}
						setOpen={setOpenApprenticeModal}
						buttonText={employer?.apprentice_levy_due ? "Stop" : "Enable"}
					/>
				</div>
				<RightSide/>
			</div>
			<ApprenticeModal
				open={openApprenticeModal}
				setOpen={setOpenApprenticeModal}
				employerRecord={employerRecord}
				setEmployerRecord={setEmployerRecord}
				employer={employer}
			/>
			<EmploymentAllowanceModal
				open={openEAModal}
				setOpen={setOpenEAModal}
				employerRecord={employerRecord}
				setEmployerRecord={setEmployerRecord}
				employer={employer}
			/>
		</div>
	);
}

export default Allowances;