import Grid from "@mui/material/Grid";
import {useState} from "react";
import {CompanyDetailsCover} from "../../../components/Pages/CompanyDetails/CompanyDetailsCover";
import FindYourCompany from "../../../components/Pages/CompanyDetails/FindYourCompany";
import CompanyDetailsForm from "../../../components/Pages/CompanyDetails/CompanyDetailsForm";
import {StyleAppBar} from "../../../components/StyleAppBar";
import SelectDirector from "../../../components/Pages/CompanyDetails/SelectDirector";

const renderContent = (
	signupType = "companiesHouse",
	setSignupType = () => {},
	step = 0,
	setStep = () => {},
	director = {
		fullName: "",
	},
	setDirector = () => {},
	company = null,
	setCompany = () => {},
) => {
	switch (step) {
		case 1:
			return (
				<FindYourCompany
					step={step}
					setStep={setStep}
					setSignupType={setSignupType}
					setCompany={setCompany}
					company={company}
				/>
			);
		case 2:
			return (
				<SelectDirector
					step={step}
					setStep={setStep}
					director={director}
					setDirector={setDirector}
					company={company}
				/>
			);
		case 3:
			return (
				<CompanyDetailsForm
					signupType={signupType}
					step={step}
					setStep={setStep}
					director={director}
					setDirector={setDirector}
					company={company}
					setSignupType={setSignupType}
				/>
			);
		default:
			return <></>;
	}
}

function _backLink(step, setStep, company, setCompany, setDirector, setSignupType) {
	switch (step) {
		case 1:
			setCompany(null);
			return setStep(0);
		case 2:
		case 3:
		default:
			setDirector("");
			setCompany(null);
			setSignupType("companiesHouse");
			setStep(1);
			return;

	}
}

const CompanyDetails = (): JSX.Element => {

	const [step, setStep] = useState(0);
	const [signupType, setSignupType] = useState("companiesHouse");
	const [company, setCompany] = useState(null);

	const [director, setDirector] = useState("");

	return (
		<Grid>
			{
				step === 0 &&
				<CompanyDetailsCover
					step={step}
					setStep={setStep}
				/>
			}
			{
				step !== 0 &&
				<Grid>
					<StyleAppBar
						custom={true}
						customOnClick={() => _backLink(
							step,
							setStep,
							company,
							setCompany,
							setDirector,
							setSignupType,
						)}
					/>
					<Grid
						id={"company-details-container"}
						container
					>
						{renderContent(signupType, setSignupType, step, setStep, director, setDirector, company, setCompany)}
					</Grid>
				</Grid>
			}
		</Grid>
	);
}

export default CompanyDetails;